import axios from "axios";

export function getToken(roomName, userName) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.post(
      `${mainUrl}/api/room/join/${roomName}/username/${userName}`
    );
    return response;
  };
}
export function createVideochat(messenger) {
  return async (dispatch, getState) => {
    let id_user = getState().user.user.id;
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.post(`${mainUrl}/api/room/videochat`, {
      messenger: { ...messenger },
      api_token: getState().user.user.api_token,
    });
    return response;
  };
}

export function joinVideohat(messenger) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.post(`${mainUrl}/api/room/videochat/join`, {
        messenger: { ...messenger },
        api_token: getState().user.user.api_token,
    });
    return response;
  };
}

export function downVideochat(id) {

  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.patch(`${mainUrl}/api/room/videochat/disable/${id}`);
    return response;
  };
}
export function startVideochat(videoChat) {

  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.patch(`${mainUrl}/api/room/videochat/start/${videoChat.id}`);
    return response;
  };
}
export function listSeller(status) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let id_user = getState().user.user.id;
    let response = await axios.get(`${mainUrl}/api/room/videochat/list-seller/${id_user}/status/${status}`);
    return response;
  }
}