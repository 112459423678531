import {
  USER_REGISTRATION,
  USER_LOGIN,
  USER_VERIFY,
  USER_VERIFY_RESULT,
  USER_LOGIN_RESULT,
  SET_USER_LOGIN,
  LOGOUT,
  USER_TOKEN_VIEW,
} from "./actionTypes";
import axios from "axios";

export function registration(formData) {
  return async (dispatch, getState) => {
    dispatch(registerDispatch());
    let mainUrl = getState().settings.mainUrl;
    return await axios.post(`${mainUrl}/api/register`, {
      ...formData,
    });
  };
}

export function login(formData) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    dispatch(loginDispatchResult());
    return await axios.post(`${mainUrl}/api/login`, {
      ...formData,
    });
  };
}
export function loginDispatchResult() {
  return {
    type: USER_LOGIN,
  };
}

export function registerDispatch() {
  return {
    type: USER_REGISTRATION,
  };
}

export function userVerify(url) {
  return async (dispatch, getState) => {
    dispatch({
      type: USER_VERIFY,
    });
    return axios.get(atob(url));
  };
}

export function userVerifyError() {
  return {
    type: USER_VERIFY_RESULT,
    data: {
      status: true,
      message: "Error Verify",
      type: "danger",
    },
  };
}

export function userVerifySuccess(message) {
  return {
    type: USER_VERIFY_RESULT,
    data: {
      status: true,
      message: message,
      type: "success",
    },
  };
}

export function userLoginResultError(message) {
  return {
    type: USER_LOGIN_RESULT,

    data: { status: true, message: message, type: "danger" },
  };
}

export function userLoginResultSuccess(message) {
  return {
    type: USER_LOGIN_RESULT,

    data: { status: true, message: message, type: "success" },
  };
}

export function sendForgotPassword(email) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    return await axios.post(`${mainUrl}/api/password/email`, {
      email: email,
    });
  };
}

export function setUserLogin(user) {
  return {
    type: SET_USER_LOGIN,
    data: user,
  };
}

export function resetForgotPassword(formData) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    return await axios.post(`${mainUrl}/api/password/reset`, {
      email: formData.email,
      token: formData.token,
      password: formData.newPassword,
      password_confirmation: formData.newPassword_c,
    });
  };
}

export function logout() {
  return {
    type: LOGOUT,
  };
}

export function updateMainData(formData) {
  return async (dispatch, getState) => {
    let id_user = getState().user.user.id;
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.patch(`${mainUrl}/api/user/main/${id_user}`, {
      ...formData,
      api_token: getState().user.user.api_token,
    });
    return response;
  };
}

export function userViewToken() {
  return async (dispatch, getState) => {
    let id_user = getState().user.user.id;
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.get(`${mainUrl}/api/user/viewToken/${id_user}`, {
      params: {
        api_token: getState().user.user.api_token,
      },
    });

    return response;
  };
}

export function userNewData(data) {
  return {
    type: USER_TOKEN_VIEW,
    data: data,
  };
}

export function getUserById(id_user) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.get(`${mainUrl}/api/user/viewToken/${id_user}`, {
      params: {
        api_token: getState().user.user.api_token,
      },
    });

    return response;
  };
}
export function getUserByName(name) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.get(`${mainUrl}/api/user/viewByName/${name}`, {
      params: {
        api_token: getState().user.user.api_token,
      },
    });

    return response;
  };
}

export function getSkillNames(skills) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.get(`${mainUrl}/api/user/skillNames`, {
      params: {
        skills: skills,
        api_token: getState().user.user.api_token,
      },
    });
    return response;
  };
}

export function skills() {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.get(`${mainUrl}/api/skills-by-category`);
    return response;
  };
}

export function getUsersBySkill(skill, sort) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.get(
      `${mainUrl}/api/user/viewBySkill/${skill.value}/sort/${sort}`,
      {
        params: {
          api_token: getState().user.user.api_token,
        },
      }
    );
    return response;
  };
}

export function changePassword(formData) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let id_user = getState().user.user.id;
    let response = await axios.patch(`${mainUrl}/api/user/change-password/${id_user}`,{
      ...formData,
      api_token: getState().user.user.api_token,
    });
    return response;
  }

}

export function searchUsers(search,type,sort,filter) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let id_user = getState().user.user.id;
    let response = await axios.get(`${mainUrl}/api/user/search`,{
      params:{
        search,
        type:{...type},
        sort,
        filter,
        api_token: getState().user.user.api_token,
      }
    });
    return response;
  }

}
export function getAgesMinMax() {
  return async (dispatch, getState) => {
   let mainUrl = getState().settings.mainUrl;
    let response = await axios.get(`${mainUrl}/api/user/search-get-ages-min-max`,{
      params:{
        api_token: getState().user.user.api_token,
      }
    });
    return response;
  }
}

export function changeBadge(badge) {
  return async (dispatch,getState) => {
    let mainUrl = getState().settings.mainUrl;
    let id_user = getState().user.user.id;
    let response = await axios.patch(`${mainUrl}/api/user/change-badge/${id_user}/badge/${badge}`,{
      api_token: getState().user.user.api_token,
    });
    return  response;
  }
}

export function deactivateUser() {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let id_user = getState().user.user.id;
    let response = await axios.patch(`${mainUrl}/api/user/deactivate/${id_user}`,{
      api_token: getState().user.user.api_token,
    });
    return  response;
  }
}