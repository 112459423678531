import axios from "axios";

export function createService(formData) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let id_user = getState().user.user.id;
    let response = await axios.post(mainUrl + `/api/service/${id_user}`, {
      ...formData,
      api_token: getState().user.user.api_token,
    });
    return response;
  };
}

export function listServices(id_user) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.get(mainUrl + `/api/service/list/${id_user}`, {
      params: {
        api_token: getState().user.user.api_token,
      },
    });
    return response.data;
  };
}

export function viewService(id) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.get(mainUrl + `/api/service/${id}`, {
      params: {
        api_token: getState().user.user.api_token,
      },
    });
    return response.data;
  };
}

export function trendingServices() {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let id_user = getState().user.user.id;
    let response = await axios.get(
      mainUrl + `/api/service/trending-list/${id_user}`,
      {
        params: {
          api_token: getState().user.user.api_token,
        },
      }
    );
    return response.data;
  };
}

export function editService(formData, id) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.patch(mainUrl + `/api/service/${id}`, {
      ...formData,
      api_token: getState().user.user.api_token,
    });
    return response.data;
  };
}

export function showBySkillCategory(category) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let id_user = getState().user.user.id;
    let response = await axios.get(
      mainUrl + `/api/service/showBySkillCategory/${category}/user/${id_user}`,
      {
        params: {
          api_token: getState().user.user.api_token,
        },
      }
    );
    return response.data;
  };
}
