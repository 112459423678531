import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import FontAwesome from 'react-fontawesome'
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import { connect } from "react-redux";
import { payment } from "../../redux/actions/stripeActions";
import { withRouter } from "react-router-dom";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe("pk_test_Du0fzY5XkR7m8qkwrWanhqpC00TMxFfEet");

function StripePayment(props) {
  useEffect(() => {
    if (props.match.params.id !== undefined) {
      axios.post(
        `${props.settings.mainUrl}/api/stripe/payment/success/${props.match.params.id}`,
        {
          api_token: props.user.api_token,
        }
      );
    }
  }, []);
  const handleClick = async () => {
    // Get Stripe.js instance
    const stripe = await stripePromise;

    // Call your backend to create the Checkout Session
    const response = await axios.post(
      `${props.settings.mainUrl}/api/stripe/payment/${props.user.id}/type/${props.type}`,
      {
        data: props.data,
        api_token: props.user.api_token,
      }
    );

    const session = await response.data;
    console.log(session);
    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    }
};

  return (
    <button
      role="link"
      onClick={(e) => {
        handleClick();
      }}
      className={"btn btn-bordered-dark btn-block font-weight-400"}
    >
      <FontAwesome name={'lock'}/> Unlock full post for ${props.data.price}
    </button>
  );
}
function mapStateToProps(state) {
  return {
    settings: state.settings,
    user: state.user.user,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    payment: () => {
      return dispatch(payment());
    },
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StripePayment));
