import React,{Component} from 'react';
import DesktopNavbar from "../../../components/headers/DesktopNavbar";
import MobileNavbar from "../../../components/headers/MobileNavbar";
import BaseLayout from "../../../layouts/BaseLayout";
import DesktopNotificationSideBar from "../../../components/sidebars/DesktopNotificationsSidebar";
import MyProfileSidebarMobile from "../../../components/sidebars/MyProfileSidebarMobile";
import {NavLink} from "react-router-dom";
import NotificationRightSidebar from "../../../components/sidebars/NotificationRightSidebar";
import Tabbar from "../../../components/headers/Tabbar";
import {connect} from 'react-redux'
import {listSeller} from './../../../redux/actions/twilioActions';
import {withRouter} from 'react-router-dom';
import RequestItem from "./RequestItem";
import MobileNotificationSidebar from "../../../components/sidebars/MobileNotificationSidebar";


class CallRequests extends Component{

    state = {
        list:[]
    }

    componentDidMount() {
        this.props.listSeller(1).then(response => {
            this.setState({
              list:[...response.data]
            })
        })
    }

  render() {
        return <React.Fragment>
            <DesktopNavbar />
            <MobileNavbar > </MobileNavbar>
            <BaseLayout>
                <aside className="col col-xl-3 order-xl-1 col-lg-12 order-lg-1 col-12 my-profile ">
                    <div className={" row sidebar-menu"}>
                        <div className={"col-12 "}>
                            <div className={"bg-white profile-account-holder desktop"}>
                                <h2>Notifications</h2>
                                <DesktopNotificationSideBar active={"calls"} userLink={"/"} />
                            </div>
                            <div className={"mobile"}>
                                <MobileNotificationSidebar
                                    active={{
                                        value: "/notifications/calls",
                                        label: "Calls",
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </aside>
                <main
                    className="col col-xl-6 order-xl-2 col-lg-12 order-lg-2 col-md-12 col-sm-12 col-12 mb-3 post-list"
                    style={{ padding: "0 8px" }}
                >
                    <div className="profile-account-holder  mb-3  bg-white post-item-list">
                        <div className={" p-0   notification notification-block"}>
                            <div className={"notification-nav"}>
                                <ul className={""}>
                                    <li>
                                        <NavLink to={"/notification/calls"}>Notifications</NavLink>
                                    </li>
                                    <li className={"active"}>
                                        <NavLink to={"/notification/calls-requests"}>Requests</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={"/notification/calls-up-coming"}>Upcoming</NavLink>
                                    </li>
                                    <li >
                                        <NavLink to={"/notification/calls-completed"}>Completed</NavLink>
                                    </li>
                                </ul>
                            </div>
                            {this.state.list.length === 0 ? <div className={'service-order-result d-flex justify-content-center align-items-center'} >
                                No Calls!
                            </div> : ''}
                          <div className={'order-list'}>
                            {this.state.list.map((item,index) => {
                              return <RequestItem videochat={item} key={`videochat_${index}`} />
                            })}
                          </div>



                        </div>
                    </div>
                </main>
                <aside className="col col-xl-3 order-xl-3 col-lg-12 order-lg-3 col-12">
                    <NotificationRightSidebar user={this.props.user} />
                </aside>
            </BaseLayout>
            <Tabbar />
        </React.Fragment>
    }
}
function mapStateToProps(state) {
    return {
        user: state.user.user,
        settings: state.settings,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        listSeller:(status) => {
            return dispatch(listSeller(status));
        }
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CallRequests)) ;