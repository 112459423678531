import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import iconBurger from "../../assets/img/icon-burger-menu.svg"
import iconFollowing from "../../assets/img/icon-following.svg"
import iconDiscover from "../../assets/img/icon-discover.svg"
import iconFly from "../../assets/img/icon-fly.svg"



class MobileNavbar extends Component {

  render() {
    const mobileNavBarUrl = String(window.location.pathname);
    return (
      <React.Fragment>
        <div className={"mobile-navbar p-0 mobile bg-white"} >
          <div className="row" 
          style={{display: "flex", width: "100%", margin: "0px auto 0 auto", 
          alignItems: "center", justifyContent: "space-between"}}>
            
              <div className={"d-flex justify-content-between"} style={{dispay: "flex"}}>
                <NavLink to={"/settings/general"} className={"nav-item"} style={{dispay: "flex"}}>
                  <img src={iconBurger} alt="burger" style={{height: "18px", width: "18px", padding: "0"}}/>
                </NavLink>
              </div>
           
            
              <div style={{fontWeight:600, fontSize:16, display: "flex", }}>
                {this.props.children === undefined ? <React.Fragment>
                  <NavLink style={{dispay:'flex'}}
                    className="nav-item"
                    style={(mobileNavBarUrl === "/") ? {opacity: '0.5'} : {opacity: '1'}}
                    to={"/"}

                  >
                    <img className="nav-icon" src={iconFollowing} alt="following"/>
                    <p className="nav-text">Following</p>

                  </NavLink>
                  
                  <NavLink
                  style={{dispay: "flex", marginLeft: "12%"}}
                  style={(mobileNavBarUrl === "/trending-feeds") ? {opacity: '0.5'} : {opacity: '1'}}
                    className="nav-item pl-3"
                    to={"/trending-feeds"}

                  >
                    <img className="nav-icon" src={iconDiscover} alt="discover"/>
                    <p className="nav-text">Discover</p>
                  </NavLink>
                </React.Fragment>  : this.props.children}


              </div>
            

            
              <div className={" d-flex justify-content-end"}
              style={{display: "flex"}}>
                <NavLink to={"/messenger"} className={"nav-item"}>
                <img src={iconFly} alt="fly"/>
                </NavLink>
              </div>
            
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default MobileNavbar;
