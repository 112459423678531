import React,{Component} from 'react';

class EarningHeader extends Component{
  render() {
    return <div className={'earning-header mb-4'}>
      <div className={'row positions mb-3'}>
        <div className={'col-4 '}>
          <h2 className={'value'}>$8,455.94</h2>
          <h3 className={'title'}>Gross Earning</h3>
        </div>
        <div className={'col-4 text-center'}>
          <h2 className={'value'}>$9,890</h2>
          <h3 className={'title'}>Total Earning</h3>
        </div>
        <div className={'col-4 text-right'}>
          <h2 className={'value'}>$1,244.69</h2>
          <h3 className={'title'}>Account Balance</h3>
        </div>
      </div>
      <div className={'row justify-content-between'}>
        <div className={'col-auto'}><button type={'button'} className={'btn btn-danger'}>Report</button></div>
        <div className={'col-auto'}><button type={'button'} className={'btn btn-primary'}>Withdraw Balance</button></div>
      </div>
    </div>
  }
}
export default EarningHeader;