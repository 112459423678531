import axios from "axios";

export function payment() {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    axios.get(`${mainUrl}/api/stripe/payment`, {
      params: {
        api_token: getState().user.user.api_token,
      },
    });
  };
}

export function createPayout(formData) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let id_user = getState().user.user.id;
    formData.id_user = id_user;
    let response = await axios.post(`${mainUrl}/api/payout`, {
      formData,
      api_token: getState().user.user.api_token,
    });
    return response;
  };
}

export function setPaymentToUser(type,data,paymentIntent) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let user = getState().user.user;
    switch (type) {
      case 'exhibit':
        await axios.post(`${mainUrl}/api/stripe/payment-exhibit-user`,{
          id_post:data.id,
          id_payer:user.id,
          paymentIntent_id:paymentIntent.id,
          api_token:user.api_token
        });
        break;
      case 'service':
       await axios.post(`${mainUrl}/api/stripe/payment-service-user`,{
          api_token:user.api_token,
          id_order:data.id,
          id_payer:user.id,
          paymentIntent_id:paymentIntent.id
        });
       break;
      case 'call':
        await axios.post(`${mainUrl}/api/stripe/payment-call-user`,{
          api_token:user.api_token,
          id_request:data.id,
          id_payer:user.id,
          paymentIntent_id:paymentIntent.id
        });
        break
  }

  }
}