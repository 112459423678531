import axios from "axios";
import{setPaymentToUser} from './../actions/stripeActions'

export function createOrder(formData, service, buyer) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.post(
      mainUrl + `/api/order/${service}/buyer/${buyer}`,
      {
        ...formData,
        api_token: getState().user.user.api_token,
      }
    );
    return response.data;
  };
}

export function sellerOrder(status) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let id_user = getState().user.user.id;
    let response = await axios.get(
      mainUrl + `/api/order/seller/${id_user}/status/${status}`,
      {
        params: {
          api_token: getState().user.user.api_token,
        },
      }
    );
    return response.data;
  };
}

export function buyerOrder(status) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let id_user = getState().user.user.id;
    let response = await axios.get(
      mainUrl + `/api/order/buyer/${id_user}/status/${status}`,
      {
        params: {
          api_token: getState().user.user.api_token,
        },
      }
    );
    return response.data;
  };
}

export function changeStatus(id_order, status) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let id_user = getState().user.user.id;
    let response = await axios.patch(
      mainUrl + `/api/order/${id_order}/status/${status}/user/${id_user}`,
      {
        api_token: getState().user.user.api_token,
      }
    );
    return response.data;
  };
}

export function changeSellerRating(id_order, rating) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let id_user = getState().user.user.id;
    let response = await axios.patch(
      mainUrl +
        `/api/order/seller/rating/${rating}/order/${id_order}/user/${id_user}`,
      {
        api_token: getState().user.user.api_token,
      }
    );
    return response.data;
  };
}

export function changeBuyerRating(id_order, rating) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let id_user = getState().user.user.id;
    let response = await axios.patch(
      mainUrl +
        `/api/order/buyer/rating/${rating}/order/${id_order}/user/${id_user}`,
      {
        api_token: getState().user.user.api_token,
      }
    );
    return response.data;
  };
}

export function setReviewBuyer(id_order, review) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;

    let response = await axios.patch(
      mainUrl + `/api/order/buyer/review/${review}/order/${id_order}`,
      {
        api_token: getState().user.user.api_token,
      }
    );
    return response.data;
  };
}
export function setReviewSeller(id_order, review) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;

    let response = await axios.patch(
      mainUrl + `/api/order/seller/review/${review}/order/${id_order}`,
      {
        api_token: getState().user.user.api_token,
      }
    );
    return response.data;
  };
}

export function simplePay(order) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let user = getState().user.user;
    let response = await axios.post(`${mainUrl}/api/stripe/payment-service-repeat`,{
      id_customer:user.customer_id,
      id_order:order.id,
      payment_method:user.payment_method_id,
      api_token: getState().user.user.api_token,
    });
    return response;
  }
}