import React from "react";
import "./LogoSmall.scss";

function LogoSmall() {
    return(
    <div className="logo-small">
        <h2 className="logo-small-title">Witit</h2>
    </div>
    )

}

export default LogoSmall; 