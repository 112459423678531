import React, { Component } from "react";
import DesktopNavbar from "../../../components/headers/DesktopNavbar";
import MobileNavbar from "../../../components/headers/MobileNavbar";
import BaseLayout from "../../../layouts/BaseLayout";
import DesktopNotificationSideBar from "../../../components/sidebars/DesktopNotificationsSidebar";
import MyProfileSidebarMobile from "../../../components/sidebars/MyProfileSidebarMobile";
import { NavLink } from "react-router-dom";
import OrderBlock from "../components/OrderBlock";
import ReactStars from "react-rating-stars-component";
import FontAwesome from "react-fontawesome";
import NotificationRightSidebar from "../../../components/sidebars/NotificationRightSidebar";
import Tabbar from "../../../components/headers/Tabbar";
import {
  changeStatus,
  buyerOrder,
  changeBuyerRating,
  setReviewBuyer,
} from "../../../redux/actions/orderActions";
import { connect } from "react-redux";
import MobileNotificationSidebar from "../../../components/sidebars/MobileNotificationSidebar";

class MadeCompleted extends Component {
  state = {
    orders: [],
    rating: 0,
  };

  componentDidMount() {
    this.props.buyerOrder("4,3,2,8").then((response) => {
      this.setState({
        orders: [...response],
      });
    });
  }

  ratingChanged = (newRating, index) => {
    let orders = [...this.state.orders];
    orders[index].rating_buyer = newRating;
    this.setState({
      orders,
    });
  };

  render() {
    return (
      <React.Fragment>
        <DesktopNavbar />
        <MobileNavbar > </MobileNavbar>
        <BaseLayout>
          <aside className="col col-xl-3 order-xl-1 col-lg-12 order-lg-1 col-12 my-profile ">
            <div className={" row sidebar-menu"}>
              <div className={"col-12 "}>
                <div className={"bg-white profile-account-holder desktop"}>
                  <h2>Notifications</h2>
                  <DesktopNotificationSideBar
                    active={"made-orders"}
                    userLink={""}
                  />
                </div>
                <div className={"mobile"}>
                  <MobileNotificationSidebar
                    active={{
                      label: "Orders Created",
                      value: "/notification/orders-created/notifications",
                    }}
                  />
                </div>
              </div>
            </div>
          </aside>
          <main
            className="col col-xl-6 order-xl-2 col-lg-12 order-lg-2 col-md-12 col-sm-12 col-12 mb-3 post-list"
            style={{ padding: "0 8px" }}
          >
            <div className="profile-account-holder  mb-3  bg-white post-item-list">
              <div className={" p-0   notification notification-block"}>
                <div className={"notification-nav"}>
                  <ul className={""}>
                    <li>
                      <NavLink to={"/notification/orders-created/notifications"}>
                        Notifications
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={"/notification/orders-created/requests"}>
                        Requests
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={"/notification/orders-created/in-progress"}>
                        In Progress
                      </NavLink>
                    </li>
                    <li className={"active"}>
                      <NavLink to={"/notification/orders-created/completed"}>
                        Completed
                      </NavLink>
                    </li>
                  </ul>
                </div>
                {this.state.orders.length === 0 ? <div className={'service-order-result d-flex justify-content-center align-items-center'} >
                  No Made Orders!
                </div> : ''}
                <div className={"order-list"}>
                  {this.state.orders.map((order, index) => {
                    let files = JSON.parse(order.files);
                    return (
                      <div className={"order-item"} key={`order_${index}`}>
                        <OrderBlock
                          order={order}
                          settings={this.props.settings}
                        />

                        <div className={"row"}>
                          <div className={"col-12 text-center"}>
                            <ReactStars
                              count={5}
                              onChange={(e) => {
                                this.ratingChanged(e, index);
                              }}
                              size={35}
                              activeColor="#ffd700"
                              value={order.rating_buyer}
                              emptyIcon={<FontAwesome name="star" />}
                              halfIcon={<FontAwesome name="star-half-alt" />}
                              fullIcon={<FontAwesome name="star" />}
                              edit={order.rating_buyer === null}
                            />
                          </div>
                          {order.feedback_seller !== null ? null : (
                            <textarea
                              className={"form-control bordered mb-2"}
                              id={`order_${order.id}`}
                            />
                          )}
                        </div>

                        <div className={"row justify-content-between"}>
                          <div className={"col-auto"}>
                            <button
                              className={"btn btn-success"}
                              disabled={order.feedback_buyer !== null}
                              onClick={() => {
                                this.props
                                  .setReviewBuyer(
                                    order.id,
                                    document.getElementById(`order_${order.id}`)
                                      .value
                                  )
                                  .then((response) => {
                                    let orders = [...this.state.orders];
                                    orders[index] = response;
                                    this.setState({ orders: [...orders] });
                                  });
                              }}
                            >
                              Write a review
                            </button>
                          </div>
                          <div className={"col-auto"}>
                            <button
                              className={"btn btn-primary"}
                              style={{ paddingLeft: 20, paddingRight: 20 }}
                              disabled={
                                order.rating_buyer !== null &&
                                order.rating_buyer_status !== null
                              }
                              onClick={() => {
                                this.props
                                  .changeBuyerRating(
                                    order.id,
                                    order.rating_buyer
                                  )
                                  .then((response) => {
                                    let orders = [...this.state.orders];
                                    orders[index] = response;
                                    this.setState({ orders: [...orders] });
                                  });
                              }}
                            >
                              Send Rating
                            </button>
                          </div>
                          <div className={"col-auto"}>
                            <button
                              className={"btn btn-dark"}
                              style={{ paddingLeft: 20, paddingRight: 20 }}
                              onClick={() => {
                                this.props.history.push(
                                  `/messenger/${order.user.name}`
                                );
                              }}
                            >
                              Message
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </main>
          <aside className="col col-xl-3 order-xl-3 col-lg-12 order-lg-3 col-12">
            <NotificationRightSidebar user={this.props.user} />
          </aside>
        </BaseLayout>
        <Tabbar />
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    user: state.user.user,
    settings: state.settings,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    buyerOrder: (status) => {
      return dispatch(buyerOrder(status));
    },
    changeStatus: (id_order, status) => {
      return dispatch(changeStatus(id_order, status));
    },
    changeBuyerRating: (id_order, rating) => {
      return dispatch(changeBuyerRating(id_order, rating));
    },
    setReviewBuyer: (id_order, review) => {
      return dispatch(setReviewBuyer(id_order, review));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(MadeCompleted);
