import React, { useState, useEffect, useRef } from "react";
import { faBars, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import FontAwesome from "react-fontawesome";
import { LocalParticipant } from "./LocalParticipant";
import Timer from "react-compound-timer";

const Participant = ({
  participant,
  handleLogout,
  localParticipant,
  activeConversation,
}) => {
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);

  const videoRef = useRef();
  const audioRef = useRef();

  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);

  useEffect(() => {
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    const trackSubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => [...videoTracks, track]);
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
      }
    };

    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  return (
    <div className="participant position-relative">
      <div className={"title"}>
        <h3>{activeConversation.full_name}</h3>
        <h5>@{activeConversation.name}</h5>
          <h5><Timer
              initialTime={60000 * 0.5 + 5000}
              direction="backward"
              checkpoints={[
                  {
                      time: 0 ,
                      callback: () => { console.log('Handle logout');handleLogout()},
                  },

              ]}
          >
              {() => (
                  <React.Fragment>

                      <Timer.Hours />:
                      <Timer.Minutes />:
                      <Timer.Seconds />

                  </React.Fragment>
              )}
          </Timer></h5>
      </div>

      <div>
        <video ref={videoRef} autoPlay={true} />
        <audio ref={audioRef} autoPlay={true}  />
      </div>
      <div className={"text-center"}>
        <button className={"btn btn-danger btn-large"} onClick={handleLogout}>
          <FontAwesome name={"phone"} />
        </button>
      </div>
      {<LocalParticipant participant={localParticipant} />}
    </div>
  );
};

export default Participant;
