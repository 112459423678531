import React, { Component } from "react";
import { Link } from "react-router-dom"
import IconBack from '../../assets/img/icon-back.svg'

class Registration2 extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="title-firstName-lastName">Lit, tell us a little more...</div>
        <div
          className={[
            "form-group",
            this.props.formErrors().email.length > 0 && this.props.isError
              ? "error"
              : "",
          ].join(" ")}
        >
          <input
            type="email"
            className="form-control"
            placeholder="Email"
            value={this.props.formData.email}
            onChange={(e) => {
              this.props.onChangeEmail(e);
            }}
          />
          <div className={"errors-messages"}>
            {this.props.formErrors().email.length > 0 && this.props.isError
              ? this.props.formErrors().email
              : null}
          </div>
        </div>
        <div
          className={[
            "form-group",
            this.props.formErrors().phone.length > 0 && this.props.isError
              ? "error"
              : "",
          ].join(" ")}
        >
          <input
            type="text"
            className="form-control"
            placeholder="Phone"
            value={this.props.formData.phone}
            onChange={(e) => {
              this.props.onChangePhone(e);
            }}
          />
          <div className={"errors-messages"}>
            {this.props.formErrors().phone.length > 0 && this.props.isError
              ? this.props.formErrors().phone
              : null}
          </div>
        </div>  
        <div
          className={[
            "form-group",
            this.props.formErrors().password.length > 0 && this.props.isError
              ? "error"
              : "",
          ].join(" ")}
        >
          <input
            type="password"
            className="form-control"
            placeholder="Password"
            value={this.props.formData.password}
            onChange={(e) => {
              this.props.onChangePassword(e);
            }}
          />
          <div className={"errors-messages"}>
            {this.props.formErrors().password.length > 0 && this.props.isError
              ? this.props.formErrors().password
              : null}
          </div>
        </div>
        <div
          className={[
            "form-group",
            this.props.formErrors().rePassword.length > 0 && this.props.isError
              ? "error"
              : "",
          ].join(" ")}
        >
          <input
            type="password"
            className="form-control"
            placeholder="Re-type Password"
            value={this.props.formData.rePassword}
            onChange={(e) => {
              this.props.onChangeRePassword(e);
            }}
          />
          <div className={"errors-messages"}>
            {this.props.formErrors().rePassword > 0 && this.props.isError
              ? this.props.formErrors().rePassword
              : null}
          </div>
        </div>
        <div style={{marginTop: "26px"}} className="title-firstName-lastName">What name would you like to go by?</div>
        <div
          className={[
            "form-group",
            this.props.formErrors().username.length > 0 && this.props.isError
              ? "error"
              : "",
          ].join(" ")}
        >
          <input
            type="text"
            className="form-control"
            placeholder="Username"
            value={this.props.formData.username}
            onChange={(e) => {
              this.props.onChangeUsername(e);
            }}
          />
          <div className={"errors-messages"}>
            {this.props.formErrors().username.length > 0 && this.props.isError
              ? this.props.formErrors().username
              : null}
          </div>
        </div>
        <div className="bottom-input-text">for example: nightrider, dragon, jabroni, etc...</div>

<div className="form-group row position-absolut-btn-next">
          {/* <div className="col-sm-12"> */}
          <div className="registration-btn">          
              <button className="registration-btn-back" 
              style={{width: "45px", height: "45px", marginRight: "10px", borderRadius: "12px",
              background: "transparent", border: "1px solid #FFFFFF"}}
              >
                  <Link to={'/'}><img src={IconBack} alt="logoImage" height='19' width='11'/></Link>
              </button>
              <button type="button" className="registration-btn-next" 
              onClick={this.props.nextPage}
              style={{width: "335px", height: "45px", borderRadius: "10px", color: "#FFF"}}
              >Next</button>
            </div>
          {/* </div> */}
        </div>
    </React.Fragment>
    );
  }
}

export default Registration2;
