import React, { Component } from "react";
import { Link } from "react-router-dom"
import IconBack from '../../assets/img/icon-back.svg'
import Picker from "../../components/Picker/Picker"
import Gender from "../../components/Gender/Gender"

class Registration1 extends Component {
  render() {
      return (
      <React.Fragment>
        
        <h3 className="birthday-title birthday-title-margin-top" style={{padding: "0px 15px",}}>Let's get to know you...</h3>
        <div
          className={[
            "form-group",
            this.props.formErrors().firstName.length > 0 && this.props.isError
              ? "error"
              : "",
          ].join(" ")}
        >
          <input
            type="text"
            className="form-control"
            placeholder="First Name"
            value={this.props.formData.firstName}
            onChange={(e) => {
              this.props.onChangeFirstName(e);
            }}
          />
          <div className={"errors-messages"}>
            {this.props.formErrors().firstName.length > 0 && this.props.isError
              ? this.props.formErrors().firstName
              : null}
          </div>
        </div>
        <div
          className={[
            "form-group",
            this.props.formErrors().lastName.length > 0 && this.props.isError
              ? "error"
              : "",
          ].join(" ")}
        >
          <input
            type="text"
            className="form-control"
            placeholder="Last Name"
            value={this.props.formData.lastName}
            onChange={(e) => {
              this.props.onChangeLastName(e);
            }}
          />
          <div className={"errors-messages"}>
            {this.props.formErrors().lastName.length > 0 && this.props.isError
              ? this.props.formErrors().lastName
              : null}
          </div>
        </div>
          <Picker onChangeBirthday={this.props.onChangeBirthday}/>
          <Gender onChangeGender={this.props.onChangeGender}/>
        <div className="form-group row position-absolut-btn-next">
          {/* <div className="col-sm-12"> */}
            <div className="registration-btn">          
              <button className="registration-btn-back" 
              style={{width: "45px", height: "45px", marginRight: "10px", borderRadius: "12px",
              background: "transparent", border: "1px solid #FFFFFF"}}
              >
                  <Link to={'/'}><img src={IconBack} alt="logoImage" height='19' width='11'/></Link>
              </button>
              <button type="button" className="registration-btn-next" 
              onClick={this.props.nextPage}
              style={{width: "335px", height: "45px", borderRadius: "10px", color: "#FFF"}}
              >Next</button>
            </div>
          {/* </div> */}
        </div>
      </React.Fragment>
    );
  }
}

export default Registration1;
