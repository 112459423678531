import React from "react";

function NotificationRightSidebar(props) {
  return (
    <React.Fragment>
      <div className=" profile-account-holder bg-white desktop">
        <div className="box  rounded bg-white profile-box  online">
          <div className=" d-flex notification-right-sidebar">
            <div className={"sidebar-position"}>
              <div className={"numbers"}>
                {props.user.likes === null ? 0 : props.user.likes}
              </div>
              <div className={"title"}>Likes</div>
            </div>
            <div className={"sidebar-position"}>
              <div className={"numbers"}>
                {props.user.awards === null ? 0 : props.user.awards}
              </div>
              <div className={"title"}>Awards</div>
            </div>
            <div className={"sidebar-position"}>
              <div className={"numbers"}>
                {props.user.comments === null ? 0 : props.user.comments}
              </div>
              <div className={"title"}>Comments</div>
            </div>
            <div className={"sidebar-position"}>
              <div className={"numbers"}>
                {props.user.follows === null ? 0 : props.user.follows}
              </div>
              <div className={"title"}>Followers</div>
            </div>
            <div className={"sidebar-position"}>
              <div className={"numbers"}>
                {props.user.orders === null ? 0 : props.user.orders}
              </div>
              <div className={"title"}>Total Orders</div>
            </div>
            <div className={"sidebar-position"}>
              <div className={"numbers"}>
                {props.user.calls === null ? 0 : props.user.calls}
              </div>
              <div className={"title"}>Calls Completed</div>
            </div>
            <div className={"sidebar-position"}>
              <div className={"numbers"}>
                ${props.user.earning === null ? 0 : props.user.earning}
              </div>
              <div className={"title"}>Total Earning</div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default NotificationRightSidebar;
