import React, { useState, useEffect, Component } from "react";
import ConversationList from "../ConversationList";
import MessageList from "../MessageList";
import "./Messenger.css";
import Toolbar from "./../Toolbar";
import ToolbarButton from "../ToolbarButton";
import { connect } from "react-redux";
import {
  messengerUsers,
  addMessage,
  getUser,
} from "../../../../redux/actions/messengerActions";
import BaseLayout from "../../../../layouts/BaseLayout";
import Tabbar from "../../../../components/headers/Tabbar";
import DesktopNavbar from "../../../../components/headers/DesktopNavbar";
import MobileNavbar from "../../../../components/headers/MobileNavbar";
import { withRouter } from "react-router-dom";
import { getUserByName } from "../../../../redux/actions/userActions";

class Messenger extends Component {
  state = {
    conversations: [],
    fullConversations: [],
    activeConversation: {},
    pageSize: 20,
    page: 1,
    sidebarHide: false,
    intervalHandler: null,
  };
  constructor(props) {
    super(props);
    this.messagesListRef = React.createRef();
  }

  componentDidMount() {
    let intervalHandler = setInterval(() => {
      this.getConversationWithActive();
    }, 5000);

    this.getConversations().then(() => {
      if (!this.props.match.params.user) {
      } else {
        if (this.props.user.name !== this.props.match.params.user ) {
          this.props
            .getUserByName(this.props.match.params.user)
            .then((response) => {
              this.changeActiveConversation(response.data,true);
            });
        }
      }
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    if (prevProps.match.params.user !== this.props.match.params.user) {
      if (this.props.user.name !== this.props.match.params.user ) {
        console.log( prevState.activeConversation.name !== this.state.activeConversation.name);
        this.props
          .getUserByName(this.props.match.params.user)
          .then((response) => {
            this.changeActiveConversation(response.data,true);
          });
      }
    }
  }
  componentWillUnmount() {
    clearInterval(this.state.intervalHandler);
  }

  getConversationWithActive = () => {
    this.props.messengerUsers(this.state.page).then((response) => {
      let newConversations = response.data.map((result) => {
        return {
          photo: result.avatar,
          name: `${result.full_name}`,
          text: "",
          id: result.id,
          id_name: result.name,
        };
      });
      this.setState(
        {
          conversations: [...newConversations],
          fullConversations: [...response.data],
        },
        () => {
          this.changeActiveConversation(this.state.activeConversation, false);
        }
      );
    });
  };

  getConversations = async () => {
    let response = await this.props
      .messengerUsers(this.state.page)
      .then((response) => {
        let newConversations = response.data.map((result) => {
          return {
            photo: result.avatar,
            name: `${result.full_name}`,
            text: "",
            id: result.id,
            id_name: result.name,
          };
        });
        this.setState({
          conversations: [...newConversations],
          fullConversations: [...response.data],
        });
      });
    return response;
  };

  changeActiveConversation = (data,change) => {
    console.log(change)
    if (data.id !== undefined) {
      this.props.getUser(data.id, this.props.user.id).then((response) => {
        this.setState(
          {
            activeConversation: { ...response.data },
            sidebarHide:
              window.outerWidth <= 767 && change ? true : this.state.sidebarHide,
          },
          () => {
            // console.log(
            //   JSON.parse(this.state.activeConversation.messenger.messages)
            // );
          }
        );
      });
    }
  };

  addMessageHandler = (object) => {
    this.props.addMessage(object).then((response) => {
      this.getConversationWithActive();
    });
  };

  toggleSidebarHide = () => {
    this.setState({
      sidebarHide: !this.state.sidebarHide,
    });
  };

  confirmMessenger = (id_chat) => {};

  render() {

    return (
      <React.Fragment>
        <DesktopNavbar />
        <MobileNavbar />
        <BaseLayout>
          <div
            className={[
              "messenger ",
              this.state.sidebarHide ? "hide-sidebar" : "",
            ].join(" ")}
          >
            <div className="scrollable sidebar">
              <ConversationList
                conversations={this.state.conversations}
              //  changeActiveConversation={this.changeActiveConversation}
                activeConversation={this.state.activeConversation}
                sidebarHide={this.state.sidebarHide}
                toggleSidebarHide={this.toggleSidebarHide}
              />
            </div>

            <MessageList
              sidebarHide={this.state.sidebarHide}
              toggleSidebarHide={this.toggleSidebarHide}
              activeConversation={this.state.activeConversation}
              addMessageHandler={this.addMessageHandler}
              getConversationWithActive={this.getConversationWithActive}
            />
          </div>
        </BaseLayout>

        <Tabbar />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    messengerUsers: (page) => {
      return dispatch(messengerUsers(page));
    },
    addMessage: (object) => {
      return dispatch(addMessage(object));
    },
    getUser: (id_user1, id_user2) => {
      return dispatch(getUser(id_user1, id_user2));
    },
    getUserByName: (name) => {
      return dispatch(getUserByName(name));
    },
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Messenger));
