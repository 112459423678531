import React, { Component } from "react";
import "./category.scss"
import Business from '../../assets/img/business.svg'
import Creative from '../../assets/img/crearive.svg'
import Comedy from '../../assets/img/comedy.svg'
import DIY from '../../assets/img/diy.svg'
import Educational from '../../assets/img/educational.svg'
import Entertainment from '../../assets/img/entertainment.svg'
import Film from '../../assets/img/film.svg'
import Gaming from '../../assets/img/gaming.svg'
import Lifestyle from '../../assets/img/lifestyle.svg'
import Music from '../../assets/img/music.svg'
import NSFW from '../../assets/img/nsfw.svg'
import Personal from '../../assets/img/personal.svg'
import iconBack from '../../assets/img/icon-back.svg'

const styles = {
    allFixed: {
      position: 'fixed',
      width: '100vw',
      height: '100vh',
      top:0,
      left:0,
      background: '#000',
      zIndex: 2,
    },
    allRelative:{
        position: 'relative',
        width: '153px',
        height: '45px',
        background: 'transparent',
        zIndex: 1,
    }
  }

export default class Categories extends Component{
    constructor(props) {
        super(props);
        this.state = {
        show: false,
        categories: [{name: 'Business',  flag: false, number: '1', img: Business},
        {name: 'Creative', flag: false, number: '2', img: Creative},
        {name: 'Comedy', flag: false, number: '7', img: Comedy},
        {name: 'DIY', flag: false, number: '3', img: DIY},
        {name: 'Educational', flag: false, number: '4', img: Educational},
        {name: 'Entertainment', flag: false, number: '5', img: Entertainment},
        {name: 'Film', flag: false, number: '13', img: Film},
        {name: 'Gaming', flag: false, number: '6', img: Gaming},
        {name: 'Lifestyle', flag: false, number: '8', img: Lifestyle},
        {name: 'Music', flag: false, number: '12', img: Music},
        {name: 'NSFW', flag: false, number: '9', img: NSFW},
        {name: 'Personal', flag: false, number: '10', img: Personal}],
        category: '0',
        };     
    }

    handelCaregoryVisible = () => {       
        if(this.state.show){
          this.setState({
            show:false
        })
        }else{
          this.setState({
            show:true
        })
        }
        
        this.props.updateCategory(this.state.category);
      }
    handleRadioChange(number) {
        this.setState(
            {category: number}
        )
        const updatedCategories = [...this.state.categories];
        const currentCategoriesIndex = updatedCategories.findIndex(
          (item) => item.number === number
        );
       if(updatedCategories[currentCategoriesIndex].flag){
        this.state.categories.map(category => category.flag = false);
        this.setState({
            category: '0'
        });
       }else{
        this.state.categories.map(category => category.flag = false); //del this adn see
        updatedCategories[currentCategoriesIndex] = {
          ...updatedCategories[currentCategoriesIndex],
          flag: !updatedCategories[currentCategoriesIndex].flag
        };
    
        this.setState({
            categories: updatedCategories
        });
      }    
    }
    render(){
        // style={this.state.show ? {position : 'fixed'} : {position : 'relative'}}
        return(
        <div className="category-all" style={this.state.show ? styles.allFixed : styles.allRelative}>
            <button className="category-header-btn" onClick={this.handelCaregoryVisible}
            style={this.state.show ? {display : 'none'} : {display : 'block'}}>
                Select category
                </button>
            <div className="category-container" style={this.state.show ? {display : 'block'} : {display : 'none'}}>
                <div className="feed-filters-header">
                    <button onClick={this.handelCaregoryVisible}>
                        <img src={iconBack} alt="icon back"/>
                            Back
                    </button>
                    <span className="category-title">Select a Category:</span>
                </div>
                <form className="category-form">
                {this.state.categories.map((category) => (
                    <label className="category-btn"
                    key={category.name}
                    style={category.flag ? {background : '#007bff'} : {background : 'rgba(255,255,255,0.1)'}}>
                                <input 
                                    name="lang"
                                    type="radio"
                                    value={category.number}
                                    onClick={() => this.handleRadioChange(category.number)}
                                />
                                <div className="category-btn-around">
                                    <div className="category-btn-up" style={category.flag ? {opacity: 1} : {opacity: 0.5}}>
                                        <img src={category.img} alt={category.name} />
                                    </div>                                
                                    <div className="category-down"
                                    style={category.flag ? {opacity: 1} : {opacity: 0.5}}>
                                        {category.name}
                                    </div>
                                </div>
                    </label>))}
                </form>
                <button className="category-footer-btn" onClick={this.handelCaregoryVisible}>Save Category & Back</button>
            </div>
        </div>   
        );
    }
}