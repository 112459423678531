import React, { Component } from "react";
import AvatarUploader from "react-avatar-uploader";
import { Link } from "react-router-dom"
import IconBack from '../../assets/img/icon-back.svg'
import {connect} from "react-redux";

class Registration4 extends Component {
  render() {
    return (
      <React.Fragment>
        <div 
          className={[
            "form-group registration-avatar-uploader-block", 
            this.props.formErrors().photo.length > 0 && this.props.isError
              ? "error"
              : "",
          ].join(" ")}
        >
          <AvatarUploader
            uploadURL={`${this.props.settings.mainUrl}/api/avatarUpload`}
            style={{ margin: "o auto" }}
            name={"image"}
            fileType={"image/jpeg, image/png, image/gif"}
            onFinished={(err, res) => {
              if (res.data !== "") {
                this.props.onChangePhoto(res.data);
              }
            }}
          />

          <div className={"errors-messages"}>
            {this.props.formErrors().photo.length > 0 && this.props.isError
              ? this.props.formErrors().photo
              : null}
          </div> 
        </div>
        <div style={{marginTop: "26px"}} className="title-firstName-lastName">Give yourself a title:</div>
        <div
          className={[
            "form-group form-group-reg4",
            this.props.formErrors().title.length > 0 && this.props.isError
              ? "error"
              : "",
          ].join(" ")}
        >
          <input
            type="text"
            className="form-control"
            placeholder="Title" //Ex: Photographer, Netflix Binger, etc.
            maxLength={20}
            onChange={(e) => {
              this.props.onChangeTitle(e);
            }}
            value={this.props.formData.title}
          />
          <div className={"errors-messages"}>
            {this.props.formErrors().title.length > 0 && this.props.isError
              ? this.props.formErrors().title
              : null}
          </div>
        </div>
        <div className="bottom-input-text">for example: photographer, lawyer, falcon tamer, etc...</div>
        <div className="form-group row position-absolut-btn-next">
          {/* <div className="col-sm-12"> */}
          <div className="registration-btn">          
              <button className="registration-btn-back" 
              style={{width: "45px", height: "45px", marginRight: "10px", borderRadius: "12px",
              background: "transparent", border: "1px solid #FFFFFF"}}
              >
                  <Link to={'/'}><img src={IconBack} alt="logoImage" height='19' width='11'/></Link>
              </button>
              <button type="button" className="registration-btn-next" 
              onClick={this.props.nextPage}
              style={{width: "335px", height: "45px", borderRadius: "10px", color: "#FFF"}}
              >Next</button>
            </div>
          {/* </div> */}
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return{
    settings: state.settings,
  }
}
export default connect(mapStateToProps)(Registration4) ;
