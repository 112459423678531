import axios from "axios";

export function subscribe(id_user1, id_user2) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.post(
      `${mainUrl}/api/subscriptions/subscribe/${id_user1}/${id_user2}`,
      {
        api_token: getState().user.user.api_token,
      }
    );
    return response;
  };
}

export function unsubscribe(id_user1, id_user2) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.patch(
      `${mainUrl}/api/subscriptions/unsubscribe/${id_user1}/${id_user2}`,
      {
        api_token: getState().user.user.api_token,
      }
    );
    return response;
  };
}

export function getFollows(id) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.get(``, {
      params: { api_token: getState().user.user.api_token },
    });
    return response;
  };
}
