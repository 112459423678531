import React,{Component} from 'react';
import DesktopNavbar from "../../components/headers/DesktopNavbar";
import MobileNavbar from "../../components/headers/MobileNavbar";
import BaseLayout from "../../layouts/BaseLayout";
import Tabbar from "../../components/headers/Tabbar";

import axios from 'axios'

class TermsOfServices extends Component{

  state = {
    content:''
  }

  componentDidMount() {
    let content = require('../../TextFiles/termsOfServices.txt');
    axios.get(content).then(response => {
      this.setState({
        content:response.data
      })
    })
  }

  render(){

    return <React.Fragment>
      <DesktopNavbar />
      <MobileNavbar />
      <BaseLayout>
        <aside className="col col-xl-3 order-xl-1 col-lg-12 order-lg-1 col-12 my-profile "></aside>
        <main
          className="col col-xl-6 order-xl-2 col-lg-12 order-lg-2 col-md-12 col-sm-12 col-12 mb-3 post-list"
          style={{ padding: "0 8px" }}
        >
          <div className="profile-account-holder  mb-3  bg-white post-item-list service-simple">
            <div className={" p-0 d-flex justify-content-center"}>
              <div
                className={
                  "d-flex align-items-center  justify-content-center"
                }
              >
                <div  className={'text-page-content'} dangerouslySetInnerHTML={{__html: this.state.content}} />
              </div>
            </div>
          </div>
        </main>
        <aside className="col col-xl-3 order-xl-3 col-lg-12 order-lg-3 col-12"></aside>
      </BaseLayout>
      <Tabbar />
    </React.Fragment>;
  }
}
export default TermsOfServices;