import React from 'react';
import { NavLink,withRouter } from "react-router-dom";



function DeactivateMyProfileSidebar(props) {

  return (<React.Fragment>
    <ul className={"p-0 desktop"} style={{ listStyleType: "none" }}>

      <li className={props.active === "about" ? "active" : ""}>
        <NavLink to={"/my-profile/about" + props.userLink}>
          About
        </NavLink>
      </li>
    </ul>

  </React.Fragment>)
}
export default withRouter(DeactivateMyProfileSidebar);