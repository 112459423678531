import React from "react";
import "./ConversationSearch.css";

export default function ConversationSearch(props) {
  return (
    <div className="conversation-search">
      <input
        type="search"
        className="conversation-search-input"
        placeholder="Search Messages"
        value={props.search}
        onChange={(e) => {
          let searchValue = e.target.value;

          props.onChangeHandler(searchValue);
          if (searchValue.length > 0) {
            props.searchUsers(searchValue).then((response) => {
              let newConversations = response.data.map((result) => {
                return {
                  photo: result.avatar,
                  name: result.full_name,
                  text: "",
                  id: result.id,
                  id_name: result.name,
                };
              });
              props.setSearchConversations(newConversations);
              props.setSearchResult(response.data);
            });
          }
        }}
      />
    </div>
  );
}
