import React from 'react';
import './Toolbar.css';
import {withRouter} from 'react-router-dom';

 function Toolbar(props) {
    const { title, leftItems, rightItems,link } = props;
    return (
      <div className="toolbar">
        <div className="left-items">{ leftItems }</div>
        <h1 className="toolbar-title" style={{
            cursor:'pointer'
        }} onClick={() => {
            props.history.push(link);
        }}>{ title }</h1>
        <div className="right-items">{ rightItems }</div>
      </div>
    );
}
export default withRouter(Toolbar);