import React, { Component } from "react";
import DesktopNavbar from "../../components/headers/DesktopNavbar";
import MobileNavbar from "../../components/headers/MobileNavbar";
import BaseLayout from "../../layouts/BaseLayout";
import MyProfileSidebar from "../../components/sidebars/MyProfileSidebar";
import MyProfileSidebarMobile from "../../components/sidebars/MyProfileSidebarMobile";
import ImageGallery from "react-image-gallery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCommentDots,
  faDollarSign,
  faEye,
  faHeart,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import FeedContextMenu from "../../components/FeedContextMenu/FeedContextMenu";
import MyProfileUserBlock from "../../components/sidebars/MyProfileUserBlock";
import Tabbar from "../../components/headers/Tabbar";
import {
  trendingExhibits,
  like,
  likeStar,
  postDelete,
  unlike,
} from "../../redux/actions/postActions";
import { getUserById, getUserByName } from "../../redux/actions/userActions";
import { connect } from "react-redux";
import TrendingSidebarDesktop from "../../components/sidebars/TrendingSidebarDeesktop";
import TrendingSidebarMobile from "../../components/sidebars/TrendingSidebarMobile";
import FeedItem from "../../components/FeedItem";

class TrendingExhibits extends Component {
  state = {
    feed: [],
    user: {},
  };

  componentDidMount() {
    if (!this.props.match.params.user) {
      this.props.trendingExhibits(this.props.user.id).then((response) => {
        this.setState({
          feed: [...response.data],
        });
      });
    } else {
      this.props
        .getUserByName(this.props.match.params.user)
        .then((response) => {
          this.setState(
            {
              user: { ...response.data },
            },
            () => {
              this.props.trendingExhibits(response.data.id).then((response) => {
                this.setState({
                  feed: [...response.data],
                });
              });
            }
          );
        });
    }
  }

  deleteFromList = (index) => {
    let feeds = [...this.state.feed];
    feeds.splice(index, 1);
    this.setState({
      feed: [...feeds],
    });
  };

  likeStar = (feed, index) => {
    this.props.likeStar(feed.id, this.props.user.id).then((response) => {
      let feeds = [...this.state.feed];
      feeds[index] = { ...response.data };

      this.setState({ feed: [...feeds] });
    });
  };

  like = (feed, index) => {
    this.props.like(feed.id, this.props.user.id).then((response) => {
      let feeds = [...this.state.feed];
      feeds[index] = { ...response.data };

      this.setState({ feed: [...feeds] });
    });
  };

  render() {
    return (
      <React.Fragment>
        <DesktopNavbar />
        <MobileNavbar />
        <BaseLayout>
          <aside className="col col-xl-3 order-xl-1 col-lg-12 order-lg-1 col-12 my-profile ">
            <div className={" row sidebar-menu"}>
              <div className={"col-12 "}>
                <div className={"bg-white profile-account-holder desktop"}>
                  <h2>@{this.props.user.name}</h2>
                  <TrendingSidebarDesktop
                    active={"exhibits"}
                    userLink={
                      Object.keys(this.state.user).length > 0
                        ? `/${this.state.user.name}`
                        : ""
                    }
                  />
                </div>
                <div className={"mobile"}>
                  <TrendingSidebarMobile
                    active={{
                      value: "/trending/exhibits",
                      label: "Exhibits",
                    }}
                    userLink={
                      Object.keys(this.state.user).length > 0
                        ? `/${this.state.user.name}`
                        : ""
                    }
                  />
                </div>
              </div>
            </div>
          </aside>
          <main
            className="col col-xl-6 order-xl-2 col-lg-12 order-lg-2 col-md-12 col-sm-12 col-12 mb-3 post-list"
            style={{ padding: "0 8px" }}
          >
            {this.state.feed.map((feed, index) => {
              return (
                <FeedItem
                  key={`trending-exhibits-${index}`}
                  feed={feed}
                  index={index}
                  deleteFromList={this.deleteFromList}
                  likeStar={this.likeStar}
                  like={this.like}
                  feedLink={`/trending-exhibit/${feed.id}`}
                />
              );
            })}
          </main>
          <aside className="col col-xl-3 order-xl-3 col-lg-12 order-lg-3 col-12">
            <MyProfileUserBlock user={this.props.user} />
          </aside>
        </BaseLayout>
        <Tabbar />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user.user,
    settings: state.settings,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    like: (id_post, id_user) => {
      return dispatch(like(id_post, id_user));
    },
    likeStar: (id_post, id_user) => {
      return dispatch(likeStar(id_post, id_user));
    },
    unlike: (id_post, id_user) => {
      return dispatch(unlike(id_post, id_user));
    },
    trendingExhibits: (id_user) => {
      return dispatch(trendingExhibits(id_user));
    },
    getUserById: (id_user) => {
      return dispatch(getUserById(id_user));
    },
    getUserByName: (name) => {
      return dispatch(getUserByName(name));
    },
    postDelete: (id) => {
      return dispatch(postDelete(id));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TrendingExhibits);
