import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class MyProfileSidebar extends Component {
  componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        <ul className={"p-0"} style={{ listStyleType: "none" }}>
          <li className={this.props.active === "highlights" ? "active" : ""}>
            <NavLink to={"/my-profile/highlights" + this.props.userLink}>
              Highlights
            </NavLink>
          </li>
          <li className={this.props.active === "feed" ? "active" : ""}>
            <NavLink to={"/my-profile/feed" + this.props.userLink}>
              {this.props.isOwner ? 'My Feed' : 'Feed'}
            </NavLink>
          </li>
          <li className={this.props.active === "exhibits" ? "active" : ""}>
            <NavLink to={"/my-profile/exhibits" + this.props.userLink}>
              {this.props.isOwner ? 'My Exhibits' : 'Exhibits'}

            </NavLink>
          </li>
          <li className={this.props.active === "services" ? "active" : ""}>
            <NavLink to={"/my-profile/services" + this.props.userLink}>
              {this.props.isOwner ? 'My Services' : 'Services'}

            </NavLink>
          </li>
          <li className={this.props.active === "about" ? "active" : ""}>
            <NavLink to={"/my-profile/about" + this.props.userLink}>
              About
            </NavLink>
          </li>
        </ul>
      </React.Fragment>
    );
  }
}
export default MyProfileSidebar;
