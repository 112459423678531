import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { userViewToken, userNewData } from "./../redux/actions/userActions";
import { createDeviceUser } from "../redux/actions/pushNotificationActions";

const ProtectedRoute = ({ component: Component, ...props }) => {
  useEffect(() => {
    if (Object.keys(props.user).length > 0) {
      props.userViewToken().then((response) => {
        props.userNewData(response.data);
      });
      OneSignalInit();
    }
  }, []);

  let OneSignalInit = () => {
    var OneSignal = window.OneSignal || [];
    OneSignal.push(function () {
      OneSignal.init({
        appId: "a63b381d-58ad-4d99-aeac-f14039437bf2",
        notifyButton: {
          enable: true,
        },
      });
      OneSignal.showNativePrompt();
    });
    OneSignal.push(() => {
      OneSignal.getUserId((userId) => {
        console.log("player_id of the subscribed user is : " + userId);
        props.createDeviceUser(userId);
      });
    });
  };

  return (
    <Route
      {...props}
      render={(data) => {
        return (
          <React.Fragment>
            {Object.keys(props.user).length > 0 ? (
              <Component {...data} />
            ) : (
              <Redirect to={"/login"} />
            )}
          </React.Fragment>
        );
      }}
    />
  );
};

function mapStateToProps(state) {
  return {
    user: state.user.user,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    userViewToken: () => {
      return dispatch(userViewToken());
    },
    userNewData: (data) => {
      dispatch(userNewData(data));
    },
    createDeviceUser: (id_device) => {
      return dispatch(createDeviceUser(id_device));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);
