import React,{Component} from 'react';
import {connect} from 'react-redux';
import DesktopNavbar from "../../components/headers/DesktopNavbar";
import MobileNavbar from "../../components/headers/MobileNavbar";
import BaseLayout from "../../layouts/BaseLayout";
import {getUserByName} from './../../redux/actions/userActions'
import Tabbar from "../../components/headers/Tabbar";
import MyProfileSidebar from "../../components/sidebars/MyProfileSidebar";
import MyProfileSidebarMobile from "../../components/sidebars/MyProfileSidebarMobile";
import MyProfileUserBlock from "../../components/sidebars/MyProfileUserBlock";
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import DateTime from 'react-datetime';
import {createRequestVideochat,simplePay} from  './../../redux/actions/messengerActions';

import {Modal} from "react-bootstrap";
import StripeCustomer from "../StripeCustomer/StripeCustomer";
require('react-datetime/css/react-datetime.css');


class RequestCall extends Component{
    state = {
        user:{},
        isPaymentShow: false,
        formData:{
            type:'audio',
            long:'15min',
            date:{
                date:'',
                time:'12:00 AM',
                value:''
            },
            description:''
        },
        requestCall:{}
    };

    componentDidMount() {
        this.props
            .getUserByName(this.props.match.params.user)
            .then((response) => {
                this.setState(
                    {
                        user: {...response.data},
                    });
            });
    }

    hidePayment = () => {
        this.setState({
            isPaymentShow:false
        })
    }

    showPayment = () => {
        this.setState({
            isPaymentShow:true
        })
    }

    render() {

        return <React.Fragment>
            <DesktopNavbar />
            <MobileNavbar >{Object.keys(this.state.user).length > 0 ? `@${this.state.user.name}` : ' ' }</MobileNavbar>
            <BaseLayout>
                <aside className="col col-xl-3 order-xl-1 col-lg-12 order-lg-1 col-12 my-profile ">
                    <div className={" row sidebar-menu"}>
                        <div className={"col-12 "}>
                            <div className={"bg-white profile-account-holder desktop"}>
                                <h2>@{this.state.user.name}</h2>
                                <MyProfileSidebar
                                    active={""}
                                    isOwner={false}
                                    userLink={
                                        Object.keys(this.state.user).length > 0
                                            ? `/${this.state.user.name}`
                                            : ""
                                    }
                                />
                            </div>
                            <div className={"mobile"}>
                                <MyProfileSidebarMobile
                                    isOwner={false}
                                    active={{
                                        value: "",
                                        label: "",
                                    }}
                                    userLink={
                                        Object.keys(this.state.user).length > 0
                                            ? `/${this.state.user.name}`
                                            : ""
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </aside>
                <main
                    className="col col-xl-6 order-xl-2 col-lg-12 order-lg-2 col-md-12 col-sm-12 col-12 mb-3 post-list"
                    style={{ padding: "0 8px" }}
                >
                    <div className="profile-account-holder  mb-3  bg-white post-item-list">
                        <div
                            className={"post-header p-2 d-flex justify-content-between"}
                        >
                            <div className={"d-flex align-items-center"}>
                                <NavLink
                                    to={
                                        "/my-profile/highlights/" + this.state.user.name
                                    }
                                    style={{ color: "#000", marginRight: 20, fontSize: 16 }}
                                >
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                </NavLink>
                                <img
                                    src={
                                        this.props.settings.mainUrl +
                                        "/storage/" +
                                        this.state.user.avatar
                                    }
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        this.props.history.push(
                                            `/my-profile/highlights/${this.state.user.name}`
                                        );
                                    }}
                                    className={"rounded-circle mr-3"}
                                    width={30}
                                />
                                <div className={"position-relative post-author  d-flex"}>
                                    <div className={"font-weight-600"}>
                                        {" "}
                                        {this.state.user.full_name}
                                    </div>
                                    <div className={"author-badge"} style={{}}>
                                        {
                                         Object.keys(this.state.user ).length > 0 &&  this.state.user.badge !== ''? <img src={require(`../../assets/img/${this.state.user.badge}.png`)} width={15} />   : null
                                        }

                                    </div>
                                </div>
                            </div>
                            <div>

                            </div>
                        </div>
                        <div className={'request-call-container'}>
                            <div className={'form-container'}>
                                <div className={'form-row'}>
                                    <div className={'col-12'}>
                                        <h2 >Type of Call</h2>
                                    </div>
                                    <div className={'col-12'}>
                                        <div className={'row'}>
                                            <label className={'col-3 radio-button-custom'}>
                                                <input type={'radio'} value={'audio'} name={'type_call'} onClick={e => {
                                                    this.setState({formData:{
                                                        ...this.state.formData, type:e.target.value
                                                        }})
                                                }} checked={this.state.formData.type === 'audio'}/>
                                                <span className={'text'} >Audio Only</span>
                                            </label>
                                            <label className={'col-3 radio-button-custom'}>
                                                <input type={'radio'} value={'video'} onClick={e => {
                                                    this.setState({formData:{
                                                            ...this.state.formData, type:e.target.value
                                                        }})
                                                }} name={'type_call'} checked={this.state.formData.type === 'video'}/>
                                                <span className={'text'}>Video Chat</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className={'form-row'}>
                                    <div className={'col-12'}>
                                        <h2 >About How Long</h2>
                                    </div>
                                    <div className={'col-9'}>
                                        <div className={'row'}>
                                            <label className={'col-4 radio-button-custom'}>
                                                <input type={'radio'} value={'15min'} onClick={e => {
                                                    this.setState({formData:{
                                                            ...this.state.formData, long:e.target.value
                                                        }})
                                                }} name={'long_call'} checked={this.state.formData.long === '15min'}/>
                                                <span className={'text'}>15 min.</span>
                                            </label>
                                            <label className={'col-4 radio-button-custom'}>
                                                <input type={'radio'} value={'30min'} onClick={e => {
                                                    this.setState({formData:{
                                                            ...this.state.formData, long:e.target.value
                                                        }})
                                                }} name={'long_call'} checked={this.state.formData.long === '30min'}/>
                                                <span className={'text'}>30 min.</span>
                                            </label>
                                            <label className={'col-4 radio-button-custom'}>
                                                <input type={'radio'} value={'45min'} onClick={e => {
                                                    this.setState({formData:{
                                                            ...this.state.formData, long:e.target.value
                                                        }})
                                                }} name={'long_call'} checked={this.state.formData.long === '45min'}/>
                                                <span className={'text'}>45 min.</span>
                                            </label>
                                            <label className={'col-4 radio-button-custom'}>
                                                <input type={'radio'} value={'1hour'} onClick={e => {
                                                    this.setState({formData:{
                                                            ...this.state.formData, long:e.target.value
                                                        }})
                                                }} name={'long_call'} checked={this.state.formData.long === '1hour'}/>
                                                <span className={'text'}>1 Hour</span>
                                            </label>
                                            <label className={'col-4 radio-button-custom'}>
                                                <input type={'radio'} value={'2hours'} onClick={e => {
                                                    this.setState({formData:{
                                                            ...this.state.formData, long:e.target.value
                                                        }})
                                                }} name={'long_call'} checked={this.state.formData.long === '2hours'}/>
                                                <span className={'text'}>2 Hours</span>
                                            </label>
                                            <label className={'col-4 radio-button-custom'}>
                                                <input type={'radio'} value={'3hours'} onClick={e => {
                                                    this.setState({formData:{
                                                            ...this.state.formData, long:e.target.value
                                                        }})
                                                }} name={'long_call'} checked={this.state.formData.long === '3hours'}/>
                                                <span className={'text'}>3 Hours</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className={'form-row'}>
                                    <div className={'col-12'}>
                                        <h2 >Suggest a Time</h2>
                                        <div className={'col-9'}>
                                            <div className={'row'}>

                                                <div className={'col-6'}>
                                                    <DateTime
                                                        timeFormat={false}
                                                        inputProps={{required:true,className:'form-control bordered'}}
                                                        defaultValue={this.state.formData.date.date}

                                                        onChange={(e) => {
                                                            let date = e;
                                                            if(typeof e === 'object'){
                                                                date = e.format('MM/DD/YYYY');
                                                            }

                                                            this.setState({
                                                                ...this.state,
                                                                formData:{
                                                                    ...this.state.formData,
                                                                    date:{
                                                                        ...this.state.formData.date,
                                                                        date:date,
                                                                        value:`${date} ${this.state.formData.date.time}`
                                                                    }
                                                                }

                                                            });
                                                        }}
                                                    />
                                                </div>
                                                <div className={'col-6'}>
                                                    <DateTime
                                                        timeConstraints={{ minutes: { step: 5 } }}
                                                        inputProps={{className:'form-control bordered'}}
                                                        value={this.state.formData.date.time} onChange={(e) => {
                                                        let date = e;
                                                        if(typeof e === 'object'){
                                                            date = e.format('hh:mm A');
                                                        }
                                                        this.setState({
                                                            ...this.state,
                                                            formData:{
                                                                ...this.state.formData,
                                                                date:{
                                                                    ...this.state.formData.date,
                                                                    time:date,
                                                                    value:`${this.state.formData.date.date} ${date}`

                                                                }
                                                            }
                                                        })
                                                    }}
                                                        dateFormat={false} />



                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className={'form-row'}>
                                    <div className={'col-12'}>
                                        <h2 >Call Details</h2>
                                    </div>
                                    <div className={'col-12'}>
                                        <textarea placeholder={'When did you wont to tal about...'}
                                                  onChange={e => {this.setState({
                                                      formData:{
                                                          ...this.state.formData,
                                                          description:e.target.value
                                                      }})}}
                                                  value={this.state.formData.description}
                                                  className={'form-control bordered'} rows={6}/>
                                    </div>
                                </div>
                            </div>
                            <div className="button-block">
                                <button
                                    className={"checkout-button btn btn-primary"}
                                    style={{ width: "100%" }}
                                    onClick={() => {
                                        this.props.createRequestVideochat(this.state.formData,this.state.user).then(response => {
                                            this.setState({
                                                requestCall:{
                                                    ...response.data
                                                }
                                            }, () => {
                                                if(this.props.user.customer_id !== null || this.props.user.payment_method_id !== null){
                                                    if(window.confirm('Do you confirm payment?')){
                                                        this.props.simplePay(this.state.requestCall).then(response => {
                                                            window.location.href = '/';
                                                        });
                                                    }
                                                }
                                                else{
                                                    this.showPayment();

                                                }

                                            })
                                        })
                                    }}
                                >Request Call ($1/minute)</button>

                                <Modal show={this.state.isPaymentShow} onHide={() => this.hidePayment()}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>
                                            Payment
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <StripeCustomer
                                            data={{
                                                type:'call',
                                                data:{
                                                    ...this.state.requestCall
                                                }
                                            }}
                                            hidePayment={() => {

                                                this.hidePayment();
                                            }}
                                        />
                                    </Modal.Body>
                                </Modal>

                                {/*<CallPayment*/}
                                {/*    type={"call"}*/}
                                {/*    data={this.state.formData}*/}
                                {/*    createVideochat={this.props.createRequestVideochat}*/}
                                {/*    buyer={this.props.user.id}*/}
                                {/*    callSeller={this.state.user}*/}
                                {/*/>*/}
                                {/*<button className="btn btn-primary" type={'button'} style={{width:'100%'}} onClick={() => {*/}
                                {/*    // this.props.createRequestVideochat(this.state.formData,this.state.user).then(response => {*/}
                                {/*    //*/}
                                {/*    // })*/}
                                {/*}}>Request Call ($1/minute)</button>*/}
                            </div>
                            <div className="text-center footer-call-request">By placing the order you agree to the Witit <a
                                href="">Terms of Service</a></div>
                        </div>
                    </div>
                </main>
                <aside className="col col-xl-3 order-xl-3 col-lg-12 order-lg-3 col-12">
                    <MyProfileUserBlock user={this.state.user} />
                </aside>
            </BaseLayout>
            <Tabbar />
        </React.Fragment>
    }
}
function mapStateToProps(state) {
    return {
        user: state.user.user,
        settings: state.settings,
    }
}
function mapDispatchToProps(dispatch) {
   return {
       getUserByName: (name) => {
           return dispatch(getUserByName(name));
       },
       createRequestVideochat:(formData, user) => {
           return dispatch( createRequestVideochat(formData,user));
       },
       simplePay:(videochat) => {
           return dispatch(simplePay(videochat));
       }
   }
}
export default connect(mapStateToProps,mapDispatchToProps)(RequestCall) ;