import React from 'react';
import {withRouter} from 'react-router-dom';
import Service from '../assets/img/service.svg'
import Content from '../assets/img/content.svg'

function CreatePostAndServiceNavbar(props) {
  return <React.Fragment>
    <div className={'row mb-2 service-post-nav'} style={{
      marginLeft: -16,
      marginRight: -16
    }}>
      <div>
      {/* <div className={'col-6'}> */}
        <button style={{fontSize:16, display: "flex", alignItems:"center", gap: "11.25px"}} className={[
          'btn',
          'btn-large',
          'btn-full-width',
          props.type === 'post' ? 'btn-primary' : 'btn-light'
        ].join(' ')} type={'button'} onClick={() => {
          props.history.push('/create-post')
        }}><img src={Service}/>Service</button>
      </div>
      <div className='service-content'></div>
      <div>
      {/* <div className={'col-6'}> */}
        <button style={{fontSize:16, display: "flex", alignItems:"center", gap: "11.25px"}} className={[
          'btn',
          'btn-large',
          'btn-full-width',
          props.type === 'service' ? 'btn-primary' : 'btn-light'
        ].join(' ')} type={'button'} onClick={() => {
          props.history.push('/create-service')
        }}><img src={Content}/>Content</button>
      </div>
    </div>
  </React.Fragment>
}
export default withRouter(CreatePostAndServiceNavbar);