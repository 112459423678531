import React, { Component } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DesktopNavbar from "../../components/headers/DesktopNavbar";
import FontAwesome from "react-fontawesome";
import { connect } from "react-redux";
import {getSkillCategoriesList} from '../../redux/actions/skillCategoryActions';
import {PaginatedList} from 'react-paginated-list';
import MobileNavbar from "../../components/headers/MobileNavbar";
import BaseLayout from "../../layouts/BaseLayout";
import Select from "react-select";
import Slider from "react-slick";
import Tabbar from "../../components/headers/Tabbar";
import { withRouter } from "react-router-dom";
import { skills, getUsersBySkill } from "../../redux/actions/userActions";
import { showBySkillCategory } from "../../redux/actions/serviceActions";
import { stylesMobileSidebarSelect } from "../../redux/actions/functionActions";

class Communities extends Component {
  state = {
    communitiesValue: { value: 0, label: "All Communities" },
    communities: [{ value: 0, label: "All Communities" }],
    users: [],
    services: [],
    service: [1, 2, 3],
    userSort:'followers',
    searchField: ''

  };

  componentDidMount() {
    this.props.getSkillCategoriesList().then((response) => {
      let categories = [...this.state.communities];
      response.data.map((category, index) => {
        categories.push({value:category.id,label:category.name })
      });
      this.setState(
        {
          communities: [...categories],
        },
        () => {
          this.props
            .getUsersBySkill(this.state.communitiesValue,this.state.userSort)
            .then((response) => {
              this.setState(
                {
                  users: [...response.data],
                },
                () => {
                  this.props
                    .showBySkillCategory(this.state.communitiesValue.value)
                    .then((response) => {
                      this.setState({
                        services: [...response],
                      });
                    });
                }
              );
            });
        }
      );
    });
  }



  render() {
    const settings = {
      dots: true,
      infinite: true,
      arrows: false,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 2,
    };

    return (
      <React.Fragment>
        <DesktopNavbar />
        <MobileNavbar > </MobileNavbar>
        <BaseLayout>
          <div className={'mobile col-12'}>
            <div className={'search-field-mobile mb-2'}>
              <input value={this.state.searchField} placeholder={'Search'} onChange={e => {
                this.setState({
                  searchField:e.target.value
                })
              }}/>
              <a href={''} className={'d-inline-block'} onClick={event => {
                event.preventDefault();
                this.props.history.push(`/search-page/${this.state.searchField}`);
              }}>
                <FontAwesome name={'search'}/>
              </a>

            </div>

          </div>
          <aside className="col col-xl-3 order-xl-1 col-lg-12 order-lg-1 col-12 my-profile ">
            <Select
              options={this.state.communities}
              className={"mb-2 mobile"}
              styles={stylesMobileSidebarSelect()}
              defaultValue={this.state.communitiesValue}
              value={this.state.communitiesValue}
              onChange={(e) => {
                this.setState({ communitiesValue: e });
              }}
            />
            <div className={" row sidebar-menu desktop"}>
              <div className={"col-12 "}>
                <div className={"bg-white profile-account-holder"}>
                  <h2>Categories</h2>
                  <ul className={"p-0"} style={{ listStyleType: "none" }}>
                    {this.state.communities.map((item, index) => {
                      return (
                        <li
                          key={`communities_list_category${index}`}
                          className={
                            item.value === this.state.communitiesValue.value
                              ? "active purple"
                              : ""
                          }
                        >
                          <a
                            href={""}
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ communitiesValue: item }, () => {
                                this.props
                                  .getUsersBySkill(this.state.communitiesValue,this.state.userSort)
                                  .then((response) => {
                                    this.setState(
                                      {
                                        users: [...response.data],
                                      },
                                      () => {
                                        this.props
                                          .showBySkillCategory(
                                            this.state.communitiesValue.value
                                          )
                                          .then((response) => {
                                            this.setState({
                                              services: [...response],
                                            });
                                          });
                                      }
                                    );
                                  });
                              });
                            }}
                          >
                            {item.label}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </aside>
          <main
            className="col col-xl-9 order-xl-2 col-lg-12 order-lg-2 col-md-12 col-sm-12 col-12 mb-3 post-list"
            style={{ padding: "0 8px" }}
          >
            {" "}
            <div className=" mb-3 desktop">
              <div className={"row"}>
                <div className={"col-12 "}>
                  <div className={"top-services bg-white box shadow "}>
                    <h2 className={"boldy"}>Top Services</h2>
                    <div >
                      <PaginatedList
                        list={this.state.services}
                        itemsPerPage={4}
                        nextText={<FontAwesome name={'chevron-right'} />}
                        prevText={<FontAwesome name={'chevron-left'} />}
                        useMinimalControls={true}
                        renderList={(list) => (
                          <div className={'row'}>
                            {list.map((service, index) => {
                              return (
                                <div
                                  className={"col-3"}
                                  key={`desktop-services-${index}`}
                                >
                                  <div className="top-services-item box shadow-sm border rounded bg-white ">
                                    <div className="card  card-recommendation-user online ">
                                      <img
                                        className="card-img-top"
                                        src={
                                          this.props.settings.mainUrl +
                                          "/storage/" +
                                          service.description_image
                                        }
                                        alt="Card image cap"
                                      />
                                      <div className="avatar-block">
                                        <div className="img-block rounded-circle">
                                          <img
                                            className="rounded-circle"
                                            src={
                                              this.props.settings.mainUrl +
                                              "/storage/" +
                                              service.user.avatar
                                            }
                                            style={{
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              this.props.history.push(
                                                `/my-profile/highlights/${service.user.name}`
                                              );
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div>
                                        <div className="card-body">
                                          <div className="card-title position-relative ">
                                            <h5
                                              className="title font-weight-600"
                                              style={{ fontSize: 13 }}
                                            >
                                              {service.user.full_name}
                                            </h5>
                                            {
                                              service.user.badge !== ''? <img src={require(`../../assets/img/${service.user.badge}.png`)} width={15} />   : null
                                            }

                                          </div>
                                          <h6
                                            className="text-center font-weight-600 mb-3"
                                            style={{ fontSize: 12 }}
                                          >
                                            {service.name}
                                          </h6>
                                          <p className="card-text">
                                            {service.description}
                                          </p>
                                        </div>
                                        <a
                                          href="#"
                                          className="btn btn-primary btn-block card-button"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.props.history.push(
                                              `/service/${service.id}`
                                            );
                                          }}
                                        >
                                          Order for ${service.price}
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      />

                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className={"mb-3 mobile"}>
              <div className={"row"}>
                <div className={"col-12 "}>
                  <div className={" bg-white box shadow p-3 pb-5"}>
                    <h2
                      className={
                        "boldy text-sm-center text-center text-md-left text-lg-left"
                      }
                    >
                      Top Services
                    </h2>

                    <Slider
                      slidesToShow={2}
                      dots={true}
                      infinite={false}
                      arrows={false}
                    >
                      {this.state.services.map((service, index) => {
                        return (
                          <div
                            className={"pl-2 pr-2"}
                            key={`desktop-services-${index}`}
                          >
                            <div className="box shadow-sm border rounded bg-white ">
                              <div className="card  card-recommendation-user online ">
                                <img
                                  className="card-img-top"
                                  src={
                                    this.props.settings.mainUrl +
                                    "/storage/" +
                                    service.description_image
                                  }
                                  alt="Card image cap"
                                />
                                <div className="avatar-block">
                                  <div className="img-block rounded-circle">
                                    <img
                                      className="rounded-circle"
                                      src={
                                        this.props.settings.mainUrl +
                                        "/storage/" +
                                        service.user.avatar
                                      }
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        this.props.history.push(
                                          `/my-profile/highlights/${service.user.name}`
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                                <div>
                                  <div className="card-body">
                                    <div className="card-title position-relative ">
                                      <h5
                                        className="title font-weight-600"
                                        style={{ fontSize: 13 }}
                                      >
                                        {service.user.full_name}
                                      </h5>
                                      {
                                        service.user.badge !== ''? <img src={require(`../../assets/img/${service.user.badge}.png`)} width={15} />   : null
                                      }

                                    </div>
                                    <h6
                                      className="text-center font-weight-600 mb-3"
                                      style={{ fontSize: 12 }}
                                    >
                                      {service.name}
                                    </h6>
                                    <p className="card-text">
                                      {service.description}
                                    </p>
                                  </div>
                                  <a
                                    href="#"
                                    className="btn btn-primary btn-block card-button"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.props.history.push(
                                        `/service/${service.id}`
                                      );
                                    }}
                                  >
                                    Order for ${service.price}
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
            <div className=" mb-3  communities-box ">
              <div className={"row"}>
                <div className={"col-lg-12 mb-3"}>
                  <div className={"community-box bg-white box shadow "}>
                    <div>
                      <h2
                        className={
                          "text-sm-center text-center text-md-left text-lg-left"
                        }
                      >
                        Community
                      </h2>
                      <div
                        className={
                          "text-sm-center text-center text-md-left text-lg-left  mb-2"
                        }
                      >
                        Sort by:{" "}
                        <select
                          style={{ listStyleType: "none" }}
                          className={"m-0 p-0 sort-by"}
                          defaultValue={this.state.usersSort}
                          onChange={event => {
                            this.setState({
                              userSort:event.target.value
                            }, () => {
                              this.props
                                .getUsersBySkill(this.state.communitiesValue,this.state.userSort)
                                .then((response) => {
                                  this.setState(
                                    {
                                      users: [...response.data],
                                    },
                                    () => {
                                      this.props
                                        .showBySkillCategory(
                                          this.state.communitiesValue.value
                                        )
                                        .then((response) => {
                                          this.setState({
                                            services: [...response],
                                          });
                                        });
                                    }
                                  );
                                });
                            })
                          }}
                        >
                          <option className={"d-inline-block "} value={'followers'}>
                            Followers
                          </option>
                          <option className={"d-inline-block"} value={'experience'}>
                            Experience
                          </option>
                          {/*<option className={"d-inline-block"} value={'reputation'}>*/}
                          {/*  Reputation*/}
                          {/*</option>*/}
                        </select>
                      </div>
                    </div>

                    <div className={"row  communities-users"}>
                      {this.state.users.map((user, index) => {
                        return (
                          <div
                            className={
                              "col-lg-2 col-sm-4 col-4 communities-user"
                            }
                            key={`communities_users_${index}`}
                          >
                            <div
                              className={"user-item  "}
                              onClick={() => {
                                this.props.history.push(
                                  `/my-profile/highlights/${user.name}`
                                );
                              }}
                            >
                              <div>
                                <img
                                  src={
                                    this.props.settings.mainUrl +
                                    "/storage/" +
                                    user.avatar
                                  }
                                  className={"rounded-circle "}
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    this.props.history.push(
                                      `/my-profile/highlights/${user.name}`
                                    );
                                  }}
                                />
                              </div>

                              <div className={"position-relative post-author "}>
                                <div
                                  className={"font-weight-600 text-center name"}
                                >
                                  {" "}
                                  {user.full_name}
                                </div>
                                <div className={"profession text-center"}>
                                  {user.title}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </BaseLayout>
        <Tabbar />
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    settings: state.settings,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    skills: () => {
      return dispatch(skills());
    },
    getUsersBySkill: (skill,sort) => {
      return dispatch(getUsersBySkill(skill,sort));
    },
    showBySkillCategory: (category) => {
      return dispatch(showBySkillCategory(category));
    },
    getSkillCategoriesList:() => {
      return dispatch(getSkillCategoriesList());
    }
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Communities));
