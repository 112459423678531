import React from "react";
import "./Logo.scss";

function Logo() {
    return(
    <div className="logo">
        <h1 className="logo-title">Witit</h1>
        <h3 className="logo-subtitle">go get it.</h3>
    </div>
    )

}

export default Logo; 