import React,{Component} from 'react';
import DesktopNavbar from "../../components/headers/DesktopNavbar";
import MobileNavbar from "../../components/headers/MobileNavbar";
import BaseLayout from "../../layouts/BaseLayout";
import Tabbar from "../../components/headers/Tabbar";
import Select from "react-select";
import { stylesMobileSidebarSelect } from "../../redux/actions/functionActions";
import FontAwesome from 'react-fontawesome';
import {connect} from 'react-redux';
import {skills,getAgesMinMax,searchUsers} from '../../redux/actions/userActions'

class SearchPage extends Component{

  state = {
    searchTypes: [
      {value:'all',label:'All'},
      {value:'by-name',label:'By Name'},
      {value:'by-username',label:'By Username'},
      {value:'by-title', label:'By Title'}
    ],
    searchTypeActive:{value:'all',label:'All'},
    searchField:'',
    sort:'followers',
    filter:{
      city:'',
      skill:'',
      gender:'',
      age:{
        from:0,
        to:0
      }
    },
    minAge:0,
    maxAge:0,
    skills:[],
    results:[]
  };

  componentDidMount() {
    this.props.skills().then(response => {
      this.setState({
        skills:[...response.data]
      }, () => {
        this.props.getAgesMinMax().then(response => {
          this.setState({
            minAge:response.data.min,
            maxAge:response.data.max,
            filter:{
              ...this.state.filter,
              age:{
                from:response.data.min,
                to:response.data.max
              }
            }
          }, () => {
            let searchField = Object.keys(this.props.match.params).length > 0 ? this.props.match.params.search : '';
            this.setState({
              searchField:searchField
            },() => {
              this.props.searchUsers(this.state.searchField,this.state.searchTypeActive,this.state.sort,this.state.filter).then(response => {
                this.setState({
                  results:[...response.data]
                })
              });
            })
          } )
        });
      })
    })
  }

  render() {
    console.log(this.props.match)
    return <React.Fragment>
      <DesktopNavbar />
      <MobileNavbar />
      <div className={'search-page'}>
        <BaseLayout>
          <div className={'mobile col-12'}>
            <div className={'search-field-mobile mb-2'}>
              <input value={this.state.searchField} placeholder={'Search'} onChange={e => {
                this.setState({
                  searchField:e.target.value
                })
              }}/>
              <a href={''} className={'d-inline-block'} onClick={event => {
                event.preventDefault();
                this.props.searchUsers(this.state.searchField,this.state.searchTypeActive,this.state.sort,this.state.filter).then(response => {
                  this.setState({
                    results:[...response.data]
                  })
                });
              }}>
                <FontAwesome name={'search'}/>
              </a>

            </div>

          </div>
          <aside className="col col-xl-3 order-xl-1 col-lg-12 order-lg-1 col-12 my-profile left-sidebar ">
            <Select
              options={this.state.searchTypes}
              className={"mb-2 mobile"}
              styles={stylesMobileSidebarSelect()}
              defaultValue={this.state.searchTypeActive}
              value={this.state.searchTypeActive}
              onChange={(e) => {
                this.setState({ searchTypeActive: e });
              }}
            />
            <div className={" row sidebar-menu desktop"}>
              <div className={"col-12 "}>
                <div className={"bg-white profile-account-holder"}>
                  <h2>Search</h2>
                  <ul className={"p-0"} style={{ listStyleType: "none" }}>
                    {this.state.searchTypes.map((item, index) => {
                      return (
                        <li
                          key={`communities_list_category${index}`}
                          className={
                            item.value === this.state.searchTypeActive.value
                              ? "active"
                              : ""
                          }
                        >
                          <a
                            href={""}
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({
                                searchTypeActive:item
                              })
                            }}
                          >
                            {item.label}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </aside>
          <main
            className="col col-xl-6 order-xl-2 col-lg-12 order-lg-2 col-md-12 col-sm-12 col-12 mb-3 post-list"
            style={{ padding: "0 8px" }}
          >
            <div className="profile-account-holder  mb-3  bg-white post-item-list service-simple">
              <div className={" p-0 d-flex justify-content-center"}>
                <div
                  className={
                    "search-content-page"
                  }
                >
                  <div className={'search-field-block mb-2 desktop'}>
                    <input className={'search-field'} placeholder={'Search'} value={this.state.searchField} onChange={event => {
                      this.setState({
                        searchField: event.target.value
                      })
                    }}/>
                    <span>
                    <FontAwesome name={'search'}/>
                  </span>
                    <button className={'btn btn-primary'} onClick={() => {
                      this.props.searchUsers(this.state.searchField,this.state.searchTypeActive,this.state.sort,this.state.filter).then(response => {
                        this.setState({
                          results:[...response.data]
                        })
                      });
                    }}>Go</button>
                  </div>
                  <div className={'search-description mb-2'}>
                    Showing all results for: {this.state.searchField}
                  </div>
                  <div className={'search-sort'}>
                    Sort by:
                    <span className={'search-sort-radio-block'}>
                    <label htmlFor={'sort_followers'}>
                      <input type={'radio'} id={'sort_followers'} checked={this.state.sort === 'followers'} name={'sort-search'} value={'followers'} onChange={event => {
                        this.setState({
                          sort:event.target.value
                        })
                      }}/>
                      <span>Followers</span>
                    </label>
                    <label htmlFor={'sort_reputation'}>
                      <input type={'radio'} id={'sort_reputation'} checked={this.state.sort === 'reputation'} name={'sort-search'} value={'reputation'} onChange={event => {
                        this.setState({
                          sort:event.target.value
                        })
                      }}/>
                      <span>Reputation</span>
                    </label>
                    <label  htmlFor={'sort_experience'}>
                      <input type={'radio'} id={'sort_experience'} checked={this.state.sort === 'experience'} name={'sort-search'} value={'experience'} onChange={event => {
                        this.setState({
                          sort:event.target.value
                        })
                      }}/>
                      <span>Experience</span>
                    </label>
                  </span>
                  </div>
                  <div className={'search-results'}>
                    {this.state.results.length === 0 ? <h3>No results</h3> : null}
                    {this.state.results.map((result, index) => {
                      return <div className={'search-result-item'} key={`search-result-${index}`}>
                        <img src={`${this.props.settings.mainUrl}/storage/${result.avatar}`} style={{cursor:'pointer'}} onClick={() => {
                          this.props.history.push(`/my-profile/highlights/${result.name}`);
                        }}/>
                        <div className={'title'}>
                          <div className={'name'}>{result.full_name}</div>
                          <div className={'profession'}>{result.title}</div>
                        </div>
                      </div>
                    })}


                  </div>
                </div>
              </div>
            </div>
          </main>
          <aside className="col col-xl-3 order-xl-3 col-lg-12 order-lg-3 col-12 right-sidebar">
            <div className={'search-filter bg-white profile-account-holder'}>
              <h2 className={'boldy'}>Filter</h2>
              <div className={'params-list'}>
                <div className={'param-item mb-2'}>
                  <span className={'param-title mr-2'}>Location:</span>
                  <input className={'param-input-location'} placeholder={'type a city...'} value={this.state.filter.city} onChange={event => {
                    this.setState({
                      filter:{
                        ...this.state.filter,
                        city:event.target.value
                      }
                    })
                  }}/>
                </div>
                <div className={'param-item mb-2'}>
                  <span className={'param-title d-block mb-2'}>Skill:</span>
                  <select className={'param-input d-block'}  value={this.state.filter.skill} onChange={event => {
                    this.setState({
                      filter:{
                        ...this.state.filter,
                        skill:parseInt(event.target.value)
                      }
                    })
                  }}>
                    <option>All Skills</option>
                    {this.state.skills.map((skill,index) => {
                      return <option key={`filter_skill_${index}`} value={skill.value}>{skill.label}</option>
                    })}
                  </select>
                </div>

                <div className={'param-item mb-2'}>
                  <span className={'param-title d-block mb-2 mr-2'}>Gender They Identity As:</span>
                  <label htmlFor={'all-genders'} className={'radio-filter'}>
                    <input type={'radio'} name={'gender'} id={'all-genders'} value={''} onChange={event => {
                      this.setState({
                        filter:{
                          ...this.state.filter,
                          gender:event.target.value
                        }
                      })
                    }} checked={this.state.filter.gender === ''}/>
                    <span>All</span>
                  </label>
                  <label htmlFor={'male-gender'} className={'radio-filter'}>
                    <input type={'radio'} name={'gender'} id={'male-gender'} value={0}  onChange={event => {
                      this.setState({
                        filter:{
                          ...this.state.filter,
                          gender:parseInt(event.target.value)
                        }
                      })
                    }}  checked={this.state.filter.gender === 0}/>
                    <span>Male</span>
                  </label>
                  <label htmlFor={'female-gender'} className={'radio-filter'}>
                    <input type={'radio'} name={'gender'} id={'female-gender'} value={1}  onChange={event => {
                      this.setState({
                        filter:{
                          ...this.state.filter,
                          gender:parseInt(event.target.value)
                        }
                      })
                    }}  checked={this.state.filter.gender === 1}/>
                    <span>Female</span>
                  </label>

                </div>
                <div className={'param-item mb-2'}>
                  <span className={'param-title mr-2'}>Age Range:</span>
                  <input className={'param-input-range'} type={'number'} value={this.state.filter.age.from} onChange={event => {
                    this.setState({
                      filter:{
                        ...this.state.filter,
                        age:{
                          ...this.state.filter.age,
                          from:parseInt(event.target.value)
                        }
                      }
                    })
                  }} min={this.state.minAge} max={this.state.maxAge} />
                  <span className={'range-connector'}>to</span>
                  <input className={'param-input-range'} type={'number'} onChange={event => {
                    this.setState({
                      filter:{
                        ...this.state.filter,
                        age:{
                          ...this.state.filter.age,
                          to:parseInt(event.target.value)
                        }
                      }
                    })
                  }} value={this.state.filter.age.to}  min={this.state.minAge} max={this.state.maxAge}/>
                </div>
              </div>

              <button className={'btn btn-primary btn-full-width'}  onClick={() => {
                this.props.searchUsers(this.state.searchField,this.state.searchTypeActive,this.state.sort,this.state.filter).then(response => {
                  this.setState({
                    results:[...response.data]
                  })
                });
              }}>Apply</button>
            </div>
          </aside>
        </BaseLayout>
        <Tabbar />
      </div>

    </React.Fragment>
  }
}
function mapStateToProps(state) {
  return {
    user: state.user.user,
    settings: state.settings,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    skills:() => {
      return dispatch(skills());
    },
    getAgesMinMax:() => {
      return dispatch(getAgesMinMax());
    },
    searchUsers:(search,type,sort,filter) => {
      return dispatch(searchUsers(search,type,sort,filter));
    }
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(SearchPage) ;