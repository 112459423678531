import React, { Component } from "react";
import DesktopNavbar from "../../components/headers/DesktopNavbar";
import MobileNavbar from "../../components/headers/MobileNavbar";
import BaseLayout from "../../layouts/BaseLayout";
import { connect } from "react-redux";
import AvatarUploader from "react-avatar-uploader";
import Tabbar from "../../components/headers/Tabbar";
import { createService } from "../../redux/actions/serviceActions";
import { skills } from "../../redux/actions/userActions";
import CreatePostAndServiceNavbar from "../../components/CreatePostAndServiceNavbar";
import { Alert } from "react-bootstrap";

class CreateService extends Component {
  state = {
    formData: {
      name: "",
      price: null,
      description: "",
      description_image: "",
      skill: 0,
    },
    skills: [],
    isError: false,
    error: "",

  };

  componentDidMount() {
    this.props.skills().then((response) => {
      this.setState({
        skills: [{ value: 0, label: "Select skill ..." }, ...response.data],
      });
    });
  }

  validateForm =  () => {
    let message = [];
    if (
      this.state.formData.description_image === null ||
      this.state.formData.description_image === ""
    ) {
      message.push("Photo is required!");
    }
    if (
      this.state.formData.description === null ||
      this.state.formData.description === ""
    ) {
      message.push("Description is required!");
    }
    if (
      this.state.formData.price === 0 ||
      this.state.formData.price === null
    ) {
      message.push("Price is required!");
    }
    if (

      this.state.formData.name === "" ||
      this.state.formData.name === null
    ) {
      message.push("Name is required!");
    }
    if (

      this.state.formData.skill === 0 ||
      this.state.formData.skill === null
    ) {
      message.push("Skill is required!");
    }
    return message.join('<br>');
  };


  render() {
    console.log(this.state.formData);
    return (
      <React.Fragment>
        <DesktopNavbar />
        <MobileNavbar > </MobileNavbar>

        <BaseLayout>
          <aside className="col col-xl-2 order-xl-1 col-lg-12 order-lg-1 col-12 my-profile ">
            {" "}
          </aside>
          <main
            className="col col-xl-8 order-xl-2 col-lg-12 order-lg-2 col-md-12 col-sm-12 col-12 mb-3 create-post"
            style={{ padding: "0 8px" }}
          >
            <CreatePostAndServiceNavbar type={'service'}/>
            <div className="profile-account-holder row  bg-white">
              <div className={"col-12"}>
                <h2>Create a Service</h2>

                {this.state.isError ? (
                  <Alert variant={"danger"} >
                    <div dangerouslySetInnerHTML={{__html: this.state.error}}  /></Alert>
                ) : null}
                <div className={"row"}>
                  <div
                    className={"col-lg-auto text-center avatar-uploader-block"}
                  >
                    <AvatarUploader
                      uploadURL={
                        this.props.settings.mainUrl + "/api/serviceImageUpload"
                      }
                      style={{ margin: "o auto" }}
                      name={"image"}
                      fileType={"image/jpeg, image/png, image/gif"}
                      onFinished={(err, res) => {
                        this.setState({
                          formData: {
                            ...this.state.formData,
                            description_image: res.data,
                          },
                        });
                      }}
                    />
                  </div>
                  <div className={"col-lg"}>
                    <input
                      type={"text"}
                      placeholder={"Write a name ..."}
                      className={"form-control bordered"}
                      rows={4}
                      value={this.state.formData.name}
                      onChange={(e) => {
                        this.setState({
                          formData: {
                            ...this.state.formData,
                            name: e.target.value,
                          },
                        });
                      }}
                      style={{
                        marginBottom: 15,
                        marginTop: 15
                      }}
                    />

                    <select
                      className={"form-control bordered"}
                      style={{
                        marginBottom: 15,
                      }}
                      value={this.state.formData.skill}
                      onChange={(e) => {
                        this.setState({
                          formData: {
                            ...this.state.formData,
                            skill: e.target.value,
                          },
                        });
                      }}
                    >
                      {this.state.skills.map((skill, index) => {
                        return (
                          <option value={skill.value} key={`skill_${index}`}>
                            {skill.label}
                          </option>
                        );
                      })}
                    </select>
                    <textarea
                      value={this.state.formData.description}
                      className={"form-control bordered"}
                      rows={4}
                      style={{
                        marginBottom: 10,
                      }}
                      onChange={(event) => {
                        this.setState({
                          formData: {
                            ...this.state.formData,
                            description: event.target.value,
                          },
                        });
                      }}
                      placeholder={"Description"}
                    />
                    <input
                      type={"number"}
                      className={"form-control bordered"}
                      value={this.state.formData.price}
                      placeholder={"Write a price"}
                      onChange={(event) => {
                        this.setState({
                          formData: {
                            ...this.state.formData,
                            price: parseInt(event.target.value),
                          },
                        });
                      }}
                    />
                  </div>
                </div>
                <div
                  className={
                    "row justify-content-between align-items-center mt-3"
                  }
                >
                  <div className={"col-auto"}>
                    <button
                      type={"button"}
                      className={"btn btn-bordered-dark mr-3 mb-2"}
                      style={{
                        marginTop: 5,
                        border: "1px solid rgba(255,255,255,0.5)"
                      }}
                    >
                      Cancel
                    </button>

                    <button
                      type={"button"}
                      onClick={() => {
                        let error = this.validateForm();
                        if (error.length > 0) {
                          this.setState({
                            isError: true,
                            error: error,
                          });
                        } else {
                          this.setState(
                            {
                              error: "",
                              isError: false,
                            },
                            () => {
                              this.props
                                .createService(this.state.formData)
                                .then((response) => {
                                  this.props.history.push("/my-profile/services");
                                });
                            }
                          );
                        }


                      }}
                      className={"btn btn-primary mb-2"}
                      style={{
                        marginTop: 5,
                      }}
                    >
                      Create
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <aside
            className="col col-xl-2 order-xl-3 col-lg-12 order-lg-3 col-12"
            style={{ fontSize: 16 }}
          >
            When posting to Witit, please be sure you own the rights to the
            content you are posting if you are accepting money for the work.
            Also please be mindful of our <a href={""}>content policy</a>.
          </aside>
        </BaseLayout>
        <Tabbar />
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return { settings: state.settings, user: state.user.user };
}
function mapDispatchToProps(dispatch) {
  return {
    createService: (formData) => {
      return dispatch(createService(formData));
    },
    skills: () => {
      return dispatch(skills());
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateService);
