export function checkErrors(errors) {
  let isError = false;
  Object.keys(errors).map((objectKey, index) => {
    let value = errors[objectKey];
    if (value.length > 0) {
      isError = true;
    }
  });
  return isError;
}
