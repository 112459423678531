import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import Timer from 'react-compound-timer';

class TwilioTest extends Component {
  state = {
    roomName: "",
  };
  componentDidMount() {
    // axios
    //   .get("https://gambits.solver.com.ua/api/room/all")
    //   .then(function (response) {
    //     console.log(response);
    //   });
  }

  render() {
    return (
      <div>
          <Timer
              initialTime={60000 * 2 + 5000}
              direction="backward"
              checkpoints={[
                  {
                      time: 60000 * 2 ,
                      callback: () => console.log('Checkpoint A'),
                  },
                  {
                      time: 60000 * 2  - 5000,
                      callback: () => console.log('Checkpoint B'),
                  }
              ]}
          >
              {() => (
                  <React.Fragment>

                      <Timer.Hours />:
                      <Timer.Minutes />:
                      <Timer.Seconds />

                  </React.Fragment>
              )}
          </Timer>
      </div>
    );
  }
}
export default withRouter(TwilioTest);
