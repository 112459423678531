import React from "react";
import { NavLink } from "react-router-dom";

function TrendingSidebarDesktop(props) {
  return (
    <ul className={"p-0"} style={{ listStyleType: "none" }}>
      <li className={props.active === "feed" ? "active" : ""}>
        <NavLink to={"/trending/feed"}>Feed</NavLink>
      </li>
      <li className={props.active === "exhibits" ? "active" : ""}>
        <NavLink to={"/trending/exhibits"}>Exhibits</NavLink>
      </li>
      <li className={props.active === "services" ? "active" : ""}>
        <NavLink to={"/trending/services"}>Services</NavLink>
      </li>
    </ul>
  );
}
export default TrendingSidebarDesktop;
