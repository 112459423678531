import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import GuestDesktopNavbar from "../components/headers/GuestDesktopNavbar";
import { Button, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import {
  userVerify,
  userVerifyError,
  userVerifySuccess,
} from "./../redux/actions/userActions";
import GuestMobileNavbar from "../components/headers/GuestMobileNavbar";

class EmailVerify extends Component {
  componentDidMount() {
    let url = this.props.match.params.url;

    this.props
      .userVerify(url)
      .then((response) => {
        this.props.userVerifySuccess(response.data.message);
      })
      .catch((error) => {
        this.props.userVerifyError();
        console.log(this.props);
      });
    //  axios.get(decodeURIComponent(url));
  }

  render() {
    console.log(this.props);
    let colorMessage = "#006afe";

    // switch (this.props.verify.type) {
    //   case "danger":
    //     colorMessage = "#f00";
    //     break;
    //   case "success":
    //     colorMessage = "#006afe";
    //     break;
    // }
    return (
      <React.Fragment>
        <GuestDesktopNavbar />

        {this.props.verify !== undefined &&
        Object.keys(this.props.verify).length > 0 ? (
          <div className={"py-3"}>
            <div className={"container"}>
              <div className={"row"}>
                <div className={"col-6"}>
                  <Card>
                    <Card.Body
                      style={{
                        backgroundImage:
                          "url(" + require("./../assets/img/thanYou.jpg") + ")",
                        textAlign: "center",
                        fontSize: 30,
                        fontWeight: 900,
                        color: colorMessage,
                        padding: "100px 0",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        style={{ fontSize: 80, marginBottom: 30 }}
                      />
                      <br />
                      <span style={{}}>{this.props.verify.message}</span>
                    </Card.Body>
                  </Card>
                </div>
                <div className={"col-6"}>
                  <Card>
                    <Card.Body>
                      {this.props.verify.type === "success" ? (
                        <React.Fragment>
                          <h4
                            style={{
                              fontWeight: 900,
                            }}
                          >
                            Congratulation your email verified !
                          </h4>
                          <p>You can Sign In</p>
                          <span>
                            <Button
                              variant={"primary"}
                              onClick={() => {
                                this.props.history.push("/login");
                              }}
                            >
                              Sign In
                            </Button>
                          </span>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <h4
                            style={{
                              fontWeight: 900,
                            }}
                          >
                            Sorry, something went wrong
                          </h4>
                        </React.Fragment>
                      )}
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    verify: state.user.verify,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userVerify: (url) => {
      return dispatch(userVerify(url));
    },
    userVerifyError: (url) => {
      dispatch(userVerifyError());
    },
    userVerifySuccess: (message) => {
      dispatch(userVerifySuccess(message));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EmailVerify));
