import React, { Component } from "react";

class BaseLayout extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="py-3">
          <div className="container-fluid p-0">
            <div className="row">{this.props.children}</div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default BaseLayout;
