import React from "react";

function GuestMobileNavbar() {
  return (
    <div className={"mobile-navbar p-0 mobile bg-white"}>
      <div className="row">
        <div className={"col-6 "}>
          <div className={"d-flex justify-content-between"}></div>
        </div>
        <div className={"col-6"}>
          <div className={" d-flex justify-content-between"}></div>
        </div>
      </div>
    </div>
  );
}
export default GuestMobileNavbar;
