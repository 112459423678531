import React,{Component} from 'react';
import DesktopNavbar from "../../components/headers/DesktopNavbar";
import MobileNavbar from "../../components/headers/MobileNavbar";
import BaseLayout from "../../layouts/BaseLayout";
import DesktopSidebarAccountSettings from "../../components/sidebars/DesktopSidebarAccountSettings";
import { postReport } from "./../../redux/actions/actionReports";
import MyProfileUserBlock from "../../components/sidebars/MyProfileUserBlock";
import Tabbar from "../../components/headers/Tabbar";
import { connect } from "react-redux";
import MobileSidebarAccountSettings from "../../components/sidebars/MobileSidebarAccountSettings";
import axios from "axios";

class HelpSettings extends Component{

  state = {
    formData:{
      description:'',
      type_content: 'general',
      files:[]
    },
    types:[{value:'general',label:'General'},{value:'verify',label:'Verify Account'}]
  }

  render(){
    return  <React.Fragment>
      <DesktopNavbar />
      <MobileNavbar />
      <BaseLayout>
        <aside className="col col-xl-3 order-xl-1 col-lg-12 order-lg-1 col-12 my-profile ">
          <div className={" row sidebar-menu"}>
            <div className={"col-12 "}>
              <div className={"bg-white profile-account-holder desktop"}>
                <h2>Settings</h2>
                <DesktopSidebarAccountSettings
                  active={"/settings/help"}
                />
              </div>
              <div className={"mobile"}>
                <MobileSidebarAccountSettings
                  active={{ value: "/settings/help", label: "Help" }}
                />
              </div>
            </div>
          </div>
        </aside>
        <main
          className="col col-xl-6 order-xl-2 col-lg-12 order-lg-2 col-md-12 col-sm-12 col-12 mb-3"
          style={{ padding: "0 16px" }}
        >
          <div className="profile-account-holder row">
            <div className={"col-12  bg-white settings-container bg-white"}>
              <h1 className={'mb-3'} >Report a Problem</h1>
              <h2>What seems to be the problem?</h2>
              <form className={'help-form'} onSubmit={event => {
                event.preventDefault();
                this.props.postReport(this.state.formData,0).then(response =>  {
                  this.props.history.push('/');
                })
              }}>
                <div className={'form-group'}>
                  <label>Type problem</label>
                  <select className={'form-control'} value={this.state.formData.type_content} onChange={event => {
                    this.setState({
                      formData:{
                        ...this.state.formData,
                        type_content: event.target.value
                      }
                    })
                  }}>
                    {this.state.types.map((type, index) => {
                      return <option value={type.value}>{type.label}</option>
                    })}
                  </select>
                </div>
                <div className={'form-group'}>
                  <textarea required className={'form-control'}rows={6} placeholder={'Write out the problem you\'re having here...'} onChange={event => {
                    this.setState({
                      formData:{
                        ...this.state.formData,
                        description: event.target.value
                      }
                    })
                  }} value={this.state.formData.description}/>
                </div>
                {
                  this.state.formData.type_content === 'verify' ? <div className={'form-group'}>
                    <input type={'file'} multiple={true} onChange={(event) => {
                      let data = new FormData();

                      data.append("api_token", this.props.user.api_token);
                      for (var i = 0; i < event.target.files.length; i++) {
                        data.append(
                          "fileNames[" + i + "]",
                          event.target.files[i]
                        );
                      }

                      axios
                        .post(
                          this.props.settings.mainUrl +
                          "/api/upload-files-multi",
                          data,
                          {
                            headers: {
                              "content-type": "multipart/form-data",
                            },
                          }
                        )
                        .then((response) => {
                          this.setState(
                            {
                              formData: {
                                ...this.state.formData,
                                files: [...response.data],
                              },
                            },
                            () => {
                              alert("Files loaded success");
                            }
                          );
                        })
                        .catch((error) => {
                          console.log(error);
                          alert("File loaded error");
                        });
                    }}/>
                  </div> : null
                }

                <button className={'btn btn-primary btn-full-width'} type={'submit'}> Report </button>
              </form>
            </div>
          </div>
        </main>
        <aside className="col col-xl-3 order-xl-3 col-lg-12 order-lg-3 col-12">
          <MyProfileUserBlock user={this.props.user} />
        </aside>
      </BaseLayout>
      <Tabbar />
    </React.Fragment>
  }
}
function mapStateToProps(state) {
  return {
    user: state.user.user,
    settings: state.settings,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    postReport: (formData, id_post) => {
      return dispatch(postReport(formData, id_post));
    },
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(HelpSettings) ;