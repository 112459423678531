export const USER_REGISTRATION = "USER_REGISTRATION";
export const USER_REGISTRATION_RESULT = "USER_REGISTRATION_RESULT";

export const USER_LOGIN = "USER_LOGIN";
export const USER_SET = "USER_SET";
export const USER_LOGIN_RESULT = "USER_LOGIN_RESULT";
export const SET_USER_LOGIN = "SET_USER_LOGIN";
export const USER_VERIFY = "USER_VERIFY";
export const USER_VERIFY_RESULT = "USER_VERIFY_RESULT";
export const LOGOUT = "LOGOUT";
export const USER_TOKEN_VIEW = "USER_TOKEN_VIEW";

export const SENT_FORGOT_PASSWORD = "SENT_FORGOT_PASSWORD";

export const CREATE_POST = "CREATE_POST";

export const MESSENGER_USERS = "MESSENGER_USERS";

export const INIT_SETTINGS = "INIT_SETTINGS";
