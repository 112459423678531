import React, { Component } from "react";
import Select from "react-select";
import { withRouter } from "react-router-dom";
import {stylesMobileSidebarSelect} from '../../redux/actions/functionActions';

class MyProfileSidebarMobile extends Component {


  render() {

    let menu = [
      { value: "/my-profile/highlights", label: "Highlights" },
      { value: "/my-profile/feed", label: this.props.isOwner ? "My Feed" : 'Feed' },
      { value: "/my-profile/exhibits", label: this.props.isOwner ? "My Exhibits" : 'Exhibits' },
      { value: "/my-profile/services", label: this.props.isOwner ? "My Services" : 'Services' },
      { value: "/my-profile/about", label: "About" },
    ];
    return (

      <React.Fragment>
        <Select
          options={menu}
          className={"mb-2 mobile"}
          defaultValue={this.props.active}
          styles={stylesMobileSidebarSelect()}
          onChange={(e) => {
            this.props.history.push(e.value + this.props.userLink);
          }}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(MyProfileSidebarMobile);
