import React, { useState } from "react";
import Select from "react-select";
import { withRouter } from "react-router-dom";
import { stylesMobileSidebarSelect } from "../../redux/actions/functionActions";

function TrendingSidebarMobile(props) {
  let { menu, setMenu } = useState([
    { value: "/trending/feed", label: "Feed" },
    { value: "/trending/exhibits", label: "Exhibits" },
    { value: "/trending/services", label: "Services" },
  ]);
  return (
    <Select
      options={menu}
      className={"mb-2 mobile"}
      styles={stylesMobileSidebarSelect()}
      defaultValue={props.active}
      onChange={(e) => {
        props.history.push(e.value);
      }}
    />
  );
}
export default withRouter(TrendingSidebarMobile);
