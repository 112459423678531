import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import {stylesMobileSidebarSelect} from '../../redux/actions/functionActions';
import {connect} from "react-redux";



class MobileNotificationSidebar extends Component {
  state = {
    menu: [
      {
        label: "Likes",
        value: "/notification/likes",
      },
      {
        label: "Awards",
        value: "/notification/awards",
      },
      {
        label: "Comments",
        value: "/notification/comments",
      },
      {
        label: "Follows",
        value: "/notification/follows",
      },
      {
        label: "Received Orders",
        value: "/notification/receive-orders/notifications",
      },
      {
        label: "Orders Created",
        value: "/notification/orders-created/notifications",
      },
      {
        label: "Calls",
        value: "/notification/calls",
      },
      {
        label: "Earnings",
        value: "/notification/earning",
      },
    ],
  };
  render() {

    return (
      <React.Fragment>
        <Select
          options={this.state.menu}
          className={"mb-2 mobile"}
          styles={stylesMobileSidebarSelect()}
          defaultValue={this.props.active}
          onChange={(e) => {
            this.props.history.push(e.value);
          }}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user.user,
    settings: state.settings,
  };
}

function mapDispatchToProps(dispatch) {
  return {

  };
}
export default connect(mapStateToProps, mapDispatchToProps) (withRouter(MobileNotificationSidebar));
