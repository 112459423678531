import { MESSENGER_USERS } from "./actionTypes";
import axios from "axios";

export function messengerUsers(page = 1) {
  return async (dispatch, getState) => {
    let id_user = getState().user.user.id;
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.get(
      `${mainUrl}/api/messenger/users/${id_user}`,
      {
        params: {
          page: page,
          api_token: getState().user.user.api_token,
        },
      }
    );
    return response;
  };
}

export function addMessage(object) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.post(`${mainUrl}/api/messenger/add-message`, {
      ...object,
      api_token: getState().user.user.api_token,
    });
    return response;
  };
}
export function appendMessages(id_chat, page) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.get(
      `${mainUrl}/api/messenger/appends/chat/${id_chat}/page/${page}`,
      {
        params: {
          api_token: getState().user.user.api_token,
        },
      }
    );
    return response;
  };
}

export function verifyMessenger(id_chat, value) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.patch(
      `${mainUrl}/api/messenger/verify-chat/${id_chat}/value/${value}`,
      {
        api_token: getState().user.user.api_token,
      }
    );
    return response;
  };
}

export function searchUsers(search) {
  return async (dispatch, getState) => {
    let id_user = getState().user.user.id;
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.get(
      `${mainUrl}/api/messenger/users/search/${search}/user/${id_user}`,
      {
        params: {
          api_token: getState().user.user.api_token,
        },
      }
    );
    return response;
  };
}

export function getUser(id_user1, id_user2) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.get(
      `${mainUrl}/api/messenger/user/${id_user1}/${id_user2}`,
      {
        params: {
          api_token: getState().user.user.api_token,
        },
      }
    );
    return response;
  };
}

export function createRequestVideochat(formData,user) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let id_user = getState().user.user.id;
    let response = await axios.post(mainUrl + '/api/room/videochat',{
      ...formData,
      user1: user.id,
      user2:id_user
    });
    return  response;
  }
}

export function simplePay(videochat) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let user = getState().user.user;
    let response = await axios.post(`${mainUrl}/api/stripe/payment-call-repeat`,{
      id_customer:user.customer_id,
      id_request:videochat.id,
      payment_method:user.payment_method_id,
      api_token: getState().user.user.api_token,
    });
    return response;
  }
}