import {
  USER_LOGIN,
  USER_REGISTRATION,
  USER_VERIFY,
  USER_VERIFY_RESULT,
  USER_LOGIN_RESULT,
  SET_USER_LOGIN,
  LOGOUT,
  USER_TOKEN_VIEW,
} from "./../actions/actionTypes";

const initializeState = {
  login: {
    register: {
      status: false,
      message: "",
      type: "success",
    },
    verify: {
      status: false,
      message: "",
      type: "success",
    },
    login: {
      status: false,
      message: "",
      type: "success",
    },
  },
  user: {},
};

export default function userReducer(state = initializeState, action) {
  switch (action.type) {
    case USER_LOGIN:
      return { ...state };
    case USER_REGISTRATION:
      return { ...state };
    case USER_VERIFY:
      return { ...state };
    case USER_LOGIN_RESULT:
      return { ...state, login: { ...action.data } };
    case SET_USER_LOGIN:
      console.log(action);
      return { ...state, user: { ...action.data } };
    case LOGOUT:
      return {
        ...state,
        user: {},
      };
    case USER_VERIFY_RESULT:
      return {
        ...state,
        verify: { ...action.data },
      };
    case USER_TOKEN_VIEW:
      return {
        ...state,
        user: { ...action.data },
      };
    default:
      return state;
  }
}
