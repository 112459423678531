import React,{Component} from 'react';
import ReactStars from "react-rating-stars-component";
import FontAwesome from "react-fontawesome";
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import { } from "../../../redux/actions/twilioActions";

class RequestItem extends Component{
  state = {
    rating:0
  }

  getTypeOfCall = (videochat) => {
    let text = '';
    switch (videochat.type) {
      case 'audio':text = 'Audio Chat'; break;
      case 'video':text = 'Video Chat'; break;
    }
    return text;
  };

  getStartDateFormate = (videochat) => {
    var mydate = new Date(videochat.plan_start);
    var month = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"][mydate.getMonth()];
    var hours = (mydate.getHours())%24;
    var mid='am';
    if(hours==0){ //At 00 hours we need to show 12 am
      hours=12;
    }
    else if(hours>12)
    {
      hours=hours%12;
      mid='pm';
    }
    return  `${hours}:${mydate.getMinutes()} ${mid} ` + month + ' ' + mydate.getDate() + ', ' + mydate.getFullYear();
  }

  getTextLong = (videochat) => {
    let long = '0 min';
    switch (videochat.plan_duration) {
      case 900: long = '15 Minutes';break;
      case 1800: long = '30 Minutes';break;
      case 2700: long = '45 Minutes';break;
      case 3600: long = '1 Hour';break;
      case 7200: long = '2 Hours';break;
      case 10800: long = '3 Hours';break;
    }
    return long;
  };

  getTextStatus = (videochat) => {
    let status = '';
    switch (videochat.status) {
      case -1:status = 'Finished';break;
      case 0:status = 'Request';break;
      case 1:status = 'Active';break;
      case 2:status = 'Paid';break;
    }
    return status;
  }

  render() {
    return <React.Fragment>
      <div className={"order-item"}>
        <div className={"row justify-content-between order-row"}>
          <div className={"col-auto"}>
            <div className={"order-user"}>
               <span>
                  <img
                         className={"notification-img"}
                              src={
                                `${this.props.settings.mainUrl}/storage/${this.props.videochat.user2.avatar}`
                              }
                            />
                          </span>

              <span className={"d-inline-block text-block"}>
                            <div className={"name-badge"}>
                              <div className={"name"}> {this.props.videochat.user2.full_name} </div>
                              <div className={"badge"}>
                                {
                                  this.props.videochat.user2.badge !== ''? <img src={require(`../../../assets/img/${this.props.videochat.user2.badge}.png`)} width={15} />   : null
                                }
                              </div>
                            </div>
                            <div className={"status"}>
                              Placed an order
                            </div>
                          </span>
            </div>
          </div>
          <div className={"col-auto text-right"}></div>
        </div>

        <div className={"row description-block row-content"}>
          <div className={"col-12"}>
            <div className={"row-title"}>Type of Call:</div>
            <div className={"description"}>{this.getTypeOfCall(this.props.videochat) }</div>
          </div>
        </div>
        <div className={"row description-block row-content"}>
          <div className={"col-12"}>
            <div className={"row-title"}>When:</div>
            <div className={"description"}>
              {this.getStartDateFormate(this.props.videochat) }
            </div>
          </div>
        </div>
        <div className={"row description-block row-content"}>
          <div className={"col-12"}>
            <div className={"row-title"}>How Long:</div>
            <div className={"description"}>{this.getTextLong(this.props.videochat)}</div>
          </div>
        </div>
        <div className={"row description-block row-content"}>
          <div className={"col-12"}>
            <div className={"row-title"}>Status:</div>
            <div className={"description"}>{this.getTextStatus(this.props.videochat) }</div>
          </div>
        </div>
        <div className={"row description-block row-content last"}>
          <div className={"col-12"}>
            <div className={"row-title"}>Call Details:</div>
            <div className={"description"}>
              {this.props.videochat.description}
            </div>
          </div>
        </div>
        {/*<div className={"row"}>*/}
        {/*  <div className={"col-12 text-center"}>*/}
        {/*    <ReactStars*/}
        {/*      count={5}*/}
        {/*      onChange={this.ratingChanged}*/}
        {/*      size={35}*/}
        {/*      activeColor="#ffd700"*/}
        {/*      value={this.state.rating}*/}
        {/*      emptyIcon={<FontAwesome className="star" />}*/}
        {/*      halfIcon={<FontAwesome className="star-half-alt" />}*/}
        {/*      fullIcon={<FontAwesome className="star" />}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className={"row justify-content-between"}>
          <div className={"col-auto"}>
            {/*<button*/}
            {/*  className={"btn btn-danger"}*/}
            {/*  style={{ paddingLeft: 20, paddingRight: 20 }}*/}
            {/*>*/}
            {/*  Refund*/}
            {/*</button>*/}
          </div>
          <div className={"col-auto"}>
            {/*<button*/}
            {/*  className={"btn btn-primary"}*/}
            {/*  style={{ paddingLeft: 20, paddingRight: 20 }}*/}
            {/*>*/}
            {/*  Send Rating*/}
            {/*</button>*/}
          </div>
          <div className={"col-auto"}>
            <button
              className={"btn btn-dark"}
              type={'button'}
              onClick={() => {
                this.props.history.push(`/messenger/${this.props.videochat.user2.name}`);
              }}
              style={{ paddingLeft: 20, paddingRight: 20 }}
            >
              Message
            </button>
          </div>
        </div>


      </div>
    </React.Fragment>
  }
}
function mapStateToProps(state) {
  return {
    user: state.user.user,
    settings: state.settings,
  };
}
function mapDispatchToProps(dispatch) {
  return {

  };
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RequestItem)) ;