import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import iconHome from "../../assets/img/icon-home.svg" 
import iconCommunity from "../../assets/img/icon-community.svg" 
import iconPlus from "../../assets/img/icon-plus.svg" 
import iconHype from "../../assets/img/icon-hype.svg" 

class Tabbar extends Component {
  render() {
    return (
      <React.Fragment>
        <div className={"row tabbar-list text-center mobile"}>
          <div className={"col tabbar-item tabbar-col pl-0"}>
            <NavLink exact className="nav-link menu-center"  to={"/"}>
              <img src={iconHome} alt="Home"/>
              <p className="nav-link-sub-title nav-link-sub-title-home" > Home</p>
            </NavLink>
          </div>
          <div className={"col tabbar-item tabbar-col"}>
            <NavLink  className="nav-link menu-center"  to={"/communities"}>
              <img src={iconCommunity } alt="Community"/>
              <p className="nav-link-sub-title nav-link-sub-title-community">Community</p>
            </NavLink>
          </div>
          <div className={"col tabbar-item tabbar-col"}>
            <NavLink  className="nav-link menu-center plus-border"  to={"/create-post"}> 
              <div className="border-icon-plus-div">
                <img src={iconPlus } alt="Plus" className="border-icon-plus"/>
              </div>             
            </NavLink>
          </div>
          <div className={"col tabbar-item heart-icon tabbar-col"}>
            <NavLink className="nav-link menu-center" to={"/notification/likes"}>
              <img src={iconHype} alt="Hype"/>
              <p className="nav-link-sub-title nav-link-sub-title-hype">Hype</p>
            </NavLink>
          </div>
          <div className={"col tabbar-item tabbar-col pr-0"}>
            <NavLink
              href={""}
              className={"nav-link menu-center"}
              to={"/my-profile/highlights"}
            >
              <img
                className="rounded-circle rounded-circle-img "
                src={
                  this.props.settings.mainUrl +
                  "/storage/" +
                  this.props.user.avatar
                }
                width={19.17}
                height={19.17}
              />
              <p className="nav-link-sub-title nav-link-sub-title-profile">Profile</p>
            </NavLink>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    user: state.user.user,
    settings: state.settings,
  };
}
export default connect(mapStateToProps)(Tabbar);
