import React, { useState, useEffect } from "react";
import Video from "twilio-video";
import { createLocalVideoTrack, connect as twilioConnect } from "twilio-video";
import Participant from "./Participant";
import ToolbarButton from "../Messenger/components/ToolbarButton";
import { LocalParticipant } from "./LocalParticipant";
import CallTo from "./CallTo";

const Room = ({ ...props }) => {
    console.log(props.roomName)
  useEffect(() => {
    const participantConnected = (participant) => {
      console.log(`Connected ${participant.identity}`);
      props.setParticipants((prevParticipants) => [
        ...prevParticipants,
        participant,
      ]);
    };

    const participantDisconnected = (participant) => {
      // console.log(`Disconnected ${participant.identity}`);
      props.setParticipants((prevParticipants) => {
        return prevParticipants.filter((p) => p !== participant);
      });

      props.handleLogout();
    };

    twilioConnect(props.token, { name: props.roomName }).then(
      (room) => {
          console.log(`Successfully joined a Room: ${room}`);

        props.setRoom(room);

        room.on("participantConnected", participantConnected);
        room.on("participantDisconnected", participantDisconnected);
        room.participants.forEach(participantConnected);
      },
      (error) => {
         console.error(`Unable to connect to Room: ${error.message}`);
      }
    );

    return () => {
      props.setRoom((currentRoom) => {
        if (currentRoom && currentRoom.localParticipant.state === "connected") {
          currentRoom.localParticipant.tracks.forEach(function (
            trackPublication
          ) {
            trackPublication.track.stop();
          });
          currentRoom.disconnect();
          return null;
        } else {
          return currentRoom;
        }
      });
    };
  }, [props.roomName, props.token]);

  const remoteParticipants = props.participants.map((participant) => (
    <React.Fragment>
      <Participant
        key={participant.sid}
         participant={participant}
        handleLogout={props.handleLogout}
        localParticipant={props.room.localParticipant}
        activeConversation={props.activeConversation}
      />
    </React.Fragment>
  ));


  return (
    <div className="room">
      {props.participants.length > 0 ? (
        <div className={"room-overlay"} onClick={props.handleLogout}></div>
      ) : null}

      {props.participants.length > 0 && props.token !== null && props.activeConversation.videochat.status === 1 ? (
          <div className="remote-participants">{remoteParticipants}</div>
      ) : (null)
      }
      { props.participants.length === 0  && props.activeConversation.videochat.status === 1 && props.activeConversation.videochat.id_user1 === props.user.id ?
        <CallTo
          handleLogout={props.handleLogout}
          activeConversation={props.activeConversation}
        />
        :
          null}
    </div>
  );
};

export default Room;
