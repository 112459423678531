import {} from "../actions/actionTypes";
import { INIT_SETTINGS } from "../actions/actionTypes";

const initializeState = {
  mainUrl: "https://aws.witit.com",
  stripe_pk: 'pk_test_Du0fzY5XkR7m8qkwrWanhqpC00TMxFfEet',
  twilio: {
    ACCOUNT: "SIDAC14f96088a790585c3cee2fd35ddc6533",
    AUTH: "TOKEN717b02714ccedb3a1ab8b77d7918c67c",
  },
};

export default function settingsReducer(state = initializeState, action) {
  switch (action.type) {
    default:
      return { ...state };
  }
}
