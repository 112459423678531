import React, { useState, useCallback } from "react";
import Lobby from "./Lobby";
import Room from "./Room";
import axios from "axios";
import ToolbarButton from "../Messenger/components/ToolbarButton";
import CallFrom from "./CallFrom";
import CallTo from "./CallTo";

const VideoChat = (props) => {
  let activeConversation = props.activeConversation;
  const [username, setUsername] = useState("");
  const [roomName, setRoomName] = useState(
    `room_${activeConversation.messenger.id_user1}_${activeConversation.messenger.id_user2}`
  );
  const [call, setCall] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [room, setRoom] = useState(null);
  // const [token, setToken] = useState(null);

  const handleUsernameChange = useCallback((event) => {
    setUsername(event.target.value);
  }, []);

  const handleRoomNameChange = useCallback((event) => {
    setRoomName(event.target.value);
  }, []);

  const handleSubmit = (e) => {
    props.startVideochat(e, roomName);
    setCall(true);
  };
  setTimeout(() => {}, 20000);
  const handleLogout = () => {
    setParticipants([]);
    setCall(false);
    props.downVideochat();
    //console.log(props.activeConversation.videochat);
  };
  //     useCallback((event) => {

  let handleJoin = (e) => {
    e.preventDefault();
    props.joinVideochat(e, roomName);
    // props.joinVideochat(props.activeConversation.messenger).then((response) => {
    //   props.callVideoHandler(e, roomName, props.user).then((response) => {
    //     props.setToken(response.data.accessToken);
    //   });
    // });
  };

  let secondsToCall = () => {
      return Math.round((new Date().getTime()/1000) - (new Date(props.activeConversation.videochat.plan_start).getTime()/1000));
  } ;

  let render;
   // console.log(Math.round((new Date().getTime()/1000) - (new Date(props.activeConversation.videochat.plan_start).getTime()/1000)),props.activeConversation.videochat.plan_start)
  if (props.token ) {
    render = (
      <React.Fragment>
          {props.activeConversation.videochat.status === 1 ? <Room
              roomName={roomName}
              token={props.token}
              handleLogout={handleLogout}
              activeConversation={props.activeConversation}
              participants={participants}
              setParticipants={setParticipants}
              room={room}
              setRoom={setRoom}
              user={props.user}
          /> : null}

      </React.Fragment>
    );
  }
  let callStart = secondsToCall();
  if (   !props.token  ) {
    render = (
      <React.Fragment>
        { props.activeConversation.videochat !== null && props.activeConversation.videochat.id_user1 === props.user.id && props.activeConversation.videochat.status === 2 && (callStart >= 0 && callStart < 900)
            ? <a href={"#"} onClick={handleSubmit}>
          <ToolbarButton key="phone" icon="ion-ios-call" />
        </a> : null}

      </React.Fragment>
    );
  }
  if (
    !props.token &&
    props.activeConversation.videochat !== null &&
      props.activeConversation.videochat.id_user2 === props.user.id &&
      props.activeConversation.videochat.status === 1 &&
    participants.length === 0 &&
    call === false
  ) {

    render = (
      <React.Fragment>
        <CallFrom
          handleJoin={handleJoin}
          handleLogout={handleLogout}
          activeConversation={props.activeConversation}
        />
      </React.Fragment>
    );
  }

  return render;
};

export default VideoChat;
