import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import "./ConversationListItem.css";

function ConversationListItem(props) {
  useEffect(() => {
    if (props.type === "search") {
    }
  });

  const { photo, name, text, id_name } = props.data;

  return (
    <div
      className={[
        "conversation-list-item",
        props.data.id === props.activeConversation.id ? "active" : "",
      ].join(" ")}
      onClick={() => {

        props.history.push(`/messenger/${id_name}`);
        // props.changeActiveConversation(props.data);
      }}
    >
      {props.settings !== undefined ? (
        <img
          className="conversation-photo"
          src={props.settings.mainUrl + "/storage/" + photo}
          alt="conversation"
        />
      ) : null}

      <div className="conversation-info">
        <h1 className="conversation-title">{name}</h1>
        <p className="conversation-snippet">{text}</p>
      </div>
    </div>
  );
}
function mapStateToProps(state) {
  return {
    settings: state.settings,
  };
}

export default connect(mapStateToProps)(withRouter(ConversationListItem));
