import React, { Component } from "react";
import './gender.scss'

export default class Gender extends Component{
    constructor(props) {
        super(props);
        this.state = {
            genders: [{name: 'Agende',  flag: false, number: 0},
            {name: 'Female', flag: false, number: 1},
            {name: 'Fluid', flag: false, number: 2},
            {name: 'Male', flag: false, number: 3},
            {name: 'Neutral', flag: false, number: 4},
            {name: 'Non-Binary', flag: false, number: 5},
            {name: 'Transgender', flag: false, number: 6},
            {name: 'Two Spirit', flag: false, number: 7},
            {name: 'Other', flag: false, number: 8},
            {name: 'Prefer not to say', flag: false, number: 9}],
            gender: ''
        };
    }
    handleRadioChange(number) {
        this.props.onChangeGender(number); 
        this.setState(
            {gender: number}
        )
        const updatedGenders = [...this.state.genders];
        const currentGendersIndex = updatedGenders.findIndex(
          (item) => item.number === number
        );
        this.state.genders.map(gender => gender.flag = false);
        updatedGenders[currentGendersIndex] = {
          ...updatedGenders[currentGendersIndex],
          flag: !updatedGenders[currentGendersIndex].flag
        };
    
        this.setState({
            genders: updatedGenders
        });
      };
    render(){
        return(
        <div className="gender-container">
            <h3 className="gender-title">What gender do you identify as?</h3>
            <form className="gender-form">
            {this.state.genders.map((gender) => (
                <label className="gender-btn"
                style={gender.flag ? {background : '#1448FF', border: 'none'} : {background : 'transparent'}}>
                            <input 
                                name="lang"
                                type="radio"
                                value={gender.number}
                                key={gender.name}
                                onClick={() => this.handleRadioChange(gender.number)}
                            />
                            <span>{gender.name}</span>
                </label>))}
            </form>
        </div>
        );
    }
}