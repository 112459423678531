import React, { Component } from "react";
import { Navbar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faHeart,
  faPaperPlane,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import { withRouter, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "./../../redux/actions/userActions";
import iconHome from "../../assets/img/icon-home.svg" 
import iconCommunity from "../../assets/img/icon-community.svg" 
import iconHype from "../../assets/img/icon-hype.svg" 

class DesktopNavbar extends Component {

  state = {
    search:''
  };
  render() {
    return (
      <React.Fragment>
        <Navbar
          bg={"white"}
          className={" osahan-nav-top p-0 desktop"}
          variant="dark"
        >
          <div className={"container-fluid"}>
            <div
              className="row no-gutters align-items-center"
              style={{ width: "100%" }}
            >
              <div className="col pl-4">
                <NavLink
                  className="navbar-brand mr-4"
                  to={"/"}
                  style={{ margin: "7px 0" }}
                >
                  <div className="around-logo-navbar">
                    <img src={require('../../assets/img/logo-small.svg')} />
                  </div>
                  
                </NavLink>
                <form className="d-none d-sm-inline-block form-inline mr-auto my-2 my-md-0 mw-100 navbar-search">
                  <div className="input-group">
                    <div className="input-group-append">
                      <button className="btn" type="button" onClick={() => {
                        this.props.history.push(`/search-page/${this.state.search}`)
                      }}>
                        <FontAwesomeIcon icon={faSearch} />
                      </button>
                    </div>
                    <input
                      type="text"
                      className="form-control shadow-none border-0"
                      placeholder="Search"
                      aria-label="Search"
                      aria-describedby="basic-addon2"
                      value={this.state.search}
                      onChange={(e) => this.setState({
                        search:e.target.value
                      })}
                    />
                  </div>
                </form>
              </div>

              <div className="col">
                <ul className="navbar-nav-menu navbar-nav ml-auto d-flex align-items-center justify-content-center"
>
                  {/* Nav Item - Search Dropdown (Visible Only XS) */}
                  <li className="nav-item">
                    <NavLink className="nav-link" to={"/create-post"}>
                      <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        
                        <g filter="url(#filter0_d_1697_4746)">
                        <path d="M21.6223 15.3748H16.3746V20.6224H14.6254V15.3748H9.37781V13.6255H14.6254V8.37793H16.3746V13.6255H21.6223V15.3748Z" fill="white"/>
                        </g>
                        
                        <g filter="url(#filter1_d_1697_4746)">
                        <rect x="3" y="2" width="25" height="25" rx="8" stroke="#1448FF" stroke-width="4" shape-rendering="crispEdges"/>
                        <rect x="3" y="2" width="25" height="25" rx="8" stroke="url(#paint0_linear_1697_4746)" stroke-width="4" shape-rendering="crispEdges"/>
                        </g>
                        <defs>
                        <filter id="filter0_d_1697_4746" x="9.17781" y="8.37793" width="12.6444" height="12.7446" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="0.3"/>
                        <feGaussianBlur stdDeviation="0.1"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.75 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1697_4746"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1697_4746" result="shape"/>
                        </filter>
                        <filter id="filter1_d_1697_4746" x="0.8" y="0" width="29.4" height="29.5" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="0.3"/>
                        <feGaussianBlur stdDeviation="0.1"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.75 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1697_4746"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1697_4746" result="shape"/>
                        </filter>
                        <linearGradient id="paint0_linear_1697_4746" x1="15.5" y1="0" x2="15.5" y2="29" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#50D5FF"/>
                        <stop offset="1" stop-color="#50D5FF" stop-opacity="0"/>
                        </linearGradient>
                        <clipPath id="clip0_1697_4746">
                        <rect width="21.6869" height="21.5388" fill="white" transform="translate(4.95007 4.74023)"/>
                        </clipPath>
                        </defs>
                      </svg>
                      Create
                      {/* <FontAwesomeIcon icon={faPlusCircle} className={"mr-2"} />{" "} */}
                      {/* Create a Post */}
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to={"/"}>
                      <img src={iconHome} alt="Home"/>
                      {/* <FontAwesomeIcon icon={faStar} className={"mr-2"} /> */}
                       Home
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className={"nav-link"} to={"/trending-feeds"}>
                      <img src={iconHype} alt="Hype"/>
                      {/* <FontAwesomeIcon icon={faFire} className={"mr-2"} />{" "} */}
                      Hype
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/communities">
                      <img src={iconCommunity } alt="Community"/>
                      {/* <FontAwesomeIcon icon={faUser} className={"mr-2"} />{" "} */}
                      Community
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div className="col pr-4">
                <ul className="navbar-nav ml-auto d-flex align-items-center justify-content-end">
                  {/* Nav Item - Search Dropdown (Visible Only XS) */}


                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href=""
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push("/messenger");
                      }}
                    >
                      <FontAwesomeIcon icon={faPaperPlane} />
                    </a>
                  </li>

                  <li className="nav-item dropdown no-arrow mx-1 osahan-list-dropdown">
                    <NavLink className="nav-link " to={"/notification/likes"}>
                      <FontAwesomeIcon icon={faHeart} />
                    </NavLink>
                  </li>
                  {/* Nav Item - User Information */}
                  <li className="nav-item dropdown no-arrow ml-1 osahan-profile-dropdown">
                    <a
                      className="nav-link dropdown-toggle pr-0 mr-3"
                      href=""
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(
                          `/my-profile/highlights/${this.props.user.name}`
                        );
                      }}
                    >
                      <img
                        className="img-profile rounded-circle"
                        src={
                          this.props.settings.mainUrl +
                          "/storage/" +
                          this.props.user.avatar
                        }
                      />
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="" style={{ paddingRight: 0 }}  onClick={(e) => {
                      e.preventDefault();
                      this.props.history.push(
                        `/settings/general`
                      );
                    }}>
                      <FontAwesomeIcon icon={faBars} style={{ fontSize: 24 }} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Navbar>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user.user,
    settings: state.settings,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logout: () => {
      return dispatch(logout());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DesktopNavbar));
