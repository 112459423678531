import React, { Component } from "react";
import DesktopNavbar from "../../components/headers/DesktopNavbar";
import { connect } from "react-redux";
import MobileNavbar from "../../components/headers/MobileNavbar";
import BaseLayout from "../../layouts/BaseLayout";
import {
  fetchMyFeed,
  like,
  unlike,
  likeStar,
  postDelete,
} from "./../../redux/actions/postActions";
import MyProfileSidebar from "../../components/sidebars/MyProfileSidebar";
import MyProfileUserBlock from "../../components/sidebars/MyProfileUserBlock";
import Tabbar from "../../components/headers/Tabbar";
import MyProfileSidebarMobile from "../../components/sidebars/MyProfileSidebarMobile";
import {
  getUserById,
  getUserByName,
} from "../../redux/actions/userActions";
import FeedItem from "../../components/FeedItem";
import NotFoundBlock from "../NotFound/NotFoundBlock";
class Feed extends Component {
  state = {
    feed: [],
    user: {},
  };

  componentDidMount() {
    if (!this.props.match.params.user) {
      this.props.fetchMyFeed(this.props.user.id).then((response) => {
        this.setState({
          feed: [...response.data],
          user: { ...this.props.user },
        }, () => {
          this.checkDeactivated();
        });
      });
    } else {
      this.props
        .getUserByName(this.props.match.params.user)
        .then((response) => {
          this.setState(
            {
              user: { ...response.data },
            },
            () => {
              this.checkDeactivated();
              this.props.fetchMyFeed(response.data.id).then((response) => {
                this.setState({
                  feed: [...response.data],
                });
              });
            }
          );
        });
    }

    //this.props.getUserById();
  }
  deleteFromList = (index) => {
    let feeds = [...this.state.feed];
    feeds.splice(index, 1);
    this.setState({
      feed: [...feeds],
    });
  };

  likeStar = (feed, index) => {
    this.props.likeStar(feed.id, this.props.user.id).then((response) => {
      let feeds = [...this.state.feed];
      feeds[index] = { ...response.data };

      this.setState({ feed: [...feeds] });
    });
  };

  like = (feed, index) => {
    this.props.like(feed.id, this.props.user.id).then((response) => {
      let feeds = [...this.state.feed];
      feeds[index] = { ...response.data };

      this.setState({ feed: [...feeds] });
    });
  };

  checkDeactivated = () => {
    if(this.state.user.status === -1){
      this.props.history.push('/my-profile/about/' + this.state.user.name);
    }
  };


  render() {
    return (
      <React.Fragment>
        <DesktopNavbar />
        <MobileNavbar >{this.state.user.name !== this.props.user.name ? `@${this.state.user.name}`  : ' '} </MobileNavbar>

        <BaseLayout>
          <aside className="col col-xl-3 order-xl-1 col-lg-12 order-lg-1 col-12 my-profile order-1 ">
            <div className={" row sidebar-menu"}>
              <div className={"col-12 "}>
                <div className={"bg-white profile-account-holder desktop"}>
                  <h2>@{this.state.user.name}</h2>
                  <MyProfileSidebar
                    isOwner={this.state.user.name === this.props.user.name}
                    active={"feed"}
                    userLink={
                      Object.keys(this.state.user).length > 0
                        ? `/${this.state.user.name}`
                        : ""
                    }
                  />
                </div>
                <div className={"mobile"}>
                  <MyProfileSidebarMobile
                    active={{
                      value: "/my-profile/feed",
                      label: this.state.user.name === this.props.user.name ? "My Feed" : 'Feed',
                    }}
                    isOwner={this.state.user.name === this.props.user.name}
                    userLink={
                      Object.keys(this.state.user).length > 0
                        ? `/${this.state.user.name}`
                        : ""
                    }
                  />
                </div>
              </div>
            </div>
          </aside>
          <main
            className="col col-xl-6 order-xl-2 col-lg-12 order-lg-2 col-md-12 col-sm-12 col-12 mb-3 post-list order-2"
            style={{ padding: "0 8px" }}
          >

            {this.state.feed.length === 0 ? <NotFoundBlock/>  : null}
            {this.state.feed.map((feed, index) => {
              return (
                <FeedItem
                  key={`feed-${index}`}
                  feed={feed}
                  index={index}
                  deleteFromList={this.deleteFromList}
                  likeStar={this.likeStar}
                  like={this.like}
                  feedLink={`/my-profile-feed/${feed.id}`}
                />
              );
            })}
          </main>
          <aside className="col col-xl-3 order-xl-3 col-lg-12 order-lg-3 col-12 order-0">
            <MyProfileUserBlock user={this.state.user} />
          </aside>
        </BaseLayout>
        <Tabbar />
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    user: state.user.user,
    settings: state.settings,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    like: (id_post, id_user) => {
      return dispatch(like(id_post, id_user));
    },
    likeStar: (id_post, id_user) => {
      return dispatch(likeStar(id_post, id_user));
    },
    unlike: (id_post, id_user) => {
      return dispatch(unlike(id_post, id_user));
    },
    fetchMyFeed: (id_user) => {
      return dispatch(fetchMyFeed(id_user));
    },
    getUserById: (id_user) => {
      return dispatch(getUserById(id_user));
    },
    getUserByName: (name) => {
      return dispatch(getUserByName(name));
    },
    postDelete: (id) => {
      return dispatch(postDelete(id));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Feed);
