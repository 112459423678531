import React, { Component } from "react";
import DesktopNavbar from "../../components/headers/DesktopNavbar";
import MobileNavbar from "../../components/headers/MobileNavbar";
import BaseLayout from "../../layouts/BaseLayout";
import Select from 'react-select';
import MyProfileUserBlock from "../../components/sidebars/MyProfileUserBlock";
import Tabbar from "../../components/headers/Tabbar";
import { connect } from "react-redux";
import AvatarUploader from "react-avatar-uploader";
import Switch from "react-switch";
import { skills } from "./../../redux/actions/userActions";
import {
  updateMainData,
  userNewData,
  userViewToken,
  logout
} from "./../../redux/actions/userActions";
import DesktopSidebarAccountSettings from "../../components/sidebars/DesktopSidebarAccountSettings";
import MobileSidebarAccountSettings from "../../components/sidebars/MobileSidebarAccountSettings";
import { skillsSelectStyles } from "../../redux/actions/functionActions";

class MainAccountSettings extends Component {
  state = {
    formData: {
      firstName: "",
      lastName: "",
      bio: "",
      city: "",
      country: "",
      image: "",
      title:'',
      skills:[],
      is_adult_show:false,
      is_adult_make:false
    },
    categories:[],
    expertise: [
      { value:'', label:"Expertise"},
      { value:0, label:"Novice"},
      { value:1, label:"Intermediate"},
      { value:2, label:"Expert"}
    ],
  };

  componentDidMount() {
    let skills = this.props.user.skills !== null ? JSON.parse(this.props.user.skills) :[
      { category: "", expertise: "" },
      { category: "", expertise: "" },
      { category: "", expertise: "" },
    ];

    this.props.skills().then(response => {
      this.setState({
        categories:[...response.data]
      })
    }) ;
    this.setState({
      formData: {
        firstName: this.props.user.first_name,
        lastName: this.props.user.last_name,
        bio: this.props.user.description,
        city: this.props.user.city,
        country: this.props.user.country,
        image: this.props.user.avatar,
        title:this.props.user.title,
        skills: [...skills],
        is_adult_make: !!this.props.user.is_adult_make,
        is_adult_show: !!this.props.user.is_adult_show
      },
    });
  }

  onChangeSkillsHandler = (value, index) => {


    let list = [...this.state.formData.skills];
    list[index].category = value;

    this.setState({
      formData: {
        ...this.state.formData,
        skills: [...list],
      },
    }, () => {
      console.log((this.state.categories,this.state.formData.skills))
    });
  };
  onChangeExpertiseHandler = (value, index) => {

    let list = [...this.state.formData.skills];
    list[index].expertise = value;

    this.setState({
      formData: {
        ...this.state.formData,
        skills: [...list],
      },
    });
  };

  getCategoryValue = (value) => {
    let result = {value:'',label:'Category'};
    let categories = [...this.state.categories];
    let category = [];
    for(var i = 0; i < categories.length;i++){
      if(categories[i].options.length > 0){
        category = (categories[i].options.filter(optionItem => optionItem.value === value));
        if(category.length > 0){
          result = category[0];
          break;
        }
      }
    }
    return result;
  }

  render() {

    return (
      <React.Fragment>
        <DesktopNavbar />
        <MobileNavbar />
        <BaseLayout>
          <aside className="col col-xl-3 order-xl-1 col-lg-12 order-lg-1 col-12 my-profile ">
            <div className={" row sidebar-menu"}>
              <div className={"col-12 "}>
                <div className={"bg-white profile-account-holder desktop"}>
                  <h2>Settings</h2>
                  <DesktopSidebarAccountSettings
                    active={"/settings/general"}
                  />
                </div>
                <div className={"mobile"}>
                  <MobileSidebarAccountSettings
                    active={{ value: "/settings/general", label: "General" }}
                  />
                </div>
              </div>
            </div>
          </aside>
          <main
            className="col col-xl-6 order-xl-2 col-lg-12 order-lg-2 col-md-12 col-sm-12 col-12 mb-3"
            style={{ padding: "0 16px" }}
          >
            <div className="profile-account-holder settings-container row settings-container bg-white">
              <div className={"col-12  "}>
                <h1 >General</h1>
                <div className={"row"}>
                  <div className={"col-lg-12 avatar-uploader-block mb-3"}>
                    <label>Avatar</label>
                    <AvatarUploader
                      uploadURL={
                        this.props.settings.mainUrl + "/api/avatarUpload"
                      }
                      style={{ margin: "o auto" }}
                      name={"image"}
                      fileType={"image/jpeg, image/png, image/gif"}
                      onFinished={(err, res) => {
                        this.setState({
                          formData: {
                            ...this.state.formData,
                            image: res.data,
                          },
                        });
                      }}
                      defaultImg={
                        this.props.settings.mainUrl +
                        "/storage/" +
                        this.state.formData.image
                      }
                    />
                  </div>
                  <div className={"col-lg-12 mb-3"}>
                    <label>First Name</label>
                    <input
                      type={"text"}
                      placeholder={"First Name"}
                      className={"form-control bordered"}
                      value={this.state.formData.firstName}
                      onChange={(e) => {
                        this.setState({
                          formData: {
                            ...this.state.formData,
                            firstName: e.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                  <div className={"col-lg-12 mb-3"}>
                    <label>Last Name</label>
                    <input
                      type={"text"}
                      placeholder={"Last Name"}
                      className={"form-control bordered"}
                      value={this.state.formData.lastName}
                      onChange={(e) => {
                        this.setState({
                          formData: {
                            ...this.state.formData,
                            lastName: e.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                  <div className={"col-lg-12 mb-3"}>
                    <label>Country</label>
                    <input
                      type={"text"}
                      placeholder={"Country"}
                      className={"form-control bordered"}
                      value={this.state.formData.country}
                      onChange={(e) => {
                        this.setState({
                          formData: {
                            ...this.state.formData,
                            country: e.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                  <div className={"col-lg-12 mb-3"}>
                    <label>City</label>
                    <input
                      type={"text"}
                      placeholder={"City"}
                      className={"form-control bordered"}
                      value={this.state.formData.city}
                      onChange={(e) => {
                        this.setState({
                          formData: {
                            ...this.state.formData,
                            city: e.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                  <div className={"col-lg-12 mb-3"}>
                    <label>Title</label>
                    <input
                      type={"text"}
                      placeholder={"Title"}
                      className={"form-control bordered"}
                      maxLength={20}
                      value={this.state.formData.title}
                      onChange={(e) => {
                        this.setState({
                          formData: {
                            ...this.state.formData,
                            title: e.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                  <div className={"col-lg-12 mb-3"}>
                    <label>Skills</label>
                    {this.state.formData.skills.map((skill, index) => {
                      let category = this.getCategoryValue(this.state.formData.skills[index].category) ;
                      let expertise = this.state.expertise.filter(expertiseItem => expertiseItem.value === parseInt(this.state.formData.skills[index].expertise));

                      return (
                        <div className={'row'}>
                          <div className={"col-6 mb-2"}>
                            <Select styles={skillsSelectStyles()} options={[{value:'',label:'Category'},...this.state.categories]} value={category}  onChange={e => {
                              this.onChangeSkillsHandler(e.value, index);
                            }}/>

                          </div>
                          <div className={"col-6 mb-2"}>
                            <Select options={this.state.expertise} value={expertise} onChange={e => {
                              this.onChangeExpertiseHandler(e.value, index);
                            }}/>

                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className={"col-lg-12 mb-3"}>
                    <label>Bio</label>
                    <textarea
                      placeholder={"Bio"}
                      className={"form-control bordered"}
                      value={this.state.formData.bio}
                      onChange={(e) => {
                        this.setState({
                          formData: {
                            ...this.state.formData,
                            bio: e.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                  <div className={"col-lg-12 mb-3"}>
                    <label className={'d-block'}>Post NSFW Content</label>
                    <Switch
                      checked={this.state.formData.is_adult_make}
                      height={20}
                      width={48}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      onColor="#006afe"
                      onChange={e => {
                        this.setState({
                          formData:{
                            ...this.state.formData,
                            is_adult_make:e
                          }
                        })
                      }}
                    />
                  </div>
                  <div className={"col-lg-12 mb-3"}>
                    <label className={'d-block'}>Adult Content</label>
                    <Switch
                      checked={this.state.formData.is_adult_show}
                      height={20}
                      width={48}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      onColor="#006afe"
                      onChange={e => {
                        this.setState({
                          formData:{
                            ...this.state.formData,
                            is_adult_show:e
                          }
                        })}}
                    />
                  </div>
                  <div className={"col-lg-12 "}>
                    <button
                      type={"button"}
                      className={"btn btn-primary"}
                      onClick={() => {
                        this.props
                          .updateMainData(this.state.formData)
                          .then((response) => {
                            if (Object.keys(this.props.user).length > 0) {
                              this.props.userViewToken().then((response) => {
                                this.props.userNewData(response.data);
                              });
                            }
                            window.location.href = ("/my-profile/about");
                          });
                      }}
                    >
                      Save
                    </button>
                  </div>
                  <div  className={'col-12 mt-3'}>
                    <button className={'btn btn-primary btn-full-width'} style={{
                      fontSize:19
                    }} onClick={() => {
                      if(window.confirm('Do you confirm logout?')){
                        this.props.logout();
                      }
                    }}>
                      Logout
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <aside className="col col-xl-3 order-xl-3 col-lg-12 order-lg-3 col-12">
            <MyProfileUserBlock user={this.props.user} />
          </aside>
        </BaseLayout>
        <Tabbar />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user.user,
    settings: state.settings,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logout:() => {
      dispatch(logout());
    },
    updateMainData: (formData) => {
      return dispatch(updateMainData(formData));
    },
    userViewToken: () => {
      return dispatch(userViewToken());
    },
    userNewData: (data) => {
      dispatch(userNewData(data));
    },
    skills: () => {
      return dispatch(skills());
    },
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainAccountSettings);
