import React, { Component } from "react";
import DesktopNavbar from "../../components/headers/DesktopNavbar";
import MobileNavbar from "../../components/headers/MobileNavbar";
import BaseLayout from "../../layouts/BaseLayout";
import MyProfileSidebar from "../../components/sidebars/MyProfileSidebar";
import MyProfileSidebarMobile from "../../components/sidebars/MyProfileSidebarMobile";
import MyProfileUserBlock from "../../components/sidebars/MyProfileUserBlock";
import Tabbar from "../../components/headers/Tabbar";
import { getUserByName } from "../../redux/actions/userActions";
import { trendingServices } from "../../redux/actions/serviceActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import TrendingSidebarDesktop from "../../components/sidebars/TrendingSidebarDeesktop";
import TrendingSidebarMobile from "../../components/sidebars/TrendingSidebarMobile";
class TrendingServices extends Component {
  state = {
    user: {},
    list: [],
  };
  componentDidMount() {
    if (!this.props.match.params.user) {
      this.setState(
        {
          user: { ...this.props.user },
        },
        () => {
          this.setServices();
        }
      );
    } else {
      this.props
        .getUserByName(this.props.match.params.user)
        .then((response) => {
          this.setState(
            {
              user: { ...response.data },
            },
            () => {
              this.setServices();
            }
          );
        });
    }
  }

  setServices = () => {
    this.props.trendingServices().then((response) => {
      this.setState({
        list: [...response],
      });
    });
  };

  render() {
    return (
      <React.Fragment>
        <DesktopNavbar />
        <MobileNavbar />
        <BaseLayout>
          <aside className="col col-xl-3 order-xl-1 col-lg-12 order-lg-1 col-12 my-profile ">
            <div className={" row sidebar-menu"}>
              <div className={"col-12 "}>
                <div className={"bg-white profile-account-holder desktop"}>
                  <h2>@{this.state.user.name}</h2>
                  <TrendingSidebarDesktop
                    active={"services"}
                    userLink={
                      Object.keys(this.state.user).length > 0
                        ? `/${this.state.user.name}`
                        : ""
                    }
                  />
                </div>
                <div className={"mobile"}>
                  <TrendingSidebarMobile
                    userLink={
                      Object.keys(this.state.user).length > 0
                        ? `/${this.state.user.name}`
                        : ""
                    }
                    active={{
                      value: "/trending/services",
                      label: "Services",
                    }}
                  />
                </div>
              </div>
            </div>
          </aside>
          <main
            className="col col-xl-6 order-xl-2 col-lg-12 order-lg-2 col-md-12 col-sm-12 col-12 mb-3 post-list"
            style={{ padding: "0 8px" }}
          >
            <div className={"row"}>
              {this.state.list.map((service, index) => {
                return (
                  <div className={"col-lg-4"} key={`service_${index}`}>
                    <div className="box shadow-sm border rounded bg-white mb-4">
                      <div className="card  card-recommendation-user online ">
                        <img
                          className="card-img-top"
                          src={
                            this.props.settings.mainUrl +
                            "/storage/" +
                            service.description_image
                          }
                          alt="Card image cap"
                        />
                        <div className="avatar-block">
                          <div className="img-block rounded-circle">
                            <img
                              className="rounded-circle"
                              src={
                                this.props.settings.mainUrl +
                                "/storage/" +
                                service.user.avatar
                              }
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                this.props.history.push(
                                  `/my-profile/highlights/${service.user.name}`
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div>
                          <div className="card-body">
                            <div className="card-title position-relative d-inline-block">
                              <h5
                                className="title font-weight-600"
                                style={{ fontSize: 13 }}
                              >
                                {service.user.firstName} {service.user.lastName}
                              </h5>
                              <img
                                className="verify position-absolute"
                                src={require("./../../assets/img/badge.png")}
                              />
                            </div>
                            <h6
                              className="text-center font-weight-600 mb-3"
                              style={{ fontSize: 12 }}
                            >
                              Subtitle Subtitle
                            </h6>
                            <p className="card-text">{service.description}</p>
                          </div>
                          <a
                            href="#"
                            className="btn btn-primary btn-block card-button"
                            onClick={(e) => {
                              e.preventDefault();
                              this.props.history.push(`/service/${service.id}`);
                            }}
                          >
                            Order for ${service.price}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </main>
          <aside className="col col-xl-3 order-xl-3 col-lg-12 order-lg-3 col-12">
            <MyProfileUserBlock user={this.props.user} />
          </aside>
        </BaseLayout>
        <Tabbar />
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    user: state.user.user,
    settings: state.settings,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    getUserByName: (name) => {
      return dispatch(getUserByName(name));
    },
    trendingServices: () => {
      return dispatch(trendingServices());
    },
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TrendingServices));
