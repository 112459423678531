import React, { Component } from "react";
import Tick from '../../assets/img/tick.png'
import './picker.scss'

const styles = {
    tickStyle: {
      backgroundImage: `url(${Tick})`,
      backgroundRepeat: ' no-repeat',
      backgroundPosition: 'top 17px right 17px'
    }
  }
  export default class Picker extends Component{
    constructor(props) {
        super(props);
        const days = Array.from({length: 31}, (_, i) => i + 1);
        const months = ['January', 'February', 'March', 'April', 
                        'May', 'June', 'July', 'August', 'September', 'October',
                        'November', 'December'];
        function range(a,b,c){c=[];while(a--)c[a]=a+b;return c};      
        const years=range(101,1920);
        this.state = {
            days: days,
            days365: true,
            months: months,
            month: "January",
            years: years,
            year: 2020,
            day: 1,
            birsday: ""
        };
        this.handleChangeDay = this.handleChangeDay.bind(this);
        this.handelMonths = this.handelMonths.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

  
    handleChangeDay(event){
        let birthday = `${event.target.value} ${this.state.month} ${this.state.year}`;
        this.setState({day: event.target.value, birsday: birthday}
            )
        this.props.onChangeBirthday(birthday);    
    }
    handleChange(event) {
        let birthday = `${this.state.day} ${this.state.month} ${event.target.value}`;
        this.setState({year: event.target.value, birsday: birthday});
        this.props.onChangeBirthday(birthday);   
        if(event.target.value % 4 === 0){
            this.setState.days365= false;
        }else{
            this.setState.days365 = true;
       }
     }
    handelMonths(event){
        let birthday = `${this.state.day} ${event.target.value} ${this.state.year}`;
        this.setState({month: event.target.value, birsday: birthday});
        this.props.onChangeBirthday(birthday);   
        if(this.state.value === 'January' || this.state.value  === 'March' || 
        this.state.value  === 'May' || this.state.value  === 'July' || this.state.value  === 'August' 
        || this.state.value  === 'October' || this.state.value  === 'December') {
            this.setState.months = 31;
        }else if(this.state.value === 'April' || this.state.value === 'June' 
         || this.state.value === 'September' || this.state.value === 'November') {
            this.setState.months = 30;
        }else{
            if(this.state.days365){
                this.setState.months = 29;
            }else{
                this.setState.months = 28;
            }
        }
        const nDays = Array.from({length: this.setState.months}, (_, i) => i + 1);
        this.setState(state => ({ 
            days: nDays
        }));
    }
    render(){
        return(
        <div className="form-picker">
            <h3 className="birthday-title">When’s your birthday?</h3>
            <form className="birthday">
                <div className="birthday-around">
                    <select style={styles.tickStyle}  
                    onChange={this.handelMonths} 
                    className="birthday-all birthday-month"
                    name="month">
                        {this.state.months.map((month,index) => (
                            <option key={index}>{month}</option>
                        ))}   
                    </select>
                    <select className="birthday-all birthday-day" 
                    style={styles.tickStyle} 
                    day={this.state.day}
                    name="day"
                    onChange={this.handleChangeDay}>
                    {this.state.days.map((day,index) => (
                            <option key={index}>{day}</option>
                        ))}
                    </select>
                    <select className="birthday-all birthday-year"  
                    style={styles.tickStyle} 
                    name="year" 
                    value={this.state.value}
                    onChange={this.handleChange}
                    >
                    {this.state.years.map((year,index) => (
                            <option
                            key={index}>{year}</option>
                        ))}
                    </select>
                </div>
            </form>
        </div>
        );
    }
}
