import axios from "axios";


export function getSkillCategoriesList() {
  return async (dispatch,getState) => {
    let mainUrl = getState().settings.mainUrl;
    let response = await axios.get(
      `${mainUrl}/api/skill-categories`,{
        params:{
          api_token: getState().user.user.api_token,
        }
    });
    return response;
  }

}