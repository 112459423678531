import React,{Component} from 'react';
import DesktopNavbar from "../../components/headers/DesktopNavbar";
import MobileNavbar from "../../components/headers/MobileNavbar";
import BaseLayout from "../../layouts/BaseLayout";
import DesktopSidebarAccountSettings from "../../components/sidebars/DesktopSidebarAccountSettings";
import MyProfileUserBlock from "../../components/sidebars/MyProfileUserBlock";
import Tabbar from "../../components/headers/Tabbar";
import { connect } from "react-redux";
import {changeBadge} from '../../redux/actions/userActions';
import MobileSidebarAccountSettings from "../../components/sidebars/MobileSidebarAccountSettings";

class BadgesSettings extends Component{

  state = {
    badges:[],
    activeBadge:''
  };

  componentDidMount() {
    let badges = [];
    let activeBadge = '';

    if(this.props.user.allow_badges !== '' || this.props.user.allow_badges !== null){
      badges = JSON.parse(this.props.user.allow_badges);
    }

    if(this.props.user.badge !== '' || this.props.user.badge !== null){
      activeBadge = this.props.user.badge;
    }

    this.setState({
      badges:[...badges],
      activeBadge:activeBadge
    })
  }

  render(){
    console.log(this.state)
    return  <React.Fragment>
      <DesktopNavbar />
      <MobileNavbar />
      <BaseLayout>
        <aside className="col col-xl-3 order-xl-1 col-lg-12 order-lg-1 col-12 my-profile ">
          <div className={" row sidebar-menu"}>
            <div className={"col-12 "}>
              <div className={"bg-white profile-account-holder desktop"}>
                <h2>Settings</h2>
                <DesktopSidebarAccountSettings
                  active={"/settings/badges"}
                />
              </div>
              <div className={"mobile"}>
                <MobileSidebarAccountSettings
                  active={{ value: "/settings/badges", label: "Badges" }}
                />
              </div>
            </div>
          </div>
        </aside>
        <main
          className="col col-xl-6 order-xl-2 col-lg-12 order-lg-2 col-md-12 col-sm-12 col-12 mb-3"
          style={{ padding: "0 16px" }}
        >
          <div className="profile-account-holder row">
            <div className={"col-12  bg-white  settings-container"}>
              <div className={'d-flex justify-content-between mb-3'}>
                <h1 className={'d-inline-block'} >Badges </h1>

              </div>
              <div className={'badge-settings'}>
                <h2>Unlocked Badges</h2>
                <div className={'unlocked-badges'}>
                  <div className={'form-group mb-0'}>
                    <label htmlFor={'badge_none'} className={'d-inline-flex align-items-center'}>
                      <input type={'radio'} id={'badge_none'} name={'badge'} value={''}  onChange={e => {
                        this.setState({
                          activeBadge:e.target.value
                        })
                      }} checked={this.state.activeBadge === ''} className={'mr-2'} />
                      <span>No Badge</span>

                    </label>
                  </div>

                  {this.state.badges.map((badge, index) => {
                    return <div className={'form-group mb-0'} key={`radio-badge-${index}`}>
                      <label htmlFor={`badge_${index}`} className={'d-inline-flex align-items-center'}>
                        <input type={'radio'} id={`badge_${index}`} name={'badge'} className={'mr-2'} value={badge} onChange={e => {
                          this.setState({
                            activeBadge:e.target.value
                          })
                        }} checked={badge === this.state.activeBadge} />
                        <img src={require(`./../../assets/img/${badge}.png`)}  width={40} />
                      </label>
                    </div>
                  })}
                  <button type={'button'} className={'btn btn-primary my-3'} onClick={() => {
                    this.props.changeBadge(this.state.activeBadge).then(response => {
                      window.location.href = '/';
                    });
                  }}>Save</button>
                </div>
                <h2>Earnible Badges</h2>
                <div className={'earnible-badges'}>
                  <div className={'earning-item'}>
                    <img src={require('./../../assets/img/badge1.png')} width={70} className={'mr-2'}/>
                    <span className={'title'}>Awarded for reaching 100,00 Experience.</span>
                  </div>
                  <div className={'earning-item'}>
                    <img src={require('./../../assets/img/badge2.png')} width={70} className={'mr-2'}/>
                    <span className={'title'}>Awarded for reaching 1,000,00 Experience.</span>
                  </div>
                  <div className={'earning-item'}>
                    <img src={require('./../../assets/img/badge3.png')} width={70} className={'mr-2'}/>
                    <span className={'title'}>Awarded for reaching $10,000 supporting creators.</span>
                  </div>
                  <div className={'earning-item'}>
                    <img src={require('./../../assets/img/badge4.png')} width={70} className={'mr-2'}/>
                    <span className={'title'}>Awarded for reaching $100,000 supporting creators.</span>
                  </div>
                  <div className={'earning-item'}>
                    <img src={require('./../../assets/img/badge5.png')} width={70} className={'mr-2'}/>
                    <span className={'title'}>Awarded for reaching $10,000 with Witit.</span>
                  </div>
                  <div className={'earning-item'}>
                    <img src={require('./../../assets/img/badge6.png')} width={70} className={'mr-2'}/>
                    <span className={'title'}>Awarded for reaching $100,000 with Witit.</span>
                  </div>
                  <div className={'earning-item'}>
                    <img src={require('./../../assets/img/badge7.png')} width={70} className={'mr-2'}/>
                    <span className={'title'}>Awarded to celebrities, notible figures, and other significant entities.</span>
                  </div>
              </div>
            </div>
          </div>
          </div>
        </main>
        <aside className="col col-xl-3 order-xl-3 col-lg-12 order-lg-3 col-12">
          <MyProfileUserBlock user={this.props.user} />
        </aside>
      </BaseLayout>
      <Tabbar />
    </React.Fragment>
  }
}
function mapStateToProps(state) {
  return {
    user: state.user.user,
    settings: state.settings,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeBadge:(badge) => {
      return dispatch(changeBadge(badge))
    }
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(BadgesSettings) ;