import React from "react";
import axios from "axios";

export function postReport(formData, id_post) {
  return (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;

    let response = axios.post(mainUrl + `/api/report/post`, {
      formData: formData,
      id_post: id_post,
      api_token: getState().user.user.api_token,
      id_user: getState().user.user.id,
    });
    return response;
  };
}
