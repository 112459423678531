import React, { Component } from "react";
import { Alert } from "react-bootstrap";
import LoginRegistration from "../../layouts/LoginRegistration";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  login,
  setUserLogin,
  userLoginResultError,
  userLoginResultSuccess,
} from "./../../redux/actions/userActions";
import  LockClosed from '../../assets/img/lock-closed.svg';
import  IconUser from '../../assets/img/icon-user.svg';
import Logo from "../../components/Logo/Logo.js"

const styles = {
  LockClosed: {
    backgroundImage: `url(${LockClosed})`,
    backgroundRepeat: ' no-repeat',
    backgroundPosition: 'top 13.62px left 11.5px',
    paddingLeft: '44px',
  },
  IconUser:{
    backgroundImage: `url(${IconUser})`,
    backgroundRepeat: ' no-repeat',
    backgroundPosition: 'top 16.5px left 11.5px',
    paddingLeft: '43px',
  }
}

class Login extends Component {
  state = {
    formData: {
      email: "",
      password: "",
    },
    errors: {
      email: [],
      password: [],
    },
    status: false,
    isErrors: false,
  };

  validateForm = () => {
    let errors = {
      email: [],
      password: [],
    };

    if (this.state.formData.email === "") {
      errors.email.push(<div>email must be required!</div>);
    }
    if (this.state.formData.password === "") {
      errors.password.push(<div>password must be required!</div>);
    }
    return errors;
  };

  checkErrors = (errors) => {
    let isError = false;
    Object.keys(errors).map((objectKey, index) => {
      let value = errors[objectKey];
      if (value.length > 0) {
        isError = true;
      }
    });
    return isError;
  };

  render() {
    return (
      <React.Fragment>
        <LoginRegistration>
          <div className="login-page position-relative">
            <div className="text-content">
              <div className="row">
                <div className="col-lg-7 content">
                  <h2>
                    Turn your social life
                    <br />
                    into your source income
                  </h2>
                  <ul style={{ paddingLeft: 20 }}>
                    <li>
                      <b>Turn posts into profit,</b> with tips and premium
                      content.
                    </li>
                    <li>
                      <b>Charge per minute</b> for calls and video chats.
                    </li>
                    <li>
                      <b>List services</b> and hired on the spot
                    </li>
                    <li>
                      <b>No algorithm,</b> all chronological.
                    </li>
                    <li>
                      <b>No ads,</b> and there never will be.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="form-block">
              {/* <div className="form-header text-center"> */}
                {/* <div className="logo">
                  <h1 className="logo-title">Witit</h1>
                  <h3 className="logo-subtitle">go get it.</h3>
                </div> */}
                <Logo />
                {/* <img src={require("../../assets/img/logo.svg")} /> */}
              {/* </div> */}
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  let errors = this.validateForm();
                  let isErrors = this.checkErrors(errors);
                  if (!isErrors) {
                    this.props
                      .login(this.state.formData)
                      .then((response) => {
                        this.props.userLoginResultSuccess(
                          response.data.message
                        );

                        this.props.setUserLogin(response.data.user);
                        this.setState({ status: true, isErrors: false });
                        this.props.history.push("/my-profile/highlights");
                      })
                      .catch((error) => {
                        console.log(error.response);
                        this.props.userLoginResultError(
                          error.response.data.message
                        );
                        this.setState({ status: true, isErrors: false });
                      });
                  } else {
                    this.setState({ isErrors: true, errors: { ...errors } });
                  }
                }}
              >
                {this.state.status ? (
                  <Alert variant={this.props.loginData.type}>
                    {this.props.loginData.message}
                  </Alert>
                ) : null}
                <div
                  className={[
                    "form-group",
                    this.state.errors.email.length > 0 && this.state.isErrors
                      ? "error"
                      : "",
                  ].join(" ")}
                >
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Username or Email"
                    style={styles.IconUser}
                    value={this.state.formData.email}
                    onChange={(e) => {
                      this.setState({
                        formData: {
                          ...this.state.formData,
                          email: e.target.value,
                        },
                      });
                    }}
                  />
                  {/* <HiUser className="icon-login"/> */}
                  <div className={"errors-messages"}>
                    {this.state.errors.email.length > 0 && this.state.isErrors
                      ? this.state.errors.email
                      : null}
                  </div>
                </div>
                <div
                  className={[
                    "form-group",
                    this.state.errors.password.length > 0 && this.state.isErrors
                      ? "error"
                      : "",
                  ].join(" ")}
                >
                  <input
                    type="password"
                    className="form-control form-person-outline"
                    placeholder="Password"
                    style={styles.LockClosed}
                    value={this.state.formData.password}
                    onChange={(e) => {
                      this.setState({
                        formData: {
                          ...this.state.formData,
                          password: e.target.value,
                        },
                      });
                    }}
                  />
                  {/* <HiLockClosed className="icon-login icon-login-after"/> */}
                  {/* <img src={LockClosed} className="icon-login icon-login-after"/> */}
                  <div className="forgot-password-link">
                    <a
                      href={"#"}
                      className="forgot-password-text"
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push("/forgot-password");
                      }}
                    >
                      Forgot Password?
                    </a>
                  </div>
                  <div className={"errors-messages"}>
                    {this.state.errors.password.length > 0 &&
                    this.state.isErrors
                      ? this.state.errors.password
                      : null}
                  </div>
                </div>
                <div className="form-group row">
                  {/* <div className="col-sm-12"> */}
                    <button
                      type="submit"
                      className="btn btn-primary btn-full-width mb-1"
                    >
                      Log in
                    </button>
                  {/* </div> */}
                </div>
                {/* <div className="form-group or-block  position-relative">
                  <hr />
                  <div className="text-center text-block">
                    <span>OR</span>
                  </div>
                </div> */}

                {/* <div className="form-group row">
                  <div className="col-sm-12">
                    <button
                      type="button"
                      onClick={() => {
                        this.props.history.push("/register");
                      }}
                      className="btn btn-dark btn-full-width"
                    >
                      Sign Up
                    </button>
                  </div> */}
                <div className="form-group pt-2">
                  <div className="form-text " style={{marginTop:"70px"}}>Don’t have an account? 
                    <a
                      href={"#"}
                      onClick={() => {
                        this.props.history.push("/register");
                      }}
                      className="form-link"
                    >
                    Sign up!
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </LoginRegistration>
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    login: (formData) => {
      return dispatch(login(formData));
    },
    userLoginResultSuccess: (message) => {
      dispatch(userLoginResultSuccess(message));
    },
    userLoginResultError: (message) => {
      dispatch(userLoginResultError(message));
    },
    setUserLogin: (user) => {
      dispatch(setUserLogin(user));
    },
  };
}

function mapStateToProps(state) {
  return {
    loginData: state.user.login,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
