import React, { useEffect } from "react";
import DesktopNavbar from "../../components/headers/DesktopNavbar";
import MobileNavbar from "../../components/headers/MobileNavbar";
import BaseLayout from "../../layouts/BaseLayout";

import MyProfileUserBlock from "../../components/sidebars/MyProfileUserBlock";
import Tabbar from "../../components/headers/Tabbar";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from "axios";

function PaymentError(props) {
  useEffect(() => {
    if (props.match.params.id !== undefined) {
      axios.post(
        `${props.settings.mainUrl}/api/stripe/payment/error/${props.match.params.id}`,
        {
          api_token: props.user.api_token,
        }
      );
    }
  }, []);
  return (
    <React.Fragment>
      <DesktopNavbar />
      <MobileNavbar />
      <BaseLayout>
        <aside className="col col-xl-3 order-xl-1 col-lg-12 order-lg-1 col-12 my-profile "></aside>
        <main
          className="col col-xl-6 order-xl-2 col-lg-12 order-lg-2 col-md-12 col-sm-12 col-12 mb-3 post-list"
          style={{ padding: "0 8px" }}
        >
          <div className="profile-account-holder  mb-3  bg-white post-item-list service-simple">
            <div className={" p-0 d-flex justify-content-center"}>
              <div
                className={
                  "d-flex align-items-center service-order-result text-center justify-content-center"
                }
              >
                <div>Your payment some error.</div>
              </div>
            </div>
          </div>
        </main>
        <aside className="col col-xl-3 order-xl-3 col-lg-12 order-lg-3 col-12"></aside>
      </BaseLayout>
      <Tabbar />
    </React.Fragment>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user.user,
    settings: state.settings,
  };
}
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PaymentError));
