import React from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";

const GuestRoute = ({ component: Component, ...props }) => {
  return (
    <Route
      {...props}
      render={(data) => {
        console.log("Route");
        return (
          <React.Fragment>
            {Object.keys(props.user).length > 0 ? (
              <Redirect to={"/my-profile"} />
            ) : (
              <Component {...data} />
            )}
          </React.Fragment>
        );
      }}
    />
  );
};

function mapStateToProps(state) {
  return {
    user: state.user.user,
  };
}
export default connect(mapStateToProps)(GuestRoute);
