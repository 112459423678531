import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class DesktopSidebarAccountSettings extends Component {
  state = {
    menu: [
      { value: "/settings/general", label: "General" },
      { value: "/settings/account", label: "Account" },
      { value: "/settings/payments", label: 'Payments'},
      { value: "/settings/badges", label: 'Badges'},
      { value: "/settings/help", label: 'Help'},
      { value: "/settings/about", label: 'About'},
    ],
  };
  render() {
    return (
      <ul className={"p-0"} style={{ listStyleType: "none" }}>
        {this.state.menu.map((item, index) => {
          return (
            <li key={`settings_${index}`} className={this.props.active === item.value ? "active" : ""}>
              <NavLink to={item.value}>{item.label}</NavLink>
            </li>
          );
        })}
      </ul>
    );
  }
}
export default DesktopSidebarAccountSettings;
