import React from "react";
import moment from "moment";
import "./Message.css";
import FontAwesome from "react-fontawesome";

function Message(props) {
  const { data, isMine, startsSequence, endsSequence, showTimestamp } = props;

  const friendlyTimestamp = moment(data.timestamp).format("LLLL");

  let messageContent = (data) => {
    switch (data.type) {
      case "base":
        return <React.Fragment>{data.message}</React.Fragment>;
      case "order":
        let content = (
          <React.Fragment>
            <h4>{data.message}</h4>
            <p>{data.order.description}</p>
            {data.order.files.map((file, index) => {
              return (
                <div key={`file_order_${data.order.id}_${index}`}>
                  <a href={props.mainUrl + "/storage/" + file}>
                    <FontAwesome name={"file"} /> {file.split("/").pop()}
                  </a>
                </div>
              );
            })}
          </React.Fragment>
        );
        return content;

      default:
        return <React.Fragment>{data.message}</React.Fragment>;
    }
  };

  return (
    <div
      className={[
        "message",
        `${isMine ? "mine" : ""}`,
        `${startsSequence ? "start" : ""}`,
        `${endsSequence ? "end" : ""}`,
      ].join(" ")}
    >
      {showTimestamp && <div className="timestamp">{friendlyTimestamp}</div>}

      <div className="bubble-container">
        <div className="bubble" title={friendlyTimestamp}>
          {messageContent(data)}
        </div>
      </div>
    </div>
  );
}
export default Message;
