import React, { Component } from "react";
import DesktopNavbar from "../../components/headers/DesktopNavbar";
import MobileNavbar from "../../components/headers/MobileNavbar";
import BaseLayout from "../../layouts/BaseLayout";
import MyProfileSidebar from "../../components/sidebars/MyProfileSidebar";
import MyProfileSidebarMobile from "../../components/sidebars/MyProfileSidebarMobile";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import MyProfileUserBlock from "../../components/sidebars/MyProfileUserBlock";
import Tabbar from "../../components/headers/Tabbar";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { viewService } from "../../redux/actions/serviceActions";
import { createOrder } from "../../redux/actions/orderActions";

class SimpleService extends Component {
  state = {
    service: {},
  };

  componentDidMount() {
    let id = this.props.match.params.id;
    this.props.viewService(id).then((response) => {

      this.setState({
        service: { ...response },
      });
    });
  }

  render() {
    return (
      <React.Fragment>
        <DesktopNavbar />
        <MobileNavbar>{ Object.keys(this.state.service).length > 0 ? `@${this.state.service.user.name}` : ' '}</MobileNavbar>
        <BaseLayout>
          <aside className="col col-xl-3 order-xl-1 col-lg-12 order-lg-1 col-12 my-profile ">
            <div className={" row sidebar-menu"}>
              <div className={"col-12 "}>
                <div className={"bg-white profile-account-holder desktop"}>
                  <h2>@{ Object.keys(this.state.service).length > 0 ? this.state.service.user.name : null}</h2>
                  <MyProfileSidebar active={"services"} isOwner={false} userLink={
                    Object.keys(this.state.service).length > 0
                      ? `/${this.state.service.user.name}`
                      : ""
                  } />
                </div>
                <div className={"mobile"}>
                  <MyProfileSidebarMobile
                    isOwner={false}
                    userLink={
                      Object.keys(this.state.service).length > 0
                        ? `/${this.state.service.user.name}`
                        : ""
                    }
                    active={{
                      value: "/my-profile/services",
                      label: "Services",
                    }}
                  />
                </div>
              </div>
            </div>
          </aside>
          <main
            className="col col-xl-6 order-xl-2 col-lg-12 order-lg-2 col-md-12 col-sm-12 col-12 mb-3 post-list"
            style={{ padding: "0 8px" }}
          >
            {Object.keys(this.state.service).length > 0 ? (
              <div className="profile-account-holder  mb-3  bg-white post-item-list service-simple">
                <div
                  className={"post-header p-2 d-flex justify-content-between"}
                >
                  <div className={"d-flex align-items-center"}>
                    <NavLink
                      to={
                        "/my-profile/highlights/" + this.state.service.user.name
                      }
                      style={{ color: "#000", marginRight: 20, fontSize: 16 }}
                    >
                      <FontAwesomeIcon icon={faChevronLeft} />
                    </NavLink>
                    <img
                      src={
                        this.props.settings.mainUrl +
                        "/storage/" +
                        this.state.service.user.avatar
                      }
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        this.props.history.push(
                          `/my-profile/highlights/${this.state.service.user.name}`
                        );
                      }}
                      className={"rounded-circle mr-3"}
                      width={30}
                    />
                    <div className={"position-relative post-author  d-flex"}>
                      <div className={"font-weight-600"}>
                        {" "}
                        {this.state.service.user.full_name
                        }
                      </div>
                      <div className={"author-badge"} style={{}}>
                        {
                          Object.keys(this.state.service ).length > 0 &&  this.state.service.user.badge !== ''? <img src={require(`../../assets/img/${this.state.service.user.badge}.png`)} width={15} />   : null
                        }
                      </div>
                    </div>
                  </div>
                  <div></div>
                </div>
                <div className={"post-image-block"}>
                  <img
                    src={
                      this.props.settings.mainUrl +
                      "/storage/" +
                      this.state.service.description_image
                    }
                    className={"img-full"}
                  />
                </div>
                <div className={"service-data"}>
                  <h1>{this.state.service.name}</h1>
                  <div className={"service-params"}>
                    <div className={"param"}>
                      {this.state.service.description}
                    </div>

                    <div className={"param"}>
                      Price: ${this.state.service.price}
                    </div>
                  </div>
                  <div className={"button-block"}>
                    <button
                      className={"btn btn-primary"}
                      style={{ width: "100%" }}
                      onClick={() => {
                        this.props.history.push(
                          `/service-place-order/${this.state.service.id}`
                        );
                      }}
                    >
                      Place order (${this.state.service.price})
                    </button>
                  </div>
                  <div className={"text-center footer-service"}>
                    By placing the order you agree to the Witit{" "}
                    <a href={""}>Terms of Service</a>
                  </div>
                </div>
              </div>
            ) : null}
          </main>
          <aside className="col col-xl-3 order-xl-3 col-lg-12 order-lg-3 col-12">
            <MyProfileUserBlock user={this.props.user} />
          </aside>
        </BaseLayout>
        <Tabbar />
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    user: state.user.user,
    settings: state.settings,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    viewService: (id) => {
      return dispatch(viewService(id));
    },
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SimpleService));
