import React from "react";
import FontAwesome from "react-fontawesome";
import { faBars, faChevronLeft } from "@fortawesome/free-solid-svg-icons";

function CallFrom(props) {
  return (
    <div className={"call from"}>
      <div className={"dialog-call text-center"}>
        <h3>{props.activeConversation.full_name} is calling</h3>
        <div className={"name"}>@{props.activeConversation.name}</div>
        <div className={"buttons"}>
          <button
            type={"button"}
            className={"btn btn-success join"}
            onClick={props.handleJoin}
          >
            <FontAwesome name={"phone"} />
          </button>
          <button
            type={"button"}
            className={"btn btn-danger down"}
            onClick={props.handleLogout}
          >
            <FontAwesome name={"phone"} />
          </button>
        </div>
      </div>
    </div>
  );
}
export default CallFrom;
