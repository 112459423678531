import React from "react";
import FontAwesome from "react-fontawesome";

function CallTo(props) {
  return (
    <div className={"call to"}>
      <div className={"dialog-call text-center"}>
        <h3> Calling to {props.activeConversation.full_name} </h3>
        <div className={"name"}>@{props.activeConversation.name}</div>
        <div className={"buttons"}>
          <button
            type={"button"}
            className={"btn btn-danger down"}
            onClick={props.handleLogout}
          >
            <FontAwesome name={"phone"} />
          </button>
        </div>
      </div>
    </div>
  );
}
export default CallTo;
