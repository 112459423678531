import axios from "axios";

export function createDeviceUser(id_device) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let id_user = getState().user.user.id;
    let response = await axios.post(
      mainUrl + `/api/push-notification/user/${id_user}/device/${id_device}`,
      {
        api_token: getState().user.user.api_token,
      }
    );
    return response.data;
  };
}

export function pushNotification() {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let id_user = getState().user.user.id;
    let response = await axios.post(
      mainUrl + `/api/push-notification/push/${id_user}`,
      {
        api_token: getState().user.user.api_token,
      }
    );
    return response.data;
  };
}
