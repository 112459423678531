import React,{Component} from 'react';
import DesktopNavbar from "../../components/headers/DesktopNavbar";
import MobileNavbar from "../../components/headers/MobileNavbar";
import BaseLayout from "../../layouts/BaseLayout";
import DesktopSidebarAccountSettings from "../../components/sidebars/DesktopSidebarAccountSettings";
import {changePassword,deactivateUser,logout} from '../../redux/actions/userActions'
import {connect} from 'react-redux';
import MyProfileUserBlock from "../../components/sidebars/MyProfileUserBlock";
import Tabbar from "../../components/headers/Tabbar";
import MobileSidebarAccountSettings from "../../components/sidebars/MobileSidebarAccountSettings";


class AccountSettings extends Component{

  state = {
    formData:{
      password: '',
      compare_password: ''
    },
    errors:{
      password:[],
      compare_password:[]
    }
  }

  validateForm = () => {
    let errors = {
      password:[],
      compare_password:[]
    };

    if(this.state.formData.password === ''){
      errors.password.push('New Password cannot be empty!');
    }
    if(this.state.formData.compare_password === ''){
      errors.compare_password.push('Confirm New Password cannot be empty!');
    }
    if(this.state.formData.compare_password !== this.state.formData.password){
      errors.compare_password.push('New Password and Confirm New Password must be the same!')
    }

    return errors;
  }

  checkErrors = (errors) => {
    let isError = false;
    Object.keys(errors).map((objectKey, index) => {
      let value = errors[objectKey];
      if (value.length > 0) {
        isError = true;
      }
    });
    return isError;
  };

  render(){
    return  <React.Fragment>
      <DesktopNavbar />
      <MobileNavbar />
      <BaseLayout>
        <aside className="col col-xl-3 order-xl-1 col-lg-12 order-lg-1 col-12 my-profile ">
          <div className={" row sidebar-menu"}>
            <div className={"col-12 "}>
              <div className={"bg-white profile-account-holder desktop"}>
                <h2>Settings</h2>
                <DesktopSidebarAccountSettings
                  active={"/settings/account"}
                />
              </div>
              <div className={"mobile"}>
                <MobileSidebarAccountSettings
                  active={{ value: "settings/account", label: "Account" }}
                />
              </div>
            </div>
          </div>
        </aside>
        <main
          className="col col-xl-6 order-xl-2 col-lg-12 order-lg-2 col-md-12 col-sm-12 col-12 mb-3"
          style={{ padding: "0 16px" }}
        >
          <div className="profile-account-holder row">
            <div className={"col-12  bg-white settings-main settings-container "}>
              <h1 >Account</h1>
              <h2 className={'mb-3'}>Change Password</h2>
              <form className={'change-password'} onSubmit={event => {
                event.preventDefault();
                let errors = this.validateForm();
                this.setState({
                  errors:{
                    ...errors
                  }
                }, () => {
                  let isError = this.checkErrors(this.state.errors);
                  if (!isError) {
                    this.props.changePassword(this.state.formData).then(response => {
                      this.props.history.push('/');
                    })
                  }
                });
              }}>
                <div className={[
                  'form-group mb-2 ',
                  this.state.errors.password.length > 0 ? 'error' : ''
                ].join(' ')}>
                  <h2>New Password:</h2>
                  <input className={'form-control'} type={'password'} value={this.state.formData.password} onChange={event => {
                    this.setState({
                      formData:{
                        ...this.state.formData,
                        password:event.target.value
                      }
                    })
                  }}/>
                  <div className={'errors_list'}>
                    {this.state.errors.password.map((error,index) => {
                      return <div key={`password_${index}`}>{error}</div>
                    })}
                  </div>
                </div>
                <div className={[
                  'form-group mb-2 ',
                  this.state.errors.compare_password.length > 0 ? 'error' : ''
                ].join(' ')}>
                  <h2>Confirm New Password:</h2>
                  <input className={'form-control'}  type={'password'} value={this.state.formData.compare_password} onChange={event => {
                    this.setState({
                      formData:{
                        ...this.state.formData,
                        compare_password:event.target.value
                      }
                    })
                  }} />
                  <div className={'errors_list'}>
                    {this.state.errors.compare_password.map((error,index) => {
                      return <div key={`confirm_password_${index}`}>{error}</div>
                    })}
                  </div>
                </div>
                <button type={'button mb-4'} className={'btn btn-primary'} type={'submit'}>Change Password</button>

                <a className={'d-block'} style={{color:'#dc3545'}} href={''} onClick={event => {
                  event.preventDefault();
                  if(window.confirm('Do you confirm deactivate your account?')){
                    this.props.deactivateUser().then(response => {
                      this.props.logout();
                    });
                  }

                }}>Deactivate Account</a>
              </form>

            </div>
          </div>
        </main>
        <aside className="col col-xl-3 order-xl-3 col-lg-12 order-lg-3 col-12">
          <MyProfileUserBlock user={this.props.user} />
        </aside>
      </BaseLayout>
      <Tabbar />
    </React.Fragment>
  }
}
function mapStateToProps(state) {
  return {
    user: state.user.user,
    settings: state.settings,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changePassword:(formData) => {
      return dispatch(changePassword(formData))
    },
    deactivateUser:() => {
      return dispatch(deactivateUser());
    },
    logout:() =>{
      dispatch(logout());
    }
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(AccountSettings) ;