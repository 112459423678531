import  React from 'react';
import {withRouter} from 'react-router-dom'
import {ReactComponent as Community}  from "../../assets/img/icon-community.svg" 
import {ReactComponent as Plus}  from "../../assets/img/icon-plus.svg" 
import {ReactComponent as Hype}  from "../../assets/img/icon-hype.svg" 

function NotFoundBlock(props) {
  return <React.Fragment>
    <div className={'not-found-block'}>
      <div className="profile-account-holder  mb-3  bg-white post-item-list service-simple">
        <div className={" p-0 d-flex justify-content-center"}>
          <div
            className={
              "d-flex align-items-center text-center justify-content-center"
            }
          >
            <div className={'main-content'}>
              <img src={require('../../assets/img/No-Content.png')}  />
              <p>Hmm... nothing here yet...<br/>In the mean time: </p>
            </div>
          </div>
        </div>
      </div>
      <div className={'alternative-links row'}>
        <div className={'col-4'}>
          <div className={'alternative-item '}>
            <div className={'icon-block text-center mb-2'}>
              {/* <FontAwesome name={'user'}/> */}
              <Community className='svg-icon-not-found-block' width="60" height="60" />
            </div>
            <p>
              Check out all the cool people on Witit.
            </p>
            <button type={'button'} onClick={() => {
              props.history.push('/communities')
            }} className={'btn btn-primary btn-full-width'}>
              Community
            </button>
          </div>
        </div>
        <div className={'col-4'}>
          <div className={'alternative-item '}>
            <div className={'icon-block text-center mb-2'}>
              {/* <FontAwesome name={'fire'}/> */}
              <Hype className='svg-icon-not-found-block' width="60" height="60" />
            </div>
            <p>
              Find great content on the trending page.
            </p>
            <button type={'button'} onClick={() => {
              props.history.push('/trending/feed')
            }}  className={'btn btn-primary btn-full-width'}>
              Hype
            </button>
          </div>
        </div>
        <div className={'col-4'}>
          <div className={'alternative-item '}>
            <div className={'icon-block text-center mb-2'}>
              <div className="border-icon-plus-div-not-found">
                <Plus className="border-icon-plus" width="58" height="58"/>
              </div>  
              {/* <FontAwesome name={'plus-circle'}/> */}
              </div>
            <p>
              Create a new post to share with the world.
            </p>
            <button type={'button'} onClick={() => {
              props.history.push('/create-post')
            }}  className={'btn btn-primary btn-full-width'}>
              Post
            </button>
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
}
export default withRouter(NotFoundBlock)