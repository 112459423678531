import React, { Component } from "react";
import { connect } from "react-redux";

import { Elements, CardElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripePayment from "./StripePayment";

class StripeTest extends Component {
  componentDidMount() {}

  render() {
    return (
      <div>
        <StripePayment />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(StripeTest);
