import axios from "axios";

export function listNotifications(type) {
  return async (dispatch, getState) => {
    let mainUrl = getState().settings.mainUrl;
    let id_user = getState().user.user.id;
    let response = await axios.get(
      `${mainUrl}/api/notification/${id_user}/type/${type}`,
      {
        params: {
          api_token: getState().user.user.api_token,
        },
      }
    );
    return response.data;
  };
}
