import React,{Component} from 'react';
import Select from "react-select";
import { stylesMobileSidebarSelect } from "../../redux/actions/functionActions";
import { withRouter } from "react-router-dom";

class MobileSidebarAccountSettings extends Component{
  state = {
    menu: [
      { value: "/settings/general", label: "General" },
      { value: "/settings/account", label: "Account" },
      { value: "/settings/payments", label: 'Payments'},
      { value: "/settings/badges", label: 'Badges'},
      { value: "/settings/help", label: 'Help'},
      { value: "/settings/about", label: 'About'},
    ],
  };
  render(){
    return <React.Fragment>
      <Select
        options={this.state.menu}
        className={"mb-2 mobile"}
        styles={stylesMobileSidebarSelect()}
        defaultValue={this.props.active}
        onChange={(e) => {
          this.props.history.push(e.value);
        }}
      />
    </React.Fragment>
  }
}
export default withRouter(MobileSidebarAccountSettings) ;