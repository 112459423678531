import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import {
  createLocalTracks,
  createLocalVideoTrack,
  connect as twilioConnect,
} from "twilio-video";

class TwilioRoomTest extends Component {
  componentDidMount() {
    axios
      .get(
        `https://gambits.solver.com.ua/api/room/join/${this.props.match.params.roomName}`
      )
      .then((response) => {
        this.connectToRoom(response.data.accessToken, response.data.roomName);
      });
  }
  connectToRoom = (accessToken, roomName) => {
    // const { connect, createLocalVideoTrack } = require("twilio-video");

    twilioConnect(accessToken, { name: roomName }).then(
      (room) => {
        console.log(`Successfully joined a Room: ${room}`);

        const videoChatWindow = document.getElementById("video-chat-window");

        createLocalVideoTrack().then((track) => {
          videoChatWindow.appendChild(track.attach());
        });

        room.on("participantConnected", (participant) => {
          console.log(`Participant "${participant.identity}" connected`);
          participant.tracks.forEach((publication) => {
            if (publication.isSubscribed) {
              const track = publication.track;
              videoChatWindow.appendChild(track.attach());
            }
          });
          participant.on("trackSubscribed", (track) => {
            videoChatWindow.appendChild(track.attach());
          });
        });
      },
      (error) => {
        console.error(`Unable to connect to Room: ${error.message}`);
      }
    );
  };

  joinToRoom = () => {};

  render() {
    return (
      <div>
        <div id="video-chat-window"></div>
      </div>
    );
  }
}
export default withRouter(TwilioRoomTest);
