import React from "react";
import { NavLink } from "react-router-dom";

function DesktopNotificationSideBar(props) {
  return (
    <ul className={"p-0"} style={{ listStyleType: "none" }}>
      <li className={props.active === "likes" ? "active" : ""}>
        <NavLink to={"/notification/likes"}>Likes</NavLink>
      </li>
      <li className={props.active === "awards" ? "active" : ""}>
        <NavLink to={"/notification/awards"}>Awards</NavLink>
      </li>
      <li className={props.active === "comments" ? "active" : ""}>
        <NavLink to={"/notification/comments"}>Comments</NavLink>
      </li>
      <li className={props.active === "follows" ? "active" : ""}>
        <NavLink to={"/notification/follows"}>Follows</NavLink>
      </li>
      <li className={props.active === "receive-orders" ? "active" : ""}>
        <NavLink to={"/notification/receive-orders/notifications"}>
          Received Orders
        </NavLink>
      </li>
      <li className={props.active === "made-orders" ? "active" : ""}>
        <NavLink to={"/notification/orders-created/notifications"}>
          Orders Created
        </NavLink>
      </li>
      <li className={props.active === "calls" ? "active" : ""}>
        <NavLink to={"/notification/calls" }>Calls</NavLink>
      </li>
      <li className={props.active === "earning" ? "active" : ""}>
        <NavLink to={"/notification/earning" }>Earnings</NavLink>
      </li>
    </ul>
  );
}

export default DesktopNotificationSideBar;
