import React, {Component} from "react";
import DesktopNavbar from "../../components/headers/DesktopNavbar";
import MobileNavbar from "../../components/headers/MobileNavbar";
import BaseLayout from "../../layouts/BaseLayout";
import DesktopSidebarAccountSettings from "../../components/sidebars/DesktopSidebarAccountSettings";
import MyProfileUserBlock from "../../components/sidebars/MyProfileUserBlock";
import Tabbar from "../../components/headers/Tabbar";
import { connect } from "react-redux";
import MobileSidebarAccountSettings from "../../components/sidebars/MobileSidebarAccountSettings";

class AboutSettings extends Component{
  render(){
    return  <React.Fragment>
      <DesktopNavbar />
      <MobileNavbar />
      <BaseLayout>
        <aside className="col col-xl-3 order-xl-1 col-lg-12 order-lg-1 col-12 my-profile ">
          <div className={" row sidebar-menu"}>
            <div className={"col-12 "}>
              <div className={"bg-white profile-account-holder desktop"}>
                <h2>Settings</h2>
                <DesktopSidebarAccountSettings
                  active={"/settings/about"}
                />
              </div>
              <div className={"mobile"}>
                <MobileSidebarAccountSettings
                  active={{ value: "settings/about", label: "about" }}
                />
              </div>
            </div>
          </div>
        </aside>
        <main
          className="col col-xl-6 order-xl-2 col-lg-12 order-lg-2 col-md-12 col-sm-12 col-12 mb-3"
          style={{ padding: "0 16px" }}
        >
          <div className="profile-account-holder row bg-white settings-main settings-container">
            <div className={"col-12 "}>
              <h1 >About</h1>
              <div className={'about-settings'}>
                <a className={'h2 d-block'} href={''}>Terms of Service</a>
                <a className={'h2 d-block mb-3'} href={''}>Content Policy</a>
                <p>Welcome to Witit! At Witit we are dedicated to providing an amazing place fore people to take control over their social media expirience and turn their socil life into their source of income. </p>
                <p>To ensure that Witit kept as amazing possible, please check out our <a href={''}>content policy</a>!</p>
                <p>Thank you for joining us in our mission of helping the world take control of their social  life and helping the passionate few fin success and support amongst an amazing community.</p>
                <p>We look  forward to your success and happiness,<br/><br/>Nathaniel<br/>Witit Founder</p>

              </div>
            </div>
          </div>
        </main>
        <aside className="col col-xl-3 order-xl-3 col-lg-12 order-lg-3 col-12">
          <MyProfileUserBlock user={this.props.user} />
        </aside>
      </BaseLayout>
      <Tabbar />
    </React.Fragment>
  }
}
function mapStateToProps(state) {
  return {
    user: state.user.user,
    settings: state.settings,
  };
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps,mapDispatchToProps)(AboutSettings) ;