import React, { useState,useEffect } from "react";
import axios from 'axios';
import {connect} from 'react-redux';
import {setPaymentToUser} from '../../redux/actions/stripeActions';
import {Alert} from 'react-bootstrap';
import { loadStripe } from "@stripe/stripe-js";
import Tabbar from "../../components/headers/Tabbar";
import {changeStatus} from "../../redux/actions/orderActions";

const stripePromise = loadStripe("pk_test_Du0fzY5XkR7m8qkwrWanhqpC00TMxFfEet");

function StripeCustomer(props){

  let [cardElement, setCard] = useState({});
  let [stripe, setStripe] = useState({});
  let [name, setName] = useState('');
  let [email, setEmail] = useState('');
  let [errors, setErrors] = useState({
    name:[],
    email:[]
  });


    let getPaymentIntent = async (customer) => {
      switch (props.data.type) {
        case 'exhibit':return await axios.post(`${props.settings.mainUrl}/api/stripe/payment-exhibits/${props.data.data.id}/customer/${customer.data.id}` ,{
          api_token:props.user.api_token
        });
        case 'service': return await axios.post(`${props.settings.mainUrl}/api/stripe/payment-service`,{
          id_service:props.data.data.id_service,
          id_customer:customer.data.id,
          api_token:props.user.api_token
        });
        case 'call': return await axios.post(`${props.settings.mainUrl}/api/stripe/payment-call`, {
          id_request:props.data.data.id,
          id_customer:customer.data.id,
          api_token:props.user.api_token
        });

      }

    }


    let validateForm = ()  => {
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let errorsList = {
        name: [],
        email:[]
      };

      if (name === "") {
        errorsList.name.push(<div>Name is required!</div>);
      }
      if(email === ""){
        errorsList.email.push(<div>Email is required!</div>);
      }
      if (!re.test(email)) {
        errorsList.email.push(<div>Email not valid!</div>);
      }


      return errorsList;
    }


    let checkErrors = (errorsList) => {
      let isError = false;
      Object.keys(errorsList).map((objectKey, index) => {
        let value = errorsList[objectKey];
        if (value.length > 0) {
          isError = true;
        }
      });
      return isError;
    };

    let payHandler = async () => {
      let customer = await axios.post(`${props.settings.mainUrl}/api/stripe/payment/customer/${props.user.id}`,{
        api_token:props.user.api_token
      });

      let paymentIntent = await getPaymentIntent(customer);


      stripe.confirmCardPayment(paymentIntent.data.client_secret, {
        payment_method: {
          card: cardElement,
          billing_details: {

            name: name,
            email:email,
          }
        },
        setup_future_usage: 'off_session'
      }).then(function(result) {
        if (result.error) {
          // Show error to your customer

        } else {
          if (result.paymentIntent.status === 'succeeded') {

            axios.patch(`${props.settings.mainUrl}/api/user/${props.user.id}/customer/${customer.data.id}`,{
              api_token:props.user.api_token
            });
            axios.patch(`${props.settings.mainUrl}/api/user/${props.user.id}/payment_method/${result.paymentIntent.payment_method}`,{
              api_token:props.user.api_token
            });

            props.setPaymentToUser(props.data.type,props.data.data,result.paymentIntent).then(response => {
              if(props.data.type === 'service'){
                props.changeStatus(props.data.data.id,9)
              }

              props.hidePayment();
              window.location.href = '/';
            })




            // Show a success message to your customer
            // There's a risk of the customer closing the window before callback execution
            // Set up a webhook or plugin to listen for the payment_intent.succeeded event
            // to save the card to a Customer

            // The PaymentMethod ID can be found on result.paymentIntent.payment_method
          }
        }
      });


    }


  useEffect(() => {

    stripePromise.then(response => {
      let elements = response.elements();
      let style = {
        base: {
          iconColor: '#c4f0ff',
          color: '#333',
          fontWeight: '500',
          fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
          fontSize: '16px',
          fontSmoothing: 'antialiased',
          ':-webkit-autofill': {
            color: '#fce883',
          },
          '::placeholder': {
            color: '#333',
          },
        },
        invalid: {
          iconColor: '#FFC7EE',
          color: '#000',
        },
      };

      let card = elements.create("card", {
        style: style,
        hidePostalCode : true
      });

      card.mount("#card-element");
      card.on('change', function(event) {
        let displayError = document.getElementById('card-errors');
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = '';
        }
      });
      setCard(card);
      setStripe(response);
    })
  },[]);





    return <div>
              {checkErrors(errors)
                ? <Alert variant={'danger'}>
                  {Object.keys(errors).map((fieldErrors,index1) => {
                    return <React.Fragment key={`errors-list-${index1}`}>
                      {errors[fieldErrors].map((itemError, index2) => {
                        return <div key={`error-item-${index1}-${index2}`}>
                          {itemError}
                        </div>
                      })}
                    </React.Fragment>
                  })}
                </Alert>
                : null
              }

              <div>
                Please enter your billing information to make a payment
              </div>
              <div className={'form-group'}>
                <label>Name</label>
                <input className={[
                  'form-control bordered',
                  errors.name.length > 0 ?'error' : ''
                ].join(' ')} value={name} onChange={e => setName(e.target.value)}/>
              </div>
              <div className={'form-group'}>
                <label>Email</label>
                <input className={[
                  'form-control bordered',
                  errors.email.length > 0 ?'error' : ''
                ].join(' ')} value={email} onChange={e => setEmail(e.target.value)}/>
              </div>
              <div id="card-element" className={'mb-3'}>

              </div>


              <div id="card-errors" role="alert"></div>

              <button
                id="submit"
                onClick={() => {
                  let errorsList = validateForm();
                  setErrors({...errorsList});
                  if(!checkErrors(errorsList)){
                    payHandler().then(response => {

                    });

                  }


                }}
                className={'btn btn-primary'}
                style={{width:'100%'}}
              >Pay</button>
            </div>


}
function mapStateToProps(state) {
  return {
    settings: state.settings,
    user: state.user.user,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    setPaymentToUser:(type,data,paymentIntent) => {
      return dispatch(setPaymentToUser(type, data, paymentIntent))
    },
    changeStatus:(id_order, status) => {
      return dispatch(changeStatus(id_order,status))
    }
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StripeCustomer) ;