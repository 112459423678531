import React, { Component } from "react";
import { Card, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import GuestDesktopNavbar from "../../components/headers/GuestDesktopNavbar";

class RegistrationThankYou extends Component {
  render() {
    return (
      <React.Fragment>
        <GuestDesktopNavbar />
        <div className={"py-3"}>
          <div className={"container"}>
            <div className={"row"}>
              <div className={"col-6"}>
                <Card>
                  <Card.Body
                    style={{
                      backgroundImage:
                        "url(" +
                        require("./../../assets/img/thanYou.jpg") +
                        ")",
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: 900,
                      color: "#0079ee",
                      padding: "100px 0",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      style={{ fontSize: 80, marginBottom: 30 }}
                    />
                    <br />
                    <span style={{}}>CONGRATULATIONS!</span>
                    <br />
                    <span>You're all signed up!</span>
                  </Card.Body>
                </Card>
              </div>
              <div className={"col-6"}>
                <Card>
                  <Card.Body>
                    <h4
                      style={{
                        fontWeight: 900,
                      }}
                    >
                      You have to verify your email!
                    </h4>
                    <p>
                      Message with verify link send to your email. Click button
                      below for sent message again.
                    </p>
                    <p>
                      <Button
                        variant={"primary"}
                        onClick={() => {
                          console.log(this.props.match.params.id);
                          axios.post(
                            this.props.settings.mainUrl +
                              "/api/mail/verify/" +
                              this.props.match.params.id
                          );
                        }}
                      >
                        Send Email
                      </Button>
                    </p>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return { settings: state.settings };
}

function mapDispatchToProps(dispatch) {}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RegistrationThankYou));
