import React from 'react';
import { stylesMobileSidebarSelect } from "../../redux/actions/functionActions";
import Select from "react-select";
import { withRouter } from "react-router-dom";

function DeactivateMyProfileSidebarMobile(props) {
  let menu = [
    { value: "/my-profile/about", label: "About" }
  ];
  return <React.Fragment>
    <Select
      options={menu}
      className={"mb-2 mobile"}
      defaultValue={props.active}
      styles={stylesMobileSidebarSelect()}
      onChange={(e) => {
        props.history.push(e.value + props.userLink);
      }}
    />
  </React.Fragment>
}
export default withRouter(DeactivateMyProfileSidebarMobile);