import React, { Component } from "react";
import DesktopNavbar from "../../components/headers/DesktopNavbar";
import MobileNavbar from "../../components/headers/MobileNavbar";
import BaseLayout from "../../layouts/BaseLayout";
import { connect } from "react-redux";
import {
  fetchHomeFeed,
  like,
  likeStar,
  unlike,
  postDelete,
} from "./../../redux/actions/postActions";
import Tabbar from "../../components/headers/Tabbar";
import { fetchAllPostCategories } from "./../../redux/actions/postCategory";
import { userViewToken } from "../../redux/actions/userActions";
import FeedItem from "../../components/FeedItem";
import { pushNotification } from "./../../redux/actions/pushNotificationActions";
import NotFoundBlock from "../NotFound/NotFoundBlock";
import iconBack from '../../assets/img/icon-back.svg'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import "swiper/swiper-bundle.min.css";
import 'swiper/modules/pagination/pagination.min.css'
import "swiper/modules/lazy/lazy.min.css"
import SwiperCore, { Mousewheel, Pagination, Lazy } from "swiper";

SwiperCore.use([Mousewheel, Pagination, Lazy]);
class Home extends Component {
  state = {
    feed: [],
    categories: [],
    activeCategory: { value: 0, label: "All categories", link:'/', mature:0 },
    categoriesMenu: [],
    modalMenuStatus: false,
    show: false,
    feedCategories: [],
    feedCategoriesSave: []
  };
  componentDidMount() {
    this.fetchHomeFeed();
    this.props.fetchAllPostCategories().then((response) => {
      let feedCategories= [];
      // feedCategories.push({ value: 0, label: "All categories",link:'/' , mature: 0});
      response.data.map((category, index) => {
        // if(!( category.mature === 1 && this.props.user.is_adult_show === 0)){
          feedCategories.push({ value: category.id, name: category.name, mature:category.mature, flag: false });
       // }
      });

      this.setState({
        categories: [...response.data],
        feedCategories: [...feedCategories],
      });
      
    });
  }

  fetchHomeFeed = () => {
    this.props
      .fetchHomeFeed(this.props.user.id, this.props.match.params.id)
      .then((response) => {
        this.setState({
          feed: [...response.data],
        });
      });
  };



  deleteFromList = (index) => {
    let feeds = [...this.state.feed];
    feeds.splice(index, 1);
    this.setState({
      feed: [...feeds],
    });
  };

  likeStar = (feed, index) => {
    this.props.likeStar(feed.id, this.props.user.id).then((response) => {
      let feeds = [...this.state.feed];
      feeds[index] = { ...response.data };

      this.setState({ feed: [...feeds] });
    });
  };

  like = (feed, index) => {
    this.props.like(feed.id, this.props.user.id).then((response) => {
      let feeds = [...this.state.feed];
      feeds[index] = { ...response.data };

      this.setState({ feed: [...feeds] });
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.location.pathname !== prevProps.location.pathname){
      this.fetchHomeFeed();
    }
  }
  handelFeedFiltersVisible = () => {
    if(this.state.show){
      this.setState({
        show:false
    })
    }else{
      this.setState({
        show:true
    })
    }
  }
  handleFeedFilters = (name) => {
    const updatedFeedCategories = [...this.state.feedCategories];
    const currentFeedCategoryIndex = updatedFeedCategories.findIndex(
      (item) => item.name === name
    );

    updatedFeedCategories[currentFeedCategoryIndex] = {
      ...updatedFeedCategories[currentFeedCategoryIndex],
      flag: !updatedFeedCategories[currentFeedCategoryIndex].flag
    };

    this.setState({
      feedCategories: updatedFeedCategories
    });
  }
    handleFeedFiltersSave = () => {
      let feedCategoriesSaveAll = [];
      this.state.feedCategories.map((nameTrue) => {
        if(nameTrue.flag){
         return feedCategoriesSaveAll.push(nameTrue.name);       
        }
      });
      this.setState({
        feedCategoriesSave: feedCategoriesSaveAll
      })
      this.setState({
        show:false
    })
   }

  render() {

    return (
      <React.Fragment>
        <DesktopNavbar />
        <MobileNavbar />
        <BaseLayout>
          <aside className="col col-xl-3 order-xl-1 col-lg-12 order-lg-1 col-12 my-profile ">
            <div className={" row sidebar-menu"}>
              <div className={"col-12 pl-0 pr-0"}>
              <button className="feed-filters-home" onClick={this.handelFeedFiltersVisible}>Feed Filters</button>
                <div className="feed-filters-div" style={this.state.show === true ? {display : 'block'} : {display : 'none'}}>
                  <div className="feed-filters-section">
                    <div className="feed-filters-header">
                      <button onClick={this.handelFeedFiltersVisible}>
                        <img src={iconBack} alt="icon back"/>
                        Back
                      </button>
                      <span>Feed Filters</span>
                    </div>
                    <div className="feed-filters-around">
                      {this.state.feedCategories.map((feedCategory) => (
                            <button key={feedCategory.name}
                                    style={feedCategory.flag ? {background : '#1448FF', border: 'none'} : {background : 'transparent'}}
                                    onClick={() => this.handleFeedFilters(feedCategory.name)}
                            >{feedCategory.name}</button>
                        ))}
                    </div>
                    <button className="feed-filters-btn" onClick={this.handleFeedFiltersSave}>Save Filters & Refresh</button>
                  </div>
                </div>
                {/* <Select
                  options={this.state.categoriesMenu}
                  styles={stylesMobileSidebarSelect()}
                  className={"mb-2 mobile"}
                  defaultValue={this.state.activeCategory}
                  onChange={(e) => {

                    this.props.history.push(e.link);
                  }}
                /> */}
                <div className={"bg-white profile-account-holder desktop"}>
                  <h2>Categories</h2>
                  <ul className={"p-0"} style={{ listStyleType: "none" }}>
                    {this.state.categoriesMenu.map((category, index) => {
                      return (
                        <li
                          key={`feed_category_${index}`}
                          className={[
                           parseInt(this.props.match.params.id)  === category.value || (this.props.match.path === '/' && category.value === 0)
                              ? "active"
                              : "",
                          ].join(" ")}
                        >
                          <a
                            href={""}
                            key={`home-category-${index}`}
                            onClick={(e) => {
                              e.preventDefault();

                              this.props.history.push(category.link);
                            }}
                          >
                            {category.label}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </aside>
          <main
            className="main col col-xl-6 order-xl-2 col-lg-12 order-lg-2 col-md-12 col-sm-12 col-12 mb-3 post-list"
            style={{ padding: "0 15px" }}
          >
            <Swiper
              direction={"horizontal"}
              slidesPerView={1}
              spaceBetween={30}
              mousewheel={true}
              pagination={false}
              speed= {500}
              lazy={true}
              pagination={{
                "type": "fraction"
              }}
              loop={true}
              className="mySwiper"
            
            >
            {this.state.feed.length === 0 ? <NotFoundBlock/>  : null}
            {/*<button*/}
            {/*  className={"btn btn-primary"}*/}
            {/*  onClick={() => {*/}
            {/*    this.props.pushNotification();*/}
            {/*  }}*/}
            {/*>*/}
            {/*  Test push*/}
            {/*</button>*/}
            {this.state.feed.map((feed, index) => {
              return (
                <SwiperSlide>
                  <FeedItem
                    key={`trending-feed-${index}`}
                    feed={feed}
                    index={index}
                    deleteFromList={this.deleteFromList}
                    likeStar={this.likeStar}
                    like={this.like}
                    feedLink={`/feed/${feed.id}`}
                  />
                </SwiperSlide>
              );
            })}
            </Swiper>
          </main>
          <aside className="col col-xl-3 order-xl-3 col-lg-12 order-lg-3 col-12">
            {/*<div className=" karma-tabs bg-white p-3">*/}
            {/*  <h2>Good Karma Users</h2>*/}
            {/*  <p>*/}
            {/*    Users who have supported creators the most through engagement,*/}
            {/*    tips, and orders.*/}
            {/*  </p>*/}
            {/*  <ul className={"p-0 tabs-nav"} style={{ listStyleType: "none" }}>*/}
            {/*    <li className={"active"}>*/}
            {/*      <a href={""}>Today</a>*/}
            {/*    </li>*/}
            {/*    <li>*/}
            {/*      <a href={""}>Week</a>*/}
            {/*    </li>*/}
            {/*    <li>*/}
            {/*      <a href={""}>Month</a>*/}
            {/*    </li>*/}
            {/*  </ul>{" "}*/}
            {/*  <div className={"user-list"}>*/}
            {/*    <div className={"user-item d-flex align-items-center"}>*/}
            {/*      <img*/}
            {/*        src={require("../../assets/img/p13.png")}*/}
            {/*        className={"rounded-circle mr-3"}*/}
            {/*        width={30}*/}
            {/*      />*/}
            {/*      <div className={"position-relative post-author  d-flex"}>*/}
            {/*        <div className={"font-weight-600 name"}> Georgia Rose</div>*/}
            {/*        <div className={"author-badge"} style={{}}>*/}
            {/*          <img*/}
            {/*            src={require("../../assets/img/badge.png")}*/}
            {/*            width={15}*/}
            {/*          />*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*    <div className={"user-item d-flex align-items-center"}>*/}
            {/*      <img*/}
            {/*        src={require("../../assets/img/p13.png")}*/}
            {/*        className={"rounded-circle mr-3"}*/}
            {/*        width={30}*/}
            {/*      />*/}
            {/*      <div className={"position-relative post-author  d-flex"}>*/}
            {/*        <div className={"font-weight-600 name"}> Georgia Rose</div>*/}
            {/*        <div className={"author-badge"} style={{}}>*/}
            {/*          <img*/}
            {/*            src={require("../../assets/img/badge.png")}*/}
            {/*            width={15}*/}
            {/*          />*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*    <div className={"user-item d-flex align-items-center"}>*/}
            {/*      <img*/}
            {/*        src={require("../../assets/img/p13.png")}*/}
            {/*        className={"rounded-circle mr-3"}*/}
            {/*        width={30}*/}
            {/*      />*/}
            {/*      <div className={"position-relative post-author  d-flex"}>*/}
            {/*        <div className={"font-weight-600 name"}> Georgia Rose</div>*/}
            {/*        <div className={"author-badge"} style={{}}>*/}
            {/*          <img*/}
            {/*            src={require("../../assets/img/badge.png")}*/}
            {/*            width={15}*/}
            {/*          />*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*    <div className={"user-item d-flex align-items-center"}>*/}
            {/*      <img*/}
            {/*        src={require("../../assets/img/p13.png")}*/}
            {/*        className={"rounded-circle mr-3"}*/}
            {/*        width={30}*/}
            {/*      />*/}
            {/*      <div className={"position-relative post-author  d-flex"}>*/}
            {/*        <div className={"font-weight-600 name"}> Georgia Rose</div>*/}
            {/*        <div className={"author-badge"} style={{}}>*/}
            {/*          <img*/}
            {/*            src={require("../../assets/img/badge.png")}*/}
            {/*            width={15}*/}
            {/*          />*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*    <div className={"user-item d-flex align-items-center"}>*/}
            {/*      <img*/}
            {/*        src={require("../../assets/img/p13.png")}*/}
            {/*        className={"rounded-circle mr-3"}*/}
            {/*        width={30}*/}
            {/*      />*/}
            {/*      <div className={"position-relative post-author  d-flex"}>*/}
            {/*        <div className={"font-weight-600 name"}> Georgia Rose</div>*/}
            {/*        <div className={"author-badge"} style={{}}>*/}
            {/*          <img*/}
            {/*            src={require("../../assets/img/badge.png")}*/}
            {/*            width={15}*/}
            {/*          />*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*    <div className={"user-item d-flex align-items-center"}>*/}
            {/*      <img*/}
            {/*        src={require("../../assets/img/p13.png")}*/}
            {/*        className={"rounded-circle mr-3"}*/}
            {/*        width={30}*/}
            {/*      />*/}
            {/*      <div className={"position-relative post-author  d-flex"}>*/}
            {/*        <div className={"font-weight-600 name"}> Georgia Rose</div>*/}
            {/*        <div className={"author-badge"} style={{}}>*/}
            {/*          <img*/}
            {/*            src={require("../../assets/img/badge.png")}*/}
            {/*            width={15}*/}
            {/*          />*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*    <div className={"user-item d-flex align-items-center"}>*/}
            {/*      <img*/}
            {/*        src={require("../../assets/img/p13.png")}*/}
            {/*        className={"rounded-circle mr-3"}*/}
            {/*        width={30}*/}
            {/*      />*/}
            {/*      <div className={"position-relative post-author  d-flex"}>*/}
            {/*        <div className={"font-weight-600 name"}> Georgia Rose</div>*/}
            {/*        <div className={"author-badge"} style={{}}>*/}
            {/*          <img*/}
            {/*            src={require("../../assets/img/badge.png")}*/}
            {/*            width={15}*/}
            {/*          />*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </aside>
        </BaseLayout>
        <Tabbar />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user.user,
    settings: state.settings,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    like: (id_post, id_user) => {
      return dispatch(like(id_post, id_user));
    },
    likeStar: (id_post, id_user) => {
      return dispatch(likeStar(id_post, id_user));
    },
    unlike: (id_post, id_user) => {
      return dispatch(unlike(id_post, id_user));
    },
    fetchHomeFeed: (id_user, id_category) => {
      return dispatch(fetchHomeFeed(id_user, id_category));
    },
    fetchAllPostCategories: () => {
      return dispatch(fetchAllPostCategories());
    },
    userViewToken: () => {
      return dispatch(userViewToken());
    },
    postDelete: (id) => {
      return dispatch(postDelete(id));
    },
    pushNotification: () => {
      return dispatch(pushNotification());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
