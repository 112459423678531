import React, { useState, useEffect } from "react";
import ConversationSearch from "../ConversationSearch";
import ConversationListItem from "../ConversationListItem";
import Toolbar from "../Toolbar";
import ToolbarButton from "../ToolbarButton";
import axios from "axios";
import { connect } from "react-redux";
import { searchUsers } from "../../../../redux/actions/messengerActions";
import { messengerUsers } from "./../../../../redux/actions/messengerActions";

import "./ConversationList.css";

function ConversationList(props) {
  const [search, setSearch] = useState("");

  const [searchResult, setSearchResult] = useState([]);

  const [searchConversations, setSearchConversations] = useState([]);

  useEffect(() => {});

  return (
    <div className="conversation-list">
      <React.Fragment>
        <Toolbar
          title=""
          leftItems={[<span />]}
          rightItems={[
         false &&  ! props.sidebarHide ? (
              <ToolbarButton
                key="add"
                icon="ion-ios-arrow-dropleft"
                onClick={() => {
                  props.toggleSidebarHide();
                }}
              />
            ) : null,
          ]}
        />
        <ConversationSearch
          value={search}
          onChangeHandler={setSearch}
          searchUsers={(searchValue) => {
            return props.searchUsers(searchValue);
          }}
          setSearchConversations={(result) => {
            setSearchConversations(result);
          }}
          setSearchResult={(data) => {
            setSearchResult(data);
          }}
        />

        {search.length > 0 ? (
          searchConversations.length > 0 ? (
            <React.Fragment>
              {searchConversations.map((item, index) => {
                return (
                  <ConversationListItem
                    key={index}
                    data={item}
                    type={"search"}
                   // changeActiveConversation={props.changeActiveConversation}
                    activeConversation={props.activeConversation}
                  />
                );
              })}
            </React.Fragment>
          ) : (
            <div>Result not found</div>
          )
        ) : null}

        {search.length === 0
          ? props.conversations.map((conversation, index) => {
              return (
                <React.Fragment key={`search_${index}`}>
                  <ConversationListItem
                    data={conversation}
                    type={"main"}
                    changeActiveConversation={props.changeActiveConversation}
                    activeConversation={props.activeConversation}
                  />
                </React.Fragment>
              );
            })
          : null}
      </React.Fragment>
    </div>
  );
}

function mapStateToProps(state) {
  return { settings: state.settings };
}

function mapDispatchToProps(dispatch) {
  return {
    searchUsers: (search) => {
      return dispatch(searchUsers(search));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConversationList);
