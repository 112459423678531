import React, { Component } from "react";
import BaseLayout from "../../layouts/BaseLayout";
import DesktopNavbar from "../../components/headers/DesktopNavbar";
import MobileNavbar from "../../components/headers/MobileNavbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import {
  faPaperPlane,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import MyProfileSidebar from "../../components/sidebars/MyProfileSidebar";
import MyProfileUserBlock from "../../components/sidebars/MyProfileUserBlock";
import Tabbar from "../../components/headers/Tabbar";
import MyProfileSidebarMobile from "../../components/sidebars/MyProfileSidebarMobile";
import {
  getUserByName,
  userNewData,
  userViewToken,
  getSkillNames,
} from "../../redux/actions/userActions";
import {
  getFollows,
  subscribe,
  unsubscribe,
} from "../../redux/actions/subscriptionsActions";
import { createPayout } from "../../redux/actions/stripeActions";
import RequestPayoutForm from "./RequestPayoutForm";
import DeactivateMyProfileSidebar from "../../components/sidebars/DeactivateMyProfileSidebar";
import DeactivateMyProfileSidebarMobile from "../../components/sidebars/DeactivateMyProfileSidebarMobile";

class MyProfile extends Component {
  state = {
    user: {},
    skills: [],
    modalShow: false,
  };
  componentDidMount() {
    if (!this.props.match.params.user) {
      this.setState(
        {
          user: { ...this.props.user },
        },
        () => {
          let skils = "[]";
          if (!this.state.user.skills) {
          } else {
            skils = this.state.user.skills;
          }

          this.props.getSkillNames(skils).then((response) => {
            this.setState({
              skills: [...response.data],
            });
          });
        }
      );
    } else {
      this.props
        .getUserByName(this.props.match.params.user)
        .then((response) => {
          this.setState(
            {
              user: { ...response.data },
            },
            () => {
              let skils = "[]";
              if (!this.state.user.skills) {
              } else {
                skils = this.state.user.skills;
              }

              this.props.getSkillNames(skils).then((response) => {
                this.setState({
                  skills: [...response.data],
                });
              });
            }
          );
        });
    }
  }

  render() {
    // console.log(this.props.user.subscriptions);
    return (
      <React.Fragment>
        <DesktopNavbar />
        <MobileNavbar >{this.state.user.name !== this.props.user.name ? `@${this.state.user.name}`  : ' '}</MobileNavbar>

        <BaseLayout>
          <aside className="col col-xl-3 order-xl-1 col-lg-12 order-lg-1 col-12 my-profile order-1">
            <div className={" row sidebar-menu"}>
              <div className={"col-12 "}>
                <div className={"bg-white profile-account-holder desktop"}>
                  <h2>@{this.state.user.name}</h2>
                  {this.state.user.status === -1 ? <DeactivateMyProfileSidebar
                    active={"about"}
                    userLink={
                     Object.keys(this.state.user).length > 0
                     ? `/${this.state.user.name}`
                     : ""
                    }/>  : <MyProfileSidebar
                    isOwner={this.state.user.name === this.props.user.name}
                    active={"about"}
                    userLink={
                      Object.keys(this.state.user).length > 0
                        ? `/${this.state.user.name}`
                        : ""
                    }
                  />}

                </div>
                <div className={"mobile"}>
                  {this.state.user.status === -1 ? <DeactivateMyProfileSidebarMobile  active={{ value: "/my-profile/about", label: "About" }}
                                                                                      userLink={
                                                                                        Object.keys(this.state.user).length > 0
                                                                                          ? `/${this.state.user.name}`
                                                                                          : ""
                                                                                      }/>  :  <MyProfileSidebarMobile
                    isOwner={this.state.user.name === this.props.user.name}
                    active={{ value: "/my-profile/about", label: "About" }}
                    userLink={
                      Object.keys(this.state.user).length > 0
                        ? `/${this.state.user.name}`
                        : ""
                    }
                  /> }

                </div>
              </div>
            </div>
          </aside>
          <main
            className="col col-xl-6 order-xl-2 col-lg-12 order-lg-2 col-md-12 col-sm-12 col-12 mb-3 order-2"
            style={{ padding: "0 16px" }}
          >
            <div className="profile-account-holder row">
              <div className={"col-6  bg-white"}>
                <div className="box mb-3 border rounded bg-white profile-box  online">
                  <div className="py-3 px-3 d-flex flex-column">
                    <div className="profile-main-data-container pl-0">
                      <div className="name-block mb-2">
                        <h4 className=" text-dark mb-3 mt-0">{`${this.state.user.full_name}`}</h4>
                        <div>
                          <span
                            className="font-weight-600"
                            style={{ color: "#7f7f7f" }}
                          >
                            {" "}
                            <FontAwesomeIcon
                              icon={faMapMarkerAlt}
                              className={"mr-2"}
                            />
                            {this.state.user.city}, {this.state.user.country}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="profile-image-box">
                      <img
                        src={
                          this.props.settings.mainUrl +
                          "/storage/" +
                          this.state.user.avatar
                        }
                        className="img-full mt-2"
                        alt="Responsive image"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={"col-6  bg-white p-3"}>
                <div className="box border rounded bg-white mb-3 mt-3">
                  <h4 className={"mb-3"}>About</h4>
                  <div className="box-title  d-flex justify-content-between">
                    <h5 className="mb-3">Bio</h5>

                  </div>
                  <div className="box-body ">
                    <p>{this.state.user.description}</p>
                  </div>
                </div>

                {this.props.user.name === this.state.user.name ? (
                  <React.Fragment>
                    <div className="box-title  d-flex justify-content-between">
                      <h5 className="mb-3">Balance</h5>
                    </div>
                    <div className="box-body ">
                      <p>${this.props.user.balance}</p>
                    </div>
                  </React.Fragment>
                ) : null}

                <div className="box  border rounded bg-white mb-2">
                  <div className="box-title  d-flex justify-content-between mb-2">
                    <h5 className="mb-3">Expertise</h5>

                  </div>
                  <div className="box-body  ">
                    {this.state.skills.map((skill, index) => {
                      if (skill.category !== null && skill.expertise !== null) {
                        let btn_bg = "btn-dark";
                        switch (skill.expertise) {
                          case "0":
                            btn_bg = "btn-dark";
                            break;
                          case "1":
                            btn_bg = "btn-primary";
                            break;
                          case "2":
                            btn_bg = "btn-warning2";
                            break;
                          default:
                            btn_bg = "btn-dark";
                        }
                        return (
                          <button
                            key={`skills_${index}`}
                            type={"button"}
                            className={
                              "mr-2 btn btn-radius-50 mr-1 mb-2 " + btn_bg
                            }
                          >
                            {skill.name}{" "}
                          </button>
                        );
                      } else {
                      }
                    })}
                    {/*<button*/}
                    {/*  type="button"*/}
                    {/*  className="btn btn-dark btn-radius-50 mr-1 mb-2"*/}
                    {/*>*/}
                    {/*  Photography*/}
                    {/*</button>*/}
                    {/*<button*/}
                    {/*  type="button"*/}
                    {/*  className="btn btn-primary btn-radius-50 mr-1 mb-2"*/}
                    {/*>*/}
                    {/*  Marketing*/}
                    {/*</button>*/}
                    {/*<button*/}
                    {/*  type="button"*/}
                    {/*  className="btn btn-warning btn-radius-50 mr-1 mb-2"*/}
                    {/*>*/}
                    {/*  Graphic Design*/}
                    {/*</button>*/}
                  </div>

                  {this.props.user.name !== this.state.user.name ? (
                    <div className="row">
                      <div className="col-12">
                        <button
                          className="btn btn-dark  mr-2 btn-circle"
                          onClick={() => {
                            this.props.history.push(
                              `/messenger/${this.props.match.params.user}`
                            );
                          }}
                        >
                          <FontAwesomeIcon icon={faPaperPlane} />
                        </button>
                        {this.props.user.subscriptions.indexOf(
                          this.state.user.id
                        ) === -1 ? (
                          <button
                            className="btn btn-primary btn-radius-50"
                            type={"button"}
                            onClick={() => {
                              this.props
                                .subscribe(
                                  this.props.user.id,
                                  this.state.user.id
                                )
                                .then((response) => {
                                  this.props
                                    .userViewToken()
                                    .then((response) => {
                                      this.props.userNewData(response.data);
                                    });
                                });
                            }}
                          >
                            Follow
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary btn-radius-50"
                            type={"button"}
                            onClick={() => {
                              this.props
                                .unsubscribe(
                                  this.props.user.id,
                                  this.state.user.id
                                )
                                .then((response) => {
                                  this.props
                                    .userViewToken()
                                    .then((response) => {
                                      this.props.userNewData(response.data);
                                    });
                                });
                            }}
                          >
                            Unfollow
                          </button>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="row">
                      <div className="col-12">
                        <RequestPayoutForm
                          createPayout={this.props.createPayout} 
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </main>
          <aside className="col col-xl-3 order-xl-3 col-lg-12 order-lg-3 col-12 order-0">
            <MyProfileUserBlock user={this.state.user} />
          </aside>
        </BaseLayout>
        <Tabbar />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user.user,
    settings: state.settings,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userViewToken: () => {
      return dispatch(userViewToken());
    },
    userNewData: (data) => {
      dispatch(userNewData(data));
    },
    getUserByName: (name) => {
      return dispatch(getUserByName(name));
    },
    getFollows: (id) => {
      return dispatch(getFollows(id));
    },
    subscribe: (id_user1, id_user2) => {
      return dispatch(subscribe(id_user1, id_user2));
    },
    unsubscribe: (id_user1, id_user2) => {
      return dispatch(unsubscribe(id_user1, id_user2));
    },
    getSkillNames: (skills) => {
      return dispatch(getSkillNames(skills));
    },
    createPayout: (formData) => {
      return dispatch(createPayout(formData));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
